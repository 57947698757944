import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, Component, Type } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConfirmModalContentComponent } from '../components/modal-confirm/confirm-modal-content.component';
import { Subject, merge, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { OKModalContentComponent } from '../components/modal-ok/ok-modal-content.component';
import { DEFAULT_MODAL_WIDTH } from '../utils/modal-widths';
import { DataDogService, MixpanelEvent, MixpanelService } from '@vpfa/utils';
import { ConfirmChangesModalContentComponent } from '../components/modal-confirm-changes/confirm-changes-modal-content.component';
import { UnsavedChangesStatus, UnsavedFormComponent } from '@vpfa/shared/types';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private nzModalService: NzModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private dataDogService: DataDogService,
    private mixpanelService: MixpanelService,
  ) {}

  /**
   * Open `component` in a Modal. Important: use `vpfa-modal-service-content` inside `component` to give it standard
   * look and feel.
   *
   * @param component component that will be opened in a modal
   * @param data data provided in `component` via `@Inject(NZ_MODAL_DATA) public modalData: TDataType`,
   */
  openComponent<TDataType>(component: Type<unknown>, data: TDataType) {
    this.nzModalService.create({
      nzClassName: 'vp-modal',
      nzBodyStyle: { padding: '0' },
      nzZIndex: 1000,
      nzTitle: undefined,
      nzContent: component,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: true,
      nzKeyboard: true,
      nzMask: true,
      nzWidth: DEFAULT_MODAL_WIDTH,
      nzData: data,
    });
  }

  confirm(infoLines?: string[], acceptLabel?: string, deniedLabel?: string) {
    const modalContentRef = this.componentFactoryResolver
      .resolveComponentFactory(ConfirmModalContentComponent)
      .create(this.injector);

    this.applicationRef.attachView(modalContentRef.hostView);
    modalContentRef.changeDetectorRef.detectChanges();
    if (infoLines) {
      modalContentRef.instance.infoLabelsKey = infoLines;
    }

    if (acceptLabel) {
      modalContentRef.instance.yesLabelKey = acceptLabel;
    }
    if (deniedLabel) {
      modalContentRef.instance.noLabelKey = deniedLabel;
    }

    const modalRefResult = new Subject<boolean>();

    const modalTrackingContext = { modalName: 'Confirm' };

    this.dataDogService.addAction('OpenModal', modalTrackingContext);
    this.mixpanelService.track(MixpanelEvent.ModalOpen, modalTrackingContext);
    const modalRef = this.nzModalService.create({
      nzClassName: 'vp-modal',
      nzBodyStyle: { padding: '0' },
      nzZIndex: 1000,
      nzTitle: undefined,
      nzContent: modalContentRef.instance.modalContentTemplate,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzMask: true,
      nzOnOk: _ => modalRefResult.next(true),
      nzOnCancel: _ => modalRefResult.next(false),
    });

    const subscription = modalContentRef.instance.confirmResult.asObservable().subscribe(res => {
      res ? modalRef.triggerOk() : modalRef.triggerCancel();
    });

    return merge(modalRefResult.asObservable()).pipe(
      take(1),
      tap(() => {
        this.dataDogService.addAction('CloseModal', modalTrackingContext);
        this.mixpanelService.track(MixpanelEvent.ModalClose, modalTrackingContext);
        modalContentRef.destroy();
        subscription?.unsubscribe();
      }),
    );
  }

  confirmUnsavedChanges(componentRef: UnsavedFormComponent) {
    const modalContentRef = this.componentFactoryResolver
      .resolveComponentFactory(ConfirmChangesModalContentComponent)
      .create(this.injector);

    this.applicationRef.attachView(modalContentRef.hostView);
    modalContentRef.changeDetectorRef.detectChanges();

    const modalRefResult = new Subject<boolean>();

    const modalTrackingContext = { modalName: 'ConfirmUnsavedChanges' };

    this.dataDogService.addAction('OpenModal', modalTrackingContext);
    this.mixpanelService.track(MixpanelEvent.ModalOpen, modalTrackingContext);
    const modalRef = this.nzModalService.create({
      nzClassName: 'vp-modal',
      nzBodyStyle: { padding: '0' },
      nzZIndex: 1000,
      nzTitle: undefined,
      nzContent: modalContentRef.instance.modalContentTemplate,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzMask: true,
      nzOnOk: _ => modalRefResult.next(true),
      nzOnCancel: _ => modalRefResult.next(false),
    });

    const subscription = modalContentRef.instance.result
      .asObservable()
      .pipe(
        switchMap(res => {
          if (res === 'cancel') {
            modalRef.triggerCancel();
            return of(null);
          }

          if (res === 'dismissChangesAndExit') {
            modalRef.triggerOk();
            return of(null);
          }

          if (res === 'saveChangesAndExit') {
            modalContentRef.instance.isLoading = true;
            modalContentRef.changeDetectorRef.detectChanges();
            return componentRef.saveChangesBeforeNavigation();
          }

          return of(null);
        }),
        map(res => {
          if (!res) {
            return;
          }

          if (res === 'validationError') {
            this.ok('unsavedChanges.validationFailedWarning');
            modalRef.triggerCancel();
          }

          if (res === 'success') {
            modalContentRef.instance.isLoading = false;
            modalContentRef.changeDetectorRef.detectChanges();
            modalRef.triggerOk();
          }

          if (res === 'error') {
            modalContentRef.instance.isLoading = false;
            modalContentRef.changeDetectorRef.detectChanges();
            modalRef.triggerCancel();
          }
        }),
      )
      .subscribe();

    return merge(modalRefResult.asObservable()).pipe(
      take(1),
      tap(() => {
        this.dataDogService.addAction('CloseModal', modalTrackingContext);
        this.mixpanelService.track(MixpanelEvent.ModalClose, modalTrackingContext);
        modalContentRef.destroy();
        subscription?.unsubscribe();
      }),
    );
  }

  ok(title: string, infoLines?: string[], acceptLabel?: string) {
    const modalContentRef = this.componentFactoryResolver
      .resolveComponentFactory(OKModalContentComponent)
      .create(this.injector);

    this.applicationRef.attachView(modalContentRef.hostView);
    modalContentRef.changeDetectorRef.detectChanges();

    modalContentRef.instance.titleKey = title;

    if (infoLines) {
      modalContentRef.instance.infoLabelsKey = infoLines;
    }

    if (acceptLabel) {
      modalContentRef.instance.okLabelKey = acceptLabel;
    }

    const modalRefResult = new Subject<boolean>();

    const modalTrackingContext = { modalName: 'OK' };

    this.dataDogService.addAction('OpenModal', modalTrackingContext);
    this.mixpanelService.track(MixpanelEvent.ModalOpen, modalTrackingContext);

    const modalRef = this.nzModalService.create({
      nzClassName: 'vp-modal',
      nzBodyStyle: { padding: '0' },
      nzZIndex: 1000,
      nzTitle: undefined,
      nzContent: modalContentRef.instance.modalContentTemplate,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: true,
      nzKeyboard: false,
      nzMask: true,
      nzWidth: DEFAULT_MODAL_WIDTH,
      nzOnOk: _ => modalRefResult.next(true),
      nzOnCancel: _ => modalRefResult.next(false),
    });

    const subscription = modalContentRef.instance.confirmResult.asObservable().subscribe(() => {
      modalRef.triggerOk();
    });

    return merge(modalRefResult.asObservable()).pipe(
      take(1),
      tap(() => {
        this.dataDogService.addAction('CloseModal', modalTrackingContext);
        this.mixpanelService.track(MixpanelEvent.ModalClose, modalTrackingContext);
        modalContentRef.destroy();
        subscription?.unsubscribe();
      }),
    );
  }
}
