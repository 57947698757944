import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchComponent } from './components/switch/switch.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { CheckerComponent } from './components/checker/checker.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { PopconfirmSwitchComponent } from './components/popconfirm-switch/popconfirm-switch.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NzSwitchModule, NzPopconfirmModule, TranslateModule],
  declarations: [SwitchComponent, CheckerComponent, PopconfirmSwitchComponent],
  exports: [SwitchComponent, CheckerComponent, PopconfirmSwitchComponent],
})
export class UiKitSwitchModule {}
