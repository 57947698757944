<ng-template #ctr>
  <vpfa-modal-content id="confirmChangesModal">
    <button class="ant-modal-close" nz-button nzType="link" (click)="cancel()" id="closeConfirmChangesModal">
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </button>

    <nz-spin [nzSpinning]="isLoading">
      <div class="main-container">
        <div class="d-flex flex-row justify-content-center">
          <div style="margin-bottom: 20px">
            <i class="icon zmdi zmdi-alert-circle"></i>
          </div>
        </div>
        <h3 class="heading">
          <h3>
            {{ 'common.unsavedChangesModal' | translate }}
          </h3>
          <p>
            {{ 'unsavedChanges.doYouWantExitAndSaveChanges' | translate }}
          </p>
        </h3>

        <div class="d-flex flex-row justify-content-center gap-10px">
          <div>
            <vpfa-modal-button id="dismissChangesAndExit" [outline]="true" [border]="false" (click)="dismiss()">
              <span class="cancel">{{ 'unsavedChanges.discardChangesAndExit' | translate }}</span>
            </vpfa-modal-button>
          </div>
          <div>
            <vpfa-modal-button id="saveChangesAndExit" [outline]="true" [border]="false" (click)="save()">
              <span class="confirm">
                {{ 'unsavedChanges.saveAndExit' | translate }}
              </span>
            </vpfa-modal-button>
          </div>
        </div>
      </div>
    </nz-spin>
  </vpfa-modal-content>
</ng-template>
