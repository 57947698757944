import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { LocaleFacade } from '@vpfa/locale';
import { ProfileFacade } from '@vpfa/profile/data';
import {
  UpdateExperianCredentialsCommand,
  UpdateProfileCommand,
  UpdateUserDefaultLanguageCommand,
} from '@vpfa/rest-api/admin';
import { LanguageEnum } from '@vpfa/shared/translate';
import { filter, map, take } from 'rxjs/operators';
import { ChangePasswordDto } from '../models/change-password-command-dto';
import {
  ChangePasswordError,
  fromUserProfileActions,
  LoadExperianCredentials,
  UpdateExperianCredentials,
  UserProfileActionTypes,
} from './user-profile.actions';
import { userProfileQuery } from './user-profile.selectors';
import { Observable } from 'rxjs';
import { BackendStatus } from '@vpfa/shared/types';

@Injectable()
export class UserProfileFacade {
  isProcessingForm$ = this.store.select(userProfileQuery.getIsProcessingForm);
  hasFormProcessingError$ = this.store.select(userProfileQuery.getHasFormProcessingError);
  isChangingPassword$ = this.store.select(userProfileQuery.getIsChangingPassword);
  isPasswordModalOpen$ = this.store.select(userProfileQuery.getIsPasswordModalOpen);
  isUserProfileUpdating$ = this.store.select(userProfileQuery.getIsUserProfileUpdating);
  passwordCompatible$ = this.store.select(userProfileQuery.getPasswordCompatible);
  experianCredentials$ = this.store.pipe(select(userProfileQuery.getExperianCredentials));
  isExperianCredentialsProcessing$ = this.store.pipe(select(userProfileQuery.isExperianCredentialsProcessing));

  constructor(
    private store: Store<any>,
    private localeFacade: LocaleFacade,
    private profileFacade: ProfileFacade,
    private actions$: Actions,
  ) {}

  getProfileSavingStatus$() {
    return this.actions$.pipe(
      filter(
        x =>
          x.type === UserProfileActionTypes.UpdateUserProfileSuccess ||
          x.type === UserProfileActionTypes.UpdateUserProfileError ||
          x.type === UserProfileActionTypes.UpdateExperianCredentialsSuccess ||
          x.type === UserProfileActionTypes.UpdateExperianCredentialsError,
      ),
      map(x => {
        switch (x.type) {
          case UserProfileActionTypes.UpdateUserProfileSuccess:
          case UserProfileActionTypes.UpdateExperianCredentialsSuccess:
            return 'success';
          case UserProfileActionTypes.UpdateUserProfileError:
          case UserProfileActionTypes.UpdateExperianCredentialsError:
            return 'error';
        }
      }),
    );
  }

  getSaveLanguageStatus$(): Observable<BackendStatus> {
    return this.actions$.pipe(
      filter(
        x =>
          x.type === UserProfileActionTypes.UserDefaultLanguageUpdated ||
          x.type === UserProfileActionTypes.UserUILanguageUpdated ||
          x.type === UserProfileActionTypes.UserContentLanguageUpdated ||
          x.type === UserProfileActionTypes.UserUILanguageUpdateError ||
          x.type === UserProfileActionTypes.UserContentLanguageUpdateError ||
          x.type === UserProfileActionTypes.UserDefaultLanguageUpdateError,
      ),
      map(x => {
        switch (x.type) {
          case UserProfileActionTypes.UserDefaultLanguageUpdated:
          case UserProfileActionTypes.UserUILanguageUpdated:
          case UserProfileActionTypes.UserContentLanguageUpdated:
            return 'success';
          case UserProfileActionTypes.UserUILanguageUpdateError:
          case UserProfileActionTypes.UserContentLanguageUpdateError:
          case UserProfileActionTypes.UserDefaultLanguageUpdateError:
            return 'error';
        }
      }),
    );
  }

  updateUiLanguage(updateUserSettingsCommand: UpdateUserDefaultLanguageCommand) {
    this.store.dispatch(new fromUserProfileActions.UpdateUserUILanguage(updateUserSettingsCommand));
    this.profileFacade.userContext$.pipe(take(1)).subscribe(context => {
      this.localeFacade.checkTranslateFile(updateUserSettingsCommand.uiLanguage as LanguageEnum, context.countryCode);
    });
  }
  updateContentLanguage(updateUserSettingsCommand: UpdateUserDefaultLanguageCommand) {
    this.store.dispatch(new fromUserProfileActions.UpdateUserContentLanguage(updateUserSettingsCommand));
  }

  updateUserLanguageSettings(updateUserSettingsCommand: UpdateUserDefaultLanguageCommand) {
    this.store.dispatch(new fromUserProfileActions.UpdateUserDefaultLanguage(updateUserSettingsCommand));
  }

  changePassword(command: ChangePasswordDto) {
    this.store.dispatch(new fromUserProfileActions.ChangePassword(command));
    return this.actions$.pipe(ofType<ChangePasswordError>(UserProfileActionTypes.ChangePasswordError));
  }

  resetFormStatus() {
    this.store.dispatch(new fromUserProfileActions.ResetFormStatus());
  }

  openChangePasswordModal() {
    this.store.dispatch(new fromUserProfileActions.OpenPasswordModal());
  }

  closeChangePasswordModal() {
    this.store.dispatch(new fromUserProfileActions.ClosePasswordModal());
  }

  updateUserProfile(command: UpdateProfileCommand) {
    this.store.dispatch(new fromUserProfileActions.UpdateUserProfile(command));
  }

  passwordCompatibility(compatible: boolean) {
    this.store.dispatch(new fromUserProfileActions.PasswordCompatibility(compatible));
  }

  loadExperianCredentials() {
    this.store.dispatch(new LoadExperianCredentials());
  }

  updateExperianCredentials(command: UpdateExperianCredentialsCommand) {
    this.store.dispatch(new UpdateExperianCredentials(command));
  }
}
