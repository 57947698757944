import { animate, animation, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

const defaultTimeToAutoCloseMs = 5000;
const timeStepMs = 1000;

@Component({
  selector: 'vpfa-notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate('0.2s ease-in', style({ opacity: '1', transform: 'translateY(0%)' })),
      ]),
      transition('* => void', [style({ opacity: 1 }), animate('0.2s ease-out', style({ opacity: '0' }))]),
    ]),
  ],
})
export class NotificationToastComponent {
  @HostBinding('@slideInOut')
  pauseAutoClose = false;

  closeNotificationId = null;
  toastContentId = null;

  /**
   * Is just peeking out from a bottom of a screen instead of being fully visible
   */
  public isStickingOut = true;

  /**
   * Function that will do the cleanup after toast is closed
   */
  close: () => void = undefined;
  isClosed = false;

  timeToAutoCloseMs = defaultTimeToAutoCloseMs;
  percentageLeft = 100;

  title: string;
  content: string;
  interpolateParams?: Object;

  // #region swipe animation params
  swipeBack = false;
  swipeInitialPoz = 0;
  swipePoz = 0;
  isSwipeInProgress: any | null = null;
  lastAnimTime: null | number = null;
  // #endregion

  constructor(public elementRef: ElementRef) {
    this.animate();
  }

  @HostListener('mouseover')
  onMouseOver(): void {
    this.pauseAutoClose = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.pauseAutoClose = false;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    this.pauseAutoClose = true;
    this.swipeInitialPoz = event.touches?.[0].clientX;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent): void {
    this.pauseAutoClose = false;
    this.detectSwipeType(event);
  }

  @HostListener('touchcancel', ['$event'])
  onTouchCancel(event: TouchEvent): void {
    this.pauseAutoClose = false;
    this.detectSwipeType(event);
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent): void {
    if (this.isStickingOut) {
      return;
    }

    this.handleSwipe(event);
  }

  onClose() {
    this.isClosed = true;
    this.cleanIds();

    if (!this.close) {
      console.error('Cannot close notification, close function in undefined');
    }

    this.close?.();
  }

  private detectSwipeType(event: TouchEvent) {
    if (this.isSwipeInProgress) {
      this.onClose();
    } else {
      // user swiped but then resigned
      this.swipeBack = true;
    }
  }

  private animate(time?: number) {
    if (this.isClosed) {
      return;
    }

    let delta = this.getDeltaTime(time);

    // progress bar animation
    if (delta > 0 && !this.pauseAutoClose && !this.isStickingOut) {
      this.timeToAutoCloseMs -= timeStepMs * delta;
      this.percentageLeft = Math.round((this.timeToAutoCloseMs / defaultTimeToAutoCloseMs) * 100);

      if (this.timeToAutoCloseMs <= 0) {
        this.onClose();
      }
    }

    // swipe animation
    if (delta > 0 && this.swipeBack) {
      // animate back to initial poz
      this.swipePoz += this.swipePoz * -10 * delta;

      // swipePoz will never reach 0, so we force animation to finish when it is near to the end
      if (this.swipePoz > -2 && this.swipePoz < 2) {
        this.swipePoz = 0;
        this.swipeBack = false;
      }
    }

    requestAnimationFrame(time => this.animate(time));
  }

  private getDeltaTime(time?: number) {
    let delta = 0;

    // time variable is undefined when executed for the first time
    if (time && this.lastAnimTime) {
      delta = (time - this.lastAnimTime) / 1000;
    }

    this.lastAnimTime = time;

    return delta;
  }

  private handleSwipe(event: TouchEvent) {
    this.detectSwipeResign();

    this.swipePoz = (this.swipeInitialPoz - event.touches[0].clientX) * -1;
  }

  /**
   * To detect if user started swiping, but resigned (touch movement stopped)
   */
  private detectSwipeResign() {
    clearTimeout(this.isSwipeInProgress);
    this.isSwipeInProgress = setTimeout(() => {
      clearTimeout(this.isSwipeInProgress);
      this.isSwipeInProgress = null;
    }, 200);
  }

  /**
   * To show IDs only for toast that is fully visible
   */
  public showIds() {
    this.closeNotificationId = 'closeNotificationToast';
    this.toastContentId = 'notificationToastContent';
  }

  /**
   * To show IDs only for toast that is fully visible
   */
  private cleanIds() {
    this.closeNotificationId = null;
    this.toastContentId = null;
  }
}
