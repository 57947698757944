import { Action } from '@ngrx/store';
import {
  BranchDto,
  BranchListItemDto,
  TwinnerCredentialsDto,
  UpdateBranchContactCommand,
  UpdateBranchUrlCommand
} from '@vpfa/rest-api/admin';
import {
  BranchConfigurationDto,
  CommandHandlerResponse as ValuationCommandHandlerResponse,
  ImportCustomersCommandHandlerResponse,
  LiveRetailConfigurationDto,
  OfferEmailTemplateDto,
  QuoteEmailTemplateDto,
  UpdateBranchSlowMovingKPINumberOfDaysCommand,
} from '@vpfa/rest-api/valuation';
import {
  ResellerEmailTemplateViewDto,
} from '@vpfa/rest-api/reseller-notification';
import {
  GdvInsuranceClaimingBranchConfigurationDto,
  UpdateGdvInsuranceClaimingBranchConfigurationCommand,
} from '@vpfa/rest-api/vehicle-insurance-claiming';

export enum BranchesActionTypes {
  LoadBranchesByBusiness = '[Branches] Load Branches By Business',
  BranchesLoaded = '[Branches] Branches Loaded',
  BranchesLoadError = '[Branches] Branches Load Error',

  LoadBranchesForDealerNetwork = '[Branches] Load Branches For Dealer Network',
  LoadBranchesForDealerNetworkCompleted = '[Branches] Load Branches For Dealer Network Completed',
  LoadBranchesForDealerNetworkError = '[Branches] Load Branches For Dealer Network Error',

  LoadBranchDetails = '[Branches] Load Branch Details',
  BranchDetailsLoaded = '[Branches] Branch Details Loaded',
  BranchDetailsLoadError = '[Branches] Branch Details Load Error',

  BranchUrlUpdate = '[Branches] Branch Url Update',
  BranchUrlUpdated = '[Branches] Branch Url Updated',
  BranchUrlUpdateError = '[Branches] Branch Url Update Error',

  ImportCustomersFromCsv = '[Branches] Import Customers From CSV file',
  ImportCustomersFromCsvSuccess = '[Branches] Import Customers From CSV file Success',
  ImportCustomersFromCsvError = '[Branches] Import Customers From CSV file Error',

  LoadResellersEmailTemplate = '[Branches] Load Resellers Email Template',
  ResellersEmailTemplateLoaded = '[Branches] Resellers Email Template Loaded',
  ResellersEmailTemplateLoadError = '[Branches] Resellers Email Template Load Error',

  UpdateBranchContact = '[Branches] Update Branch Contact',
  UpdateBranchContactSuccess = '[Branches] Update Branch Contact Success',
  UpdateBranchContactError = '[Branches] Update Branch Contact Error',

  LoadQuotesEmailTemplateByBranch = '[Branches] Load Quotes Email Template By Branch',
  QuotesEmailTemplateByBranchLoaded = '[Branches] Quotes Email Template By Branch Loaded',
  QuotesEmailTemplateByBranchLoadError = '[Branches] Quotes Email Template By Branch Load Error',

  LoadQuotesEmailTemplateByUserContext = '[Branches] Load Quotes Email Template By User Context',
  QuotesEmailTemplateByUserContextLoaded = '[Branches] Quotes Email Template By User Context Loaded',
  QuotesEmailTemplateByUserContextLoadError = '[Branches] Quotes Email Template By User Context Load Error',

  LoadOfferEmailTemplateByBranch = '[Branches] Load Offer Email Template By Branch',
  OfferEmailTemplateByBranchLoaded = '[Branches] Offer Email Template By Branch Loaded',
  OfferEmailTemplateByBranchLoadError = '[Branches] Offer Email Template By Branch Load Error',

  LoadOfferEmailTemplateByUserContext = '[Branches] Load Offer Email Template By User Context',
  OfferEmailTemplateByUserContextLoaded = '[Branches] Offer Email Template By User Context Loaded',
  OfferEmailTemplateByUserContextLoadError = '[Branches] Offer Email Template By User Context Load Error',

  LoadBranchConfiguration = '[Branch] Branch General :: Load Branch Configuration',
  ClearBranchConfiguration = '[Branch] Branch General :: Clear Branch Configuration',
  LoadBranchConfigurationSuccess = '[Branch] Branch General :: Load Branch Configuration Success',
  LoadBranchConfigurationError = '[Branch] Branch General :: Load Branch Configuration Error',

  UpdateBranchTolerances = '[Branch] Branches Update Branch Tolerances',
  UpdateBranchTolerancesSuccess = '[Branch] Branches Update Branch Tolerances Success',
  UpdateBranchTolerancesError = '[Branch] Branches Update Branch Tolerances Error',

  UpdateBranchStockDaysNumber = '[Branch] Branches Update Branch Stock Days',
  UpdateBranchStockDaysNumberSuccess = '[Branch] Branches Update Branch Stock Days Success',
  UpdateBranchStockDaysNumberError = '[Branch] Branches Update Branch Stock Days Error',

  LoadTwinnerCredentials = '[Branch] Branches Load Twinner Credentials',
  LoadTwinnerCredentialsError = '[Branch] Branches Load Twinner Credentials Error',
  LoadTwinnerCredentialsSuccess = '[Branch] Branches Load Twinner Credentials Success',

  ClearTwinnerCredentials = '[Branch] Branches Clear Twinner Credentials',

  LoadBranchGdvConfiguration = '[Branch] Load Branch Gdv Configuration',
  LoadBranchGdvConfigurationSuccess = '[Branch] Load Branch Gdv Configuration Success',
  LoadBranchGdvConfigurationError = '[Branch] Load Branch Gdv Configuration Error',

  UpdateBranchGdvConfiguration = '[Branch] Update Branch Gdv Configuration',
  UpdateBranchGdvConfigurationSuccess = '[Branch] Update Branch Gdv Configuration Success',
  UpdateBranchGdvConfigurationError = '[Branch] Update Branch Gdv Configuration Error',
}

export class LoadBranchesByBusiness implements Action {
  readonly type = BranchesActionTypes.LoadBranchesByBusiness;
  constructor(public payload: string) {}
}

export class BranchesLoadError implements Action {
  readonly type = BranchesActionTypes.BranchesLoadError;
}

export class BranchesLoaded implements Action {
  readonly type = BranchesActionTypes.BranchesLoaded;
  constructor(public payload: BranchListItemDto[]) {}
}

export class LoadBranchesForDealerNetwork implements Action {
  readonly type = BranchesActionTypes.LoadBranchesForDealerNetwork;
  constructor(public payload: string) {}
}

export class LoadBranchesForDealerNetworkCompleted implements Action {
  readonly type = BranchesActionTypes.LoadBranchesForDealerNetworkCompleted;
  constructor(public payload: BranchListItemDto[]) {}
}

export class LoadBranchesForDealerNetworkError implements Action {
  readonly type = BranchesActionTypes.LoadBranchesForDealerNetworkError;
}

export class LoadBranchDetails implements Action {
  readonly type = BranchesActionTypes.LoadBranchDetails;
  constructor(public payload: string) {}
}

export class BranchDetailsLoaded implements Action {
  readonly type = BranchesActionTypes.BranchDetailsLoaded;
  constructor(public payload: BranchDto) {}
}

export class BranchDetailsLoadError implements Action {
  readonly type = BranchesActionTypes.BranchDetailsLoadError;
}

export class BranchUrlUpdate implements Action {
  readonly type = BranchesActionTypes.BranchUrlUpdate;
  constructor(public payload: UpdateBranchUrlCommand) {}
}

export class BranchUrlUpdated implements Action {
  readonly type = BranchesActionTypes.BranchUrlUpdated;
  constructor(public payload: string) {}
}

export class BranchUrlUpdateError implements Action {
  readonly type = BranchesActionTypes.BranchUrlUpdateError;
}

export class ImportCustomersFromCsv implements Action {
  readonly type = BranchesActionTypes.ImportCustomersFromCsv;
  constructor(public payload: { businessId: string; branchId: string; file: Blob }) {}
}

export class ImportCustomersFromCsvSuccess implements Action {
  readonly type = BranchesActionTypes.ImportCustomersFromCsvSuccess;
  constructor(public payload: ImportCustomersCommandHandlerResponse) {}
}

export class ImportCustomersFromCsvError implements Action {
  readonly type = BranchesActionTypes.ImportCustomersFromCsvError;
}

export class LoadResellersEmailTemplate implements Action {
  readonly type = BranchesActionTypes.LoadResellersEmailTemplate;
  constructor(public payload: string) {}
}

export class ResellersEmailTemplateLoaded implements Action {
  readonly type = BranchesActionTypes.ResellersEmailTemplateLoaded;
  constructor(public payload: ResellerEmailTemplateViewDto) {}
}

export class ResellersEmailTemplateLoadError implements Action {
  readonly type = BranchesActionTypes.ResellersEmailTemplateLoadError;
}

export class UpdateBranchContact implements Action {
  readonly type = BranchesActionTypes.UpdateBranchContact;
  constructor(public payload: UpdateBranchContactCommand) {}
}

export class UpdateBranchContactSuccess implements Action {
  readonly type = BranchesActionTypes.UpdateBranchContactSuccess;
  constructor(public branchId: string) {}
}

export class UpdateBranchContactError implements Action {
  readonly type = BranchesActionTypes.UpdateBranchContactError;
}

export class LoadQuotesEmailTemplateByBranch implements Action {
  readonly type = BranchesActionTypes.LoadQuotesEmailTemplateByBranch;
  constructor(public payload: string) {}
}

export class QuotesEmailTemplateByBranchLoaded implements Action {
  readonly type = BranchesActionTypes.QuotesEmailTemplateByBranchLoaded;
  constructor(public payload: QuoteEmailTemplateDto) {}
}

export class QuotesEmailTemplateByBranchLoadError implements Action {
  readonly type = BranchesActionTypes.QuotesEmailTemplateByBranchLoadError;
}

export class LoadQuotesEmailTemplateByUserContext implements Action {
  readonly type = BranchesActionTypes.LoadQuotesEmailTemplateByUserContext;
}

export class QuotesEmailTemplateByUserContextLoaded implements Action {
  readonly type = BranchesActionTypes.QuotesEmailTemplateByUserContextLoaded;
  constructor(public payload: QuoteEmailTemplateDto) {}
}

export class QuotesEmailTemplateByUserContextLoadError implements Action {
  readonly type = BranchesActionTypes.QuotesEmailTemplateByUserContextLoadError;
}

export class LoadOfferEmailTemplateByBranch implements Action {
  readonly type = BranchesActionTypes.LoadOfferEmailTemplateByBranch;
  constructor(public payload: string) {}
}

export class OfferEmailTemplateByBranchLoaded implements Action {
  readonly type = BranchesActionTypes.OfferEmailTemplateByBranchLoaded;
  constructor(public payload: OfferEmailTemplateDto) {}
}

export class OfferEmailTemplateByBranchLoadError implements Action {
  readonly type = BranchesActionTypes.OfferEmailTemplateByBranchLoadError;
}

export class LoadOfferEmailTemplateByUserContext implements Action {
  readonly type = BranchesActionTypes.LoadOfferEmailTemplateByUserContext;
}

export class OfferEmailTemplateByUserContextLoaded implements Action {
  readonly type = BranchesActionTypes.OfferEmailTemplateByUserContextLoaded;
  constructor(public payload: OfferEmailTemplateDto) {}
}

export class OfferEmailTemplateByUserContextLoadError implements Action {
  readonly type = BranchesActionTypes.OfferEmailTemplateByUserContextLoadError;
}

export class LoadBranchConfiguration implements Action {
  readonly type = BranchesActionTypes.LoadBranchConfiguration;
  constructor(public payload: string) {}
}

export class ClearBranchConfiguration implements Action {
  readonly type = BranchesActionTypes.ClearBranchConfiguration;
}

export class LoadBranchConfigurationSuccess implements Action {
  readonly type = BranchesActionTypes.LoadBranchConfigurationSuccess;
  constructor(public payload: BranchConfigurationDto) {}
}

export class LoadBranchConfigurationError implements Action {
  readonly type = BranchesActionTypes.LoadBranchConfigurationError;
}

export class UpdateBranchTolerances implements Action {
  readonly type = BranchesActionTypes.UpdateBranchTolerances;
  constructor(public payload: LiveRetailConfigurationDto) {}
}

export class UpdateBranchTolerancesSuccess implements Action {
  readonly type = BranchesActionTypes.UpdateBranchTolerancesSuccess;
  constructor(public payload: string) {}
}

export class UpdateBranchTolerancesError implements Action {
  readonly type = BranchesActionTypes.UpdateBranchTolerancesError;
}

export class UpdateBranchStockDays implements Action {
  readonly type = BranchesActionTypes.UpdateBranchStockDaysNumber;
  constructor(public payload: UpdateBranchSlowMovingKPINumberOfDaysCommand) {}
}

export class UpdateBranchStockDaysSuccess implements Action {
  readonly type = BranchesActionTypes.UpdateBranchStockDaysNumberSuccess;
  constructor(public payload: ValuationCommandHandlerResponse) {}
}

export class UpdateBranchStockDaysError implements Action {
  readonly type = BranchesActionTypes.UpdateBranchStockDaysNumberError;
}

export class LoadTwinnerCredentials implements Action {
  readonly type = BranchesActionTypes.LoadTwinnerCredentials;
  constructor(public payload: { id: string; updatingUsername?: boolean; updatingPassword?: boolean }) {}
}

export class LoadTwinnerCredentialsError implements Action {
  readonly type = BranchesActionTypes.LoadTwinnerCredentialsError;
}

export class LoadTwinnerCredentialsSuccess implements Action {
  readonly type = BranchesActionTypes.LoadTwinnerCredentialsSuccess;
  constructor(public payload: TwinnerCredentialsDto) {}
}

export class ClearTwinnerCredentials implements Action {
  readonly type = BranchesActionTypes.ClearTwinnerCredentials;
}

export class LoadBranchGdvConfiguration implements Action {
  readonly type = BranchesActionTypes.LoadBranchGdvConfiguration;
  constructor(public payload: string) {}
}

export class LoadBranchGdvConfigurationSuccess implements Action {
  readonly type = BranchesActionTypes.LoadBranchGdvConfigurationSuccess;
  constructor(public payload: GdvInsuranceClaimingBranchConfigurationDto) {}
}

export class LoadBranchGdvConfigurationError implements Action {
  readonly type = BranchesActionTypes.LoadBranchGdvConfigurationError;
}

export class UpdateBranchGdvConfiguration implements Action {
  readonly type = BranchesActionTypes.UpdateBranchGdvConfiguration;
  constructor(public payload: UpdateGdvInsuranceClaimingBranchConfigurationCommand) {}
}

export class UpdateBranchGdvConfigurationSuccess implements Action {
  readonly type = BranchesActionTypes.UpdateBranchGdvConfigurationSuccess;
  constructor(public payload: string) {}
}

export class UpdateBranchGdvConfigurationError implements Action {
  readonly type = BranchesActionTypes.UpdateBranchGdvConfigurationError;
}

export type BranchesAction =
  | LoadBranchesByBusiness
  | BranchesLoaded
  | BranchesLoadError
  | LoadBranchesForDealerNetwork
  | LoadBranchesForDealerNetworkCompleted
  | LoadBranchesForDealerNetworkError
  | LoadBranchDetails
  | BranchDetailsLoaded
  | BranchDetailsLoadError
  | BranchUrlUpdate
  | BranchUrlUpdated
  | BranchUrlUpdateError
  | ImportCustomersFromCsv
  | ImportCustomersFromCsvSuccess
  | ImportCustomersFromCsvError
  | LoadResellersEmailTemplate
  | ResellersEmailTemplateLoaded
  | ResellersEmailTemplateLoadError
  | UpdateBranchContact
  | UpdateBranchContactSuccess
  | UpdateBranchContactError
  | LoadQuotesEmailTemplateByBranch
  | QuotesEmailTemplateByBranchLoaded
  | QuotesEmailTemplateByBranchLoadError
  | LoadOfferEmailTemplateByBranch
  | OfferEmailTemplateByBranchLoaded
  | OfferEmailTemplateByBranchLoadError
  | LoadQuotesEmailTemplateByUserContext
  | QuotesEmailTemplateByUserContextLoaded
  | QuotesEmailTemplateByUserContextLoadError
  | LoadOfferEmailTemplateByUserContext
  | OfferEmailTemplateByUserContextLoaded
  | OfferEmailTemplateByUserContextLoadError
  | LoadBranchConfiguration
  | LoadBranchConfigurationSuccess
  | LoadBranchConfigurationError
  | ClearBranchConfiguration
  | UpdateBranchTolerances
  | UpdateBranchTolerancesSuccess
  | UpdateBranchTolerancesError
  | UpdateBranchStockDays
  | UpdateBranchStockDaysSuccess
  | UpdateBranchStockDaysError
  | LoadTwinnerCredentials
  | LoadTwinnerCredentialsError
  | LoadTwinnerCredentialsSuccess
  | ClearTwinnerCredentials
  | LoadBranchGdvConfiguration
  | LoadBranchGdvConfigurationSuccess
  | LoadBranchGdvConfigurationError
  | UpdateBranchGdvConfiguration
  | UpdateBranchGdvConfigurationSuccess
  | UpdateBranchGdvConfigurationError;

export const fromBranchesActions = {
  LoadBranchesByBusiness,
  BranchesLoaded,
  BranchesLoadError,
  LoadBranchesForDealerNetwork,
  LoadBranchesForDealerNetworkCompleted,
  LoadBranchesForDealerNetworkError,
  LoadBranchDetails,
  BranchDetailsLoaded,
  BranchDetailsLoadError,
  BranchUrlUpdate,
  BranchUrlUpdated,
  BranchUrlUpdateError,
  ImportCustomersFromCsv,
  ImportCustomersFromCsvSuccess,
  ImportCustomersFromCsvError,
  LoadResellersEmailTemplate,
  ResellersEmailTemplateLoaded,
  ResellersEmailTemplateLoadError,
  UpdateBranchContact,
  UpdateBranchContactSuccess,
  UpdateBranchContactError,
  LoadQuotesEmailTemplateByBranch,
  QuotesEmailTemplateByBranchLoaded,
  QuotesEmailTemplateByBranchLoadError,
  LoadOfferEmailTemplateByBranch,
  OfferEmailTemplateByBranchLoaded,
  OfferEmailTemplateByBranchLoadError,
  LoadQuotesEmailTemplateByUserContext,
  QuotesEmailTemplateByUserContextLoaded,
  QuotesEmailTemplateByUserContextLoadError,
  LoadOfferEmailTemplateByUserContext,
  OfferEmailTemplateByUserContextLoaded,
  OfferEmailTemplateByUserContextLoadError,
  LoadBranchConfiguration,
  LoadBranchConfigurationSuccess,
  LoadBranchConfigurationError,
  ClearBranchConfiguration,
  UpdateBranchTolerances,
  UpdateBranchTolerancesSuccess,
  UpdateBranchTolerancesError,
  UpdateBranchStockDays,
  UpdateBranchStockDaysSuccess,
  UpdateBranchStockDaysError,
  LoadTwinnerCredentials,
  LoadTwinnerCredentialsError,
  LoadTwinnerCredentialsSuccess,
  ClearTwinnerCredentials,
  LoadBranchGdvConfiguration,
  LoadBranchGdvConfigurationSuccess,
  LoadBranchGdvConfigurationError,
  UpdateBranchGdvConfiguration,
  UpdateBranchGdvConfigurationSuccess,
  UpdateBranchGdvConfigurationError,
};
