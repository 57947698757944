import { Component, Input, TemplateRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vpfa-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
})
export class HeaderTitleComponent {
  @Input() title: string;
  @Input() extraLeftTemplate: TemplateRef<any>;
  @Input() extraRightTemplate: TemplateRef<any>;
  @Input() extraBottomTemplate: TemplateRef<any>;
  @Input() extraRightTemplateContext: object | undefined;
  @Input() tooltipContent: string | TemplateRef<void>;
  @Input() stickTo: 'none' | 'top' = 'none';

  @Output() back = new EventEmitter();

  get showBackButton(): boolean {
    return this.back.observers.length > 0;
  }
}
