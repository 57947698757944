<div class="toast-wrapper" [ngClass]="{ 'is-waiting-to-be-shown': isStickingOut === true }" role="alert">
  <div class="toast" [style.transform]="'translateX(' + swipePoz + 'px)'">
    <div class="icon-container">
      <i class="zmdi zmdi-badge-check success-icon"></i>
    </div>

    <div class="toast-content" [attr.id]="toastContentId">
      <h3 *ngIf="title">{{ title | translate: interpolateParams }}</h3>
      <div>{{ content | translate: interpolateParams }}</div>
    </div>

    <div class="toast-progress" [style.width.%]="percentageLeft"></div>
    <div class="close-icon-container" [attr.id]="closeNotificationId" (click)="onClose()">
      <i class="zmdi zmdi-close-circle-o"></i>
    </div>
  </div>
</div>
