import { UserContext } from '@vpfa/rest-api/admin';
import { CaseSummaryDto } from '@vpfa/rest-api/valuation';
import { OwnVehicleParams } from 'liveRetail-lib';

export function getOwnVehicleParams(
  caseData: CaseSummaryDto,
  branchZipCode: string,
  userContext: UserContext,
  caseAskingPrice: number,
): OwnVehicleParams {
  if (!caseData || !branchZipCode || !userContext) {
    return null;
  }

  return {
    ownVehicle: {
      askingPrice: caseAskingPrice,
      isoCountry: userContext.countryCode,
      isoLanguage: userContext.uiLanguage.toLowerCase(),
      vehicleType: caseData.vehicle.vehicleType.toString(),
      odometer: caseData.mileage.value,
      regDate: new Date(caseData.registrationDate).toISOString(),
      natCode: parseInt(caseData.vehicle.natcode),
      zipcode: branchZipCode,
      optionList: caseData.liveRetailOptionList ?? [],
    },
  };
}
