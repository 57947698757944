import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent {
  @Input() disabled = false;
  @Input() parentFormGroup: UntypedFormGroup;

  /**
   * If set to true, can be only controlled programmatically
   */
  @Input() controlled = false;

  @Input() set fc(fc: UntypedFormControl) {
    this._fc = fc;
  }

  @Output() switched = new EventEmitter<boolean>();

  private _fc: UntypedFormControl;

  constructor() {}

  onSwitched(value) {
    this.switched.emit(value);
  }

  get fc(): UntypedFormControl {
    return this._fc;
  }
}
