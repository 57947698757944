import { Component, EventEmitter, ViewChild, TemplateRef, Input } from '@angular/core';
import { ModalContentComponent } from '../modal-content/modal-content.component';

@Component({
  selector: 'vpfa-confirm-modal-content',
  templateUrl: './confirm-modal-content.component.html',
  styleUrls: ['./confirm-modal-content.component.scss'],
})
export class ConfirmModalContentComponent {
  @ViewChild('ctr') modalContentTemplate: TemplateRef<ModalContentComponent>;

  @Input() yesLabelKey = 'common.yes';
  @Input() noLabelKey = 'common.no';
  @Input() infoLabelsKey: string[] = [];

  confirmResult = new EventEmitter<boolean>();
}
