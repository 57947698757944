import { Action } from '@ngrx/store';
import { Entity } from './countries.reducer';
import {
  AddCountryAlternativeContentLanguageCommand,
  AddCountryAlternativeLanguageCommand,
  CommandHandlerResponse as AdminCommandHandlerResponseSuccess,
  CountryDto,
  CountryVinThresholdDto,
  ExperianCountrySettingsDto,
  SetExperianHostUrlCommand,
  TableConfigurationDto,
  UpdateCountryCurrencyCommand,
  UpdateCountryDefaultContentLanguageCommand,
  UpdateCountryDefaultLanguageCommand,
  UpdateCountryHotlineEmailCommand,
  UpdateCountryHotlinePhoneCommand,
  UpdateCountryLocaleCommand,
  UpdateCountryTimezoneCommand,
  UpdateCountryUcNumberSourceCommand,
  UpdateCountryUrlCommand,
  UpdateCountryVinThresholdCommand,
  UpdateTableConfigurationCommand,
} from '@vpfa/rest-api/admin';
import { CountryTaxDto, UpdateCountryTaxTypeCommand } from '@vpfa/rest-api/valuation';

import {
  CommandHandlerResponse,
  CommandHandlerResponse as BroadcastCommandHandlerResponseSuccess,
  CreateCountryBroadcastConnectionFtpConfigurationCommand,
  UpdateCountryBroadcastConnectionFtpConfigurationCommand,
} from '@vpfa/rest-api/ad-broadcast';
import { GdvInsuranceClaimingConfigurationDto } from '@vpfa/rest-api/vehicle-insurance-claiming';
import { LanguageEnum } from '@vpfa/shared/translate';
import { CountryBroadcastConnectionConfigurationMappingsListItemDto } from '@vpfa/rest-api/ad-broadcast';

export interface DeleteCountryAlternativeLanguageCommand {
  countryId: string;
  alternativeLanguage: LanguageEnum;
}

export interface DeleteCountryAlternativeContentLanguageCommand {
  countryId: string;
  alternativeContentLanguage: LanguageEnum;
}

export interface LogoUploadCommand {
  aggregateRootId: string;
  file: File;
}

export enum CountriesActionTypes {
  LoadCountries = '[Countries] Load Countries',
  CountriesLoaded = '[Countries] Countries Loaded',
  CountriesLoadError = '[Countries] Countries Load Error',
  LoadCountryDetails = '[Countries] Country Details :: Load Country Details',
  CountryDetailsLoaded = '[Countries] Country Details :: Country Details Loaded',
  CountryDetailsLoadError = '[Countries] Country Details :: Country Details Load Error',
  UpdateCountryDefaultLanguage = '[Countries] Country Details :: Update Country Default Language',
  CountryDefaultLanguageUpdated = '[Countries] Country Details :: Country Default Language Updated',
  CountryDefaultLanguageError = '[Countries] Country Details :: Country Default Language Error',
  UpdateCountryDefaultContentLanguage = '[Countries] Country Details :: Update Country Default Content Language',
  CountryDefaultContentLanguageUpdated = '[Countries] Country Details ::  Country Default Content Language Updated',
  CountryDefaultContentLanguageError = '[Countries] Country Details :: Country Default Content Language Error',
  AddCountryAlternativeLanguage = '[Countries] Country Details :: Add Country Alternative Language',
  RemoveCountryAlternativeLanguage = '[Countries] Country Details :: Remove Country Alternative Language',
  CountryAlternativeLanguageAdded = '[Countries] Country Details :: Country Alternative Language Added',
  CountryAlternativeLanguageRemoved = '[Countries] Country Details :: Country Alternative Language Removed',
  CountryAlternativeLanguageError = '[Countries] Country Details :: Country Alternative Language Error',
  AddCountryAlternativeContentLanguage = '[Countries] Country Details :: Update Country Alternative Content Language',
  RemoveCountryAlternativeContentLanguage = '[Countries] Country Details :: Remove Country Alternative Content Language',
  CountryAlternativeContentLanguageAdded = '[Countries] Country Details ::  Country Default Alternative Content Language Added',
  CountryAlternativeContentLanguageRemoved = '[Countries] Country Details ::  Country Default Alternative Content Language Removed',
  CountryAlternativeContentLanguageError = '[Countries] Country Details :: Country Default Alternative Content Language Error',
  UpdateCountryLocale = '[Countries] Country Details :: Update Country Locale',
  CountryLocaleUpdated = '[Countries] Country Details :: Country Locale Updated',
  CountryLocaleError = '[Countries] Country Details :: Country Locale Error',
  UpdateCountryCurrency = '[Countries] Country Details :: Update Country Currency',
  CountryCurrencyUpdated = '[Countries] Country Details :: Country Currency Updated',
  CountryCurrencyError = '[Countries] Country Details :: Country Currency Error',
  CountryValuationListInitialSetOfFieldsUpdate = '[Countries] Country Details :: Valuation List Initial Set Of Fields Update',
  CountryValuationListInitialSetOfFieldsUpdated = '[Countries] Country Details :: Valuation List Initial Set Of Fields Updated',
  CountryValuationListInitialSetOfFieldsUpdateError = '[Countries] Country Details :: Valuation List Initial Set Of Fields Update Error',
  CountryValuationListInitialSetOfFieldsLoad = '[Countries] Country Details :: Valuation List Initial Set Of Fields Load',
  CountryValuationListInitialSetOfFieldsLoaded = '[Countries] Country Details :: Valuation List Initial Set Of Fields Loaded',
  CountryValuationListInitialSetOfFieldsLoadError = '[Countries] Country Details :: Valuation List Initial Set Of Fields Load Error',
  CountryStockListInitialSetOfFieldsUpdate = '[Countries] Country Details :: Stock List Initial Set Of Fields Update',
  CountryStockListInitialSetOfFieldsUpdated = '[Countries] Country Details :: Stock List Initial Set Of Fields Updated',
  CountryStockListInitialSetOfFieldsUpdateError = '[Countries] Country Details :: Stock List Initial Set Of Fields Update Error',
  CountryStockListInitialSetOfFieldsLoad = '[Countries] Country Details :: Stock List Initial Set Of Fields Load',
  CountryStockListInitialSetOfFieldsLoaded = '[Countries] Country Details :: Stock List Initial Set Of Fields Loaded',
  CountryStockListInitialSetOfFieldsLoadError = '[Countries] Country Details :: Stock List Initial Set Of Fields Load Error',
  CountryCustomersListInitialSetOfFieldsUpdate = '[Countries] Country Details :: Customers List Initial Set Of Fields Update',
  CountryCustomersListInitialSetOfFieldsUpdated = '[Countries] Country Details :: Customers List Initial Set Of Fields Updated',
  CountryCustomersListInitialSetOfFieldsUpdateError = '[Countries] Country Details :: Customers List Initial Set Of Fields Update Error',
  CountryCustomersListInitialSetOfFieldsLoad = '[Countries] Country Details :: Customers List Initial Set Of Fields Load',
  CountryCustomersListInitialSetOfFieldsLoaded = '[Countries] Country Details :: Customers List Initial Set Of Fields Loaded',
  CountryCustomersListInitialSetOfFieldsLoadError = '[Countries] Country Details :: Customers List Initial Set Of Fields Load Error',
  UpdateCountryTimezone = '[Countries] Country Details :: Update Country Timezone',
  CountryTimezoneUpdated = '[Countries] Country Details :: Country Timezone Updated',
  CountryTimezoneError = '[Countries] Country Details :: Country Timezone Error',
  LoadCountryTaxType = '[Countries] Country General :: Load Country Tax Type',
  LoadCountryTaxTypeError = '[Countries] Country General :: Load Country Tax Type Error',
  LoadCountryTaxTypeSuccess = '[Countries] Country General :: Load Country Tax Type Success',

  LoadBroadcastList = '[Countries] Load Broadcast List',
  LoadBroadcastListError = '[Countries] Load Broadcast List Error',
  LoadBroadcastListSuccess = '[Countries] Load Broadcast List Success',

  LoadBroadcastCountryMappingList = '[Countries] Load Broadcast Country Mapping List',
  LoadBroadcastCountryMappingListError = '[Countries] Load Broadcast Country Mapping List Error',
  LoadBroadcastCountryMappingListSuccess = '[Countries] Load Broadcast Country Mapping List Success',

  LoadBroadcastDetails = '[Countries] Load Broadcast Details',
  LoadBroadcastDetailsError = '[Countries] Load Broadcast Details Error',
  LoadBroadcastDetailsSuccess = '[Countries] Load Broadcast Details Success',
  UpdateBroadcastLogo = '[Countries] Update Broadcast Logo',
  UpdateBroadcastLogoError = '[Countries] Update Broadcast Logo Error',
  UpdateBroadcastLogoSuccess = '[Countries] Update Broadcast Logo Success',

  LoadBroadcastLogo = '[Countries] Load Broadcast Logo',
  LoadBroadcastLogoError = '[Countries] Load Broadcast Logo Error',
  LoadBroadcastLogoSuccess = '[Countries] Load Broadcast Logo Success',

  LoadBroadcastCertificate = '[Countries] Load Broadcast Certificate',
  LoadBroadcastCertificateError = '[Countries] Load Broadcast Certificate Error',
  LoadBroadcastCertificateSuccess = '[Countries] Load Broadcast Certificate Success',

  CreateFtpExport = '[Countries] Create Ftp Export',
  CreateFtpExportError = '[Countries] Create Ftp Export Error',
  CreateFtpExportSuccess = '[Countries] Create Ftp Export Success',

  EditFtpExport = '[Countries] Edit Ftp Export',
  EditFtpExportError = '[Countries] Edit Ftp Export Error',
  EditFtpExportSuccess = '[Countries] Edit Ftp Export Success',

  UpdateCountryTaxType = '[Countries] Country General :: Update Country Tax Type',
  UpdateCountryTaxTypeError = '[Countries] Country General :: Update Country Tax Type Error',
  UpdateCountryTaxTypeSuccess = '[Countries] Country General :: Update Country Tax Type Success',
  UpdateUCNumberSourceType = '[Countries] Country General :: Update UC Number Source Type',
  UpdateUCNumberSourceTypeError = '[Countries] Country General :: Update UC Number Source Type Error',
  UpdateUCNumberSourceTypeSuccess = '[Countries] Country General :: Update UC Number Source Success',
  LogoLoad = '[Countries] Country General :: Logo Load ',
  LogoLoaded = '[Countries] Country General :: Logo Loaded',
  LogoLoadError = '[Countries] Country General :: Logo Load Error',
  UpdateCountryUrl = '[Countries] Country General :: Update Country Url',
  UpdateCountryUrlError = '[Countries] Country General :: Update Country Url Error',
  UpdateCountryUrlSuccess = '[Countries] Country General :: Update Country Url Success',
  UpdateCountryHotlinePhone = '[Countries] Country General :: Update Country Hotline Phone',
  UpdateCountryHotlinePhoneError = '[Countries] Country General :: Update Country Hotline Phone Error',
  UpdateCountryHotlinePhoneSuccess = '[Countries] Country General :: Update Country Hotline Phone Success',
  UpdateCountryHotlineEmail = '[Countries] Country General :: Update Country Hotline Email',
  UpdateCountryHotlineEmailError = '[Countries] Country General :: Update Country Hotline Email Error',
  UpdateCountryHotlineEmailSuccess = '[Countries] Country General :: Update Country Hotline Email Success',

  LoadVinThreshold = '[Countries] Country Load VIN Threshold',
  LoadVinThresholdSuccess = '[Countries] Country Load VIN Threshold Success',
  LoadVinThresholdError = '[Countries] Country Load VIN Threshold Error',

  UpdateVinThreshold = '[Countries] Country Update VIN Threshold',
  UpdateVinThresholdSuccess = '[Countries] Country Update VIN Threshold Success',
  UpdateVinThresholdError = '[Countries] Country Update VIN Threshold Error',

  LoadExperianCountrySettings = '[Countries] Load Experian Country Settings',
  LoadExperianCountrySettingsSuccess = '[Countries] Load Experian Country Settings Success',
  LoadExperianCountrySettingsError = '[Countries] Load Experian Country Settings Error',

  LoadCountryGdv = '[Countries] Country Gdv :: Load Country Gdv',
  LoadCountryGdvError = '[Countries] Country Gdv :: Load Country Gdv Error',
  LoadCountryGdvSuccess = '[Countries] Country Gdv :: Load Country Gdv Success',

  UpdateCountryGdvUsernameSuccess = '[Countries] Country Gdv :: Update Country Gdv Username Success',
  UpdateCountryGdvPasswordSuccess = '[Countries] Country Gdv :: Update Country Gdv Password Success',
}

export class LoadCountries implements Action {
  readonly type = CountriesActionTypes.LoadCountries;
}

export class CountriesLoadError implements Action {
  readonly type = CountriesActionTypes.CountriesLoadError;
}

export class CountriesLoaded implements Action {
  readonly type = CountriesActionTypes.CountriesLoaded;
  constructor(public payload: Entity[]) {}
}

export class LoadCountryDetails implements Action {
  readonly type = CountriesActionTypes.LoadCountryDetails;
  constructor(public payload: string) {}
}

export class CountryDetailsLoaded implements Action {
  readonly type = CountriesActionTypes.CountryDetailsLoaded;
  constructor(public payload: CountryDto) {}
}

export class CountryDetailsLoadError implements Action {
  readonly type = CountriesActionTypes.CountryDetailsLoadError;
}

export class UpdateCountryDefaultLanguage implements Action {
  readonly type = CountriesActionTypes.UpdateCountryDefaultLanguage;
  constructor(public payload: UpdateCountryDefaultLanguageCommand) {}
}

export class CountryDefaultLanguageUpdated implements Action {
  readonly type = CountriesActionTypes.CountryDefaultLanguageUpdated;
  constructor(public payload: LanguageEnum) {}
}

export class CountryDefaultLanguageError implements Action {
  readonly type = CountriesActionTypes.CountryDefaultLanguageError;
}

export class UpdateCountryDefaultContentLanguage implements Action {
  readonly type = CountriesActionTypes.UpdateCountryDefaultContentLanguage;
  constructor(public payload: UpdateCountryDefaultContentLanguageCommand) {}
}

export class CountryDefaultContentLanguageUpdated implements Action {
  readonly type = CountriesActionTypes.CountryDefaultContentLanguageUpdated;
  constructor(public payload: LanguageEnum) {}
}

export class CountryDefaultContentLanguageError implements Action {
  readonly type = CountriesActionTypes.CountryDefaultContentLanguageError;
}

export class AddCountryAlternativeLanguage implements Action {
  readonly type = CountriesActionTypes.AddCountryAlternativeLanguage;
  constructor(public payload: AddCountryAlternativeLanguageCommand) {}
}

export class RemoveCountryAlternativeLanguage implements Action {
  readonly type = CountriesActionTypes.RemoveCountryAlternativeLanguage;
  constructor(public payload: DeleteCountryAlternativeLanguageCommand) {}
}

export class CountryAlternativeLanguageAdded implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeLanguageAdded;
  constructor(public payload: LanguageEnum) {}
}

export class CountryAlternativeLanguageRemoved implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeLanguageRemoved;
  constructor(public payload: LanguageEnum) {}
}

export class CountryAlternativeLanguageError implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeLanguageError;
}

export class AddCountryAlternativeContentLanguage implements Action {
  readonly type = CountriesActionTypes.AddCountryAlternativeContentLanguage;
  constructor(public payload: AddCountryAlternativeContentLanguageCommand) {}
}

export class RemoveCountryAlternativeContentLanguage implements Action {
  readonly type = CountriesActionTypes.RemoveCountryAlternativeContentLanguage;
  constructor(public payload: DeleteCountryAlternativeContentLanguageCommand) {}
}

export class CountryAlternativeContentLanguageAdded implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeContentLanguageAdded;
  constructor(public payload: LanguageEnum) {}
}

export class CountryAlternativeContentLanguageRemoved implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeContentLanguageRemoved;
  constructor(public payload: LanguageEnum) {}
}

export class CountryAlternativeContentLanguageError implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeContentLanguageError;
}

export class UpdateCountryTimezone implements Action {
  readonly type = CountriesActionTypes.UpdateCountryTimezone;
  constructor(public payload: UpdateCountryTimezoneCommand) {}
}

export class CountryTimezoneUpdated implements Action {
  readonly type = CountriesActionTypes.CountryTimezoneUpdated;
  constructor(public payload: string) {}
}

export class CountryTimezoneError implements Action {
  readonly type = CountriesActionTypes.CountryTimezoneError;
}

export class UpdateCountryLocale implements Action {
  readonly type = CountriesActionTypes.UpdateCountryLocale;
  constructor(public payload: UpdateCountryLocaleCommand) {}
}

export class CountryLocaleUpdated implements Action {
  readonly type = CountriesActionTypes.CountryLocaleUpdated;
  constructor(public payload: string) {}
}

export class CountryLocaleError implements Action {
  readonly type = CountriesActionTypes.CountryLocaleError;
}

export class CountryValuationListInitialSetOfFieldsUpdate implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdate;
  constructor(public payload: { countryId: string; command: UpdateTableConfigurationCommand }) {}
}

export class CountryValuationListInitialSetOfFieldsUpdated implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdated;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryValuationListInitialSetOfFieldsUpdateError implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdateError;
}

export class CountryValuationListInitialSetOfFieldsLoad implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoad;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryValuationListInitialSetOfFieldsLoaded implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoaded;
  constructor(public payload: TableConfigurationDto) {}
}

export class CountryValuationListInitialSetOfFieldsLoadError implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoadError;
}

export class CountryStockListInitialSetOfFieldsUpdate implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdate;
  constructor(public payload: { countryId: string; command: UpdateTableConfigurationCommand }) {}
}

export class CountryStockListInitialSetOfFieldsUpdated implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdated;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryStockListInitialSetOfFieldsUpdateError implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdateError;
}

export class CountryStockListInitialSetOfFieldsLoad implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsLoad;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryStockListInitialSetOfFieldsLoaded implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsLoaded;
  constructor(public payload: TableConfigurationDto) {}
}

export class CountryStockListInitialSetOfFieldsLoadError implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsLoadError;
}

export class CountryCustomersListInitialSetOfFieldsUpdate implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdate;
  constructor(public payload: { countryId: string; command: UpdateTableConfigurationCommand }) {}
}

export class CountryCustomersListInitialSetOfFieldsUpdated implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdated;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryCustomersListInitialSetOfFieldsUpdateError implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdateError;
}

export class CountryCustomersListInitialSetOfFieldsLoad implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoad;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryCustomersListInitialSetOfFieldsLoaded implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoaded;
  constructor(public payload: TableConfigurationDto) {}
}

export class CountryCustomersListInitialSetOfFieldsLoadError implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoadError;
}

export class UpdateCountryCurrency implements Action {
  readonly type = CountriesActionTypes.UpdateCountryCurrency;
  constructor(public payload: UpdateCountryCurrencyCommand) {}
}

export class CountryCurrencyUpdated implements Action {
  readonly type = CountriesActionTypes.CountryCurrencyUpdated;
  constructor(public payload: string) {}
}

export class CountryCurrencyError implements Action {
  readonly type = CountriesActionTypes.CountryCurrencyError;
}

export class UpdateCountryUrl implements Action {
  readonly type = CountriesActionTypes.UpdateCountryUrl;
  constructor(public payload: UpdateCountryUrlCommand) {}
}

export class UpdateCountryUrlError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryUrlError;
}

export class UpdateCountryUrlSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateCountryUrlSuccess;
  constructor(public payload: { countryId: string; countryNamesWithSameUrl: string[] }) {}
}

export class UpdateUCNumberSourceType implements Action {
  readonly type = CountriesActionTypes.UpdateUCNumberSourceType;
  constructor(public payload: UpdateCountryUcNumberSourceCommand) {}
}

export class UpdateUCNumberSourceTypeError implements Action {
  readonly type = CountriesActionTypes.UpdateUCNumberSourceTypeError;
}

export class UpdateUCNumberSourceTypeSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateUCNumberSourceTypeSuccess;
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
}

export class LoadCountryTaxType implements Action {
  readonly type = CountriesActionTypes.LoadCountryTaxType;
  constructor(public payload: string) {}
}

export class LoadCountryTaxTypeError implements Action {
  readonly type = CountriesActionTypes.LoadCountryTaxTypeError;
}

export class LoadCountryTaxTypeSuccess implements Action {
  readonly type = CountriesActionTypes.LoadCountryTaxTypeSuccess;
  constructor(public payload: CountryTaxDto) {}
}

export class LoadBroadcastList implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastList;
  constructor(public payload: string) {}
}

export class LoadBroadcastListError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastListError;
}

export class LoadBroadcastListSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastListSuccess;
  constructor(public payload: any) {}
}

export class LoadBroadcastCountryMappingList implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCountryMappingList;
  constructor(public payload: string) {}
}

export class LoadBroadcastCountryMappingListError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCountryMappingListError;
}

export class LoadBroadcastCountryMappingListSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCountryMappingListSuccess;
  constructor(public payload: CountryBroadcastConnectionConfigurationMappingsListItemDto[]) {}
}

export class LoadBroadcastDetails implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastDetails;
  constructor(public payload: { broadcastId: string; countryId: string }) {}
}

export class LoadBroadcastDetailsError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastDetailsError;
}

export class LoadBroadcastDetailsSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastDetailsSuccess;
  constructor(public payload: any) {}
}
export class UpdateBroadcastLogo implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastLogo;
  constructor(public payload: { aggregateRootId: string; file: Blob }) {}
}

export class UpdateBroadcastLogoError implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastLogoError;
}

export class UpdateBroadcastLogoSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastLogoSuccess;
  constructor(public payload: BroadcastCommandHandlerResponseSuccess) {}
}

export class LoadBroadcastLogo implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastLogo;
  constructor(public payload: string) {}
}

export class LoadBroadcastLogoError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastLogoError;
}

export class LoadBroadcastLogoSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastLogoSuccess;
  constructor(public payload: string) {}
}

export class LoadBroadcastCertificate implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCertificate;
  constructor(public payload: string) {}
}

export class LoadBroadcastCertificateError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCertificateError;
}

export class LoadBroadcastCertificateSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCertificateSuccess;
  constructor(public payload: string) {}
}

export class CreateFtpExport implements Action {
  readonly type = CountriesActionTypes.CreateFtpExport;
  constructor(public payload: { ftpConfig: CreateCountryBroadcastConnectionFtpConfigurationCommand; file: any }) {}
}

export class CreateFtpExportError implements Action {
  readonly type = CountriesActionTypes.CreateFtpExportError;
}

export class CreateFtpExportSuccess implements Action {
  readonly type = CountriesActionTypes.CreateFtpExportSuccess;
  constructor(public payload: { res: CommandHandlerResponse; file: any }) {}
}

export class EditFtpExport implements Action {
  readonly type = CountriesActionTypes.EditFtpExport;
  constructor(
    public payload: {
      ftpConfig: UpdateCountryBroadcastConnectionFtpConfigurationCommand;
      file?: any;
    },
  ) {}
}

export class EditFtpExportError implements Action {
  readonly type = CountriesActionTypes.EditFtpExportError;
}

export class EditFtpExportSuccess implements Action {
  readonly type = CountriesActionTypes.EditFtpExportSuccess;
  constructor(public payload: { res: CommandHandlerResponse; file?: any }) {}
}

export class UpdateCountryTaxType implements Action {
  readonly type = CountriesActionTypes.UpdateCountryTaxType;
  constructor(public payload: UpdateCountryTaxTypeCommand) {}
}

export class UpdateCountryTaxTypeError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryTaxTypeError;
}

export class UpdateCountryTaxTypeSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateCountryTaxTypeSuccess;
}

export class LogoLoad implements Action {
  readonly type = CountriesActionTypes.LogoLoad;
  constructor(public payload: string) {}
}

export class LogoLoaded implements Action {
  readonly type = CountriesActionTypes.LogoLoaded;
  constructor(public payload: string) {}
}

export class LogoLoadError implements Action {
  readonly type = CountriesActionTypes.LogoLoadError;
}

export class UpdateCountryHotlinePhone implements Action {
  readonly type = CountriesActionTypes.UpdateCountryHotlinePhone;
  constructor(public payload: UpdateCountryHotlinePhoneCommand) {}
}

export class UpdateCountryHotlinePhoneError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryHotlinePhoneError;
}

export class UpdateCountryHotlinePhoneSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateCountryHotlinePhoneSuccess;
}

export class UpdateCountryHotlineEmail implements Action {
  readonly type = CountriesActionTypes.UpdateCountryHotlineEmail;
  constructor(public payload: UpdateCountryHotlineEmailCommand) {}
}

export class UpdateCountryHotlineEmailError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryHotlineEmailError;
}

export class UpdateCountryHotlineEmailSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateCountryHotlineEmailSuccess;
}

export class LoadVinThreshold implements Action {
  readonly type = CountriesActionTypes.LoadVinThreshold;
  constructor(public payload: string) {}
}

export class LoadVinThresholdSuccess implements Action {
  readonly type = CountriesActionTypes.LoadVinThresholdSuccess;
  constructor(public payload: CountryVinThresholdDto) {}
}

export class LoadVinThresholdError implements Action {
  readonly type = CountriesActionTypes.LoadVinThresholdError;
}

export class UpdateVinThreshold implements Action {
  readonly type = CountriesActionTypes.UpdateVinThreshold;
  constructor(public payload: UpdateCountryVinThresholdCommand) {}
}

export class UpdateVinThresholdSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateVinThresholdSuccess;
  constructor(public payload: string) {}
}

export class UpdateVinThresholdError implements Action {
  readonly type = CountriesActionTypes.UpdateVinThresholdError;
}

export class LoadExperianCountrySettings implements Action {
  readonly type = CountriesActionTypes.LoadExperianCountrySettings;
  constructor(public payload: string) {}
}

export class LoadExperianCountrySettingsSuccess implements Action {
  readonly type = CountriesActionTypes.LoadExperianCountrySettingsSuccess;
  constructor(public payload: ExperianCountrySettingsDto) {}
}

export class LoadExperianCountrySettingsError implements Action {
  readonly type = CountriesActionTypes.LoadExperianCountrySettingsError;
}

export class LoadCountryGdv implements Action {
  readonly type = CountriesActionTypes.LoadCountryGdv;
  constructor(public payload: string) {}
}

export class LoadCountryGdvError implements Action {
  readonly type = CountriesActionTypes.LoadCountryGdvError;
}

export class LoadCountryGdvSuccess implements Action {
  readonly type = CountriesActionTypes.LoadCountryGdvSuccess;
  constructor(public payload: GdvInsuranceClaimingConfigurationDto) {}
}

export class UpdateCountryGdvUsernameSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateCountryGdvUsernameSuccess;
  constructor(public payload: string) {}
}

export class UpdateCountryGdvPasswordSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateCountryGdvPasswordSuccess;
  constructor(public payload: string) {}
}

export type CountriesAction =
  | LoadCountries
  | CountriesLoaded
  | CountriesLoadError
  | LoadCountryDetails
  | CountryDetailsLoaded
  | CountryDetailsLoadError
  | UpdateCountryDefaultLanguage
  | CountryDefaultLanguageUpdated
  | CountryDefaultLanguageError
  | UpdateCountryDefaultContentLanguage
  | CountryDefaultContentLanguageUpdated
  | CountryDefaultContentLanguageError
  | AddCountryAlternativeLanguage
  | RemoveCountryAlternativeLanguage
  | CountryAlternativeLanguageAdded
  | CountryAlternativeLanguageRemoved
  | CountryAlternativeLanguageError
  | AddCountryAlternativeContentLanguage
  | RemoveCountryAlternativeContentLanguage
  | CountryAlternativeContentLanguageAdded
  | CountryAlternativeContentLanguageRemoved
  | CountryAlternativeContentLanguageError
  | UpdateCountryLocale
  | CountryLocaleUpdated
  | CountryLocaleError
  | UpdateCountryCurrency
  | CountryCurrencyUpdated
  | CountryCurrencyError
  | CountryTimezoneError
  | CountryTimezoneUpdated
  | CountryValuationListInitialSetOfFieldsUpdate
  | CountryValuationListInitialSetOfFieldsUpdated
  | CountryValuationListInitialSetOfFieldsUpdateError
  | CountryValuationListInitialSetOfFieldsLoad
  | CountryValuationListInitialSetOfFieldsLoaded
  | CountryValuationListInitialSetOfFieldsLoadError
  | CountryStockListInitialSetOfFieldsUpdate
  | CountryStockListInitialSetOfFieldsUpdated
  | CountryStockListInitialSetOfFieldsUpdateError
  | CountryStockListInitialSetOfFieldsLoad
  | CountryStockListInitialSetOfFieldsLoaded
  | CountryStockListInitialSetOfFieldsLoadError
  | CountryCustomersListInitialSetOfFieldsUpdate
  | CountryCustomersListInitialSetOfFieldsUpdated
  | CountryCustomersListInitialSetOfFieldsUpdateError
  | CountryCustomersListInitialSetOfFieldsLoad
  | CountryCustomersListInitialSetOfFieldsLoaded
  | CountryCustomersListInitialSetOfFieldsLoadError
  | UpdateCountryTimezone
  | LoadCountryTaxType
  | LoadCountryTaxTypeError
  | LoadCountryTaxTypeSuccess
  | UpdateCountryTaxType
  | UpdateCountryTaxTypeError
  | UpdateCountryTaxTypeSuccess
  | UpdateUCNumberSourceType
  | UpdateUCNumberSourceTypeSuccess
  | UpdateUCNumberSourceTypeError
  | LogoLoad
  | LogoLoaded
  | LogoLoadError
  | UpdateCountryUrl
  | UpdateCountryUrlError
  | UpdateCountryUrlSuccess
  | UpdateCountryHotlinePhone
  | UpdateCountryHotlinePhoneError
  | UpdateCountryHotlinePhoneSuccess
  | UpdateCountryHotlineEmail
  | UpdateCountryHotlineEmailError
  | UpdateCountryHotlineEmailSuccess
  | LoadBroadcastList
  | LoadBroadcastListError
  | LoadBroadcastListSuccess
  | LoadBroadcastCountryMappingList
  | LoadBroadcastCountryMappingListError
  | LoadBroadcastCountryMappingListSuccess
  | LoadBroadcastDetails
  | LoadBroadcastDetailsError
  | LoadBroadcastDetailsSuccess
  | UpdateBroadcastLogo
  | UpdateBroadcastLogoError
  | UpdateBroadcastLogoSuccess
  | LoadBroadcastLogo
  | LoadBroadcastLogoError
  | LoadBroadcastLogoSuccess
  | LoadBroadcastCertificate
  | LoadBroadcastCertificateError
  | LoadBroadcastCertificateSuccess
  | CreateFtpExport
  | CreateFtpExportError
  | CreateFtpExportSuccess
  | EditFtpExport
  | EditFtpExportError
  | EditFtpExportSuccess
  | LoadVinThreshold
  | LoadVinThresholdSuccess
  | LoadVinThresholdError
  | UpdateVinThreshold
  | UpdateVinThresholdSuccess
  | UpdateVinThresholdError
  | LoadExperianCountrySettings
  | LoadExperianCountrySettingsSuccess
  | LoadExperianCountrySettingsError
  | LoadCountryGdv
  | LoadCountryGdvError
  | LoadCountryGdvSuccess
  | UpdateCountryGdvUsernameSuccess
  | UpdateCountryGdvPasswordSuccess;

export const fromCountriesActions = {
  LoadCountries,
  CountriesLoaded,
  CountriesLoadError,
  LoadCountryDetails,
  CountryDetailsLoaded,
  CountryDetailsLoadError,
  UpdateCountryDefaultLanguage,
  CountryDefaultLanguageUpdated,
  CountryDefaultLanguageError,
  UpdateCountryDefaultContentLanguage,
  CountryDefaultContentLanguageUpdated,
  CountryDefaultContentLanguageError,
  AddCountryAlternativeLanguage,
  RemoveCountryAlternativeLanguage,
  CountryAlternativeLanguageAdded,
  CountryAlternativeLanguageRemoved,
  CountryAlternativeLanguageError,
  AddCountryAlternativeContentLanguage,
  RemoveCountryAlternativeContentLanguage,
  CountryAlternativeContentLanguageAdded,
  CountryAlternativeContentLanguageRemoved,
  CountryAlternativeContentLanguageError,
  UpdateCountryLocale,
  CountryLocaleUpdated,
  CountryLocaleError,
  CountryValuationListInitialSetOfFieldsUpdate,
  CountryValuationListInitialSetOfFieldsUpdated,
  CountryValuationListInitialSetOfFieldsUpdateError,
  CountryValuationListInitialSetOfFieldsLoad,
  CountryValuationListInitialSetOfFieldsLoaded,
  CountryValuationListInitialSetOfFieldsLoadError,
  CountryStockListInitialSetOfFieldsUpdate,
  CountryStockListInitialSetOfFieldsUpdated,
  CountryStockListInitialSetOfFieldsUpdateError,
  CountryStockListInitialSetOfFieldsLoad,
  CountryStockListInitialSetOfFieldsLoaded,
  CountryStockListInitialSetOfFieldsLoadError,
  CountryCustomersListInitialSetOfFieldsUpdate,
  CountryCustomersListInitialSetOfFieldsUpdated,
  CountryCustomersListInitialSetOfFieldsUpdateError,
  CountryCustomersListInitialSetOfFieldsLoad,
  CountryCustomersListInitialSetOfFieldsLoaded,
  CountryCustomersListInitialSetOfFieldsLoadError,
  UpdateCountryCurrency,
  CountryCurrencyUpdated,
  CountryCurrencyError,
  UpdateCountryTimezone,
  CountryTimezoneUpdated,
  CountryTimezoneError,
  LoadCountryTaxType,
  LoadCountryTaxTypeError,
  LoadCountryTaxTypeSuccess,
  UpdateCountryTaxType,
  UpdateCountryTaxTypeError,
  UpdateCountryTaxTypeSuccess,
  UpdateUCNumberSourceType,
  UpdateUCNumberSourceTypeSuccess,
  UpdateUCNumberSourceTypeError,
  LogoLoad,
  LogoLoaded,
  LogoLoadError,
  UpdateCountryUrl,
  UpdateCountryUrlError,
  UpdateCountryUrlSuccess,
  UpdateCountryHotlinePhone,
  UpdateCountryHotlinePhoneError,
  UpdateCountryHotlinePhoneSuccess,
  UpdateCountryHotlineEmail,
  UpdateCountryHotlineEmailError,
  UpdateCountryHotlineEmailSuccess,
  LoadBroadcastList,
  LoadBroadcastListError,
  LoadBroadcastListSuccess,
  LoadBroadcastCountryMappingList,
  LoadBroadcastCountryMappingListError,
  LoadBroadcastCountryMappingListSuccess,
  LoadBroadcastDetails,
  LoadBroadcastDetailsError,
  LoadBroadcastDetailsSuccess,
  UpdateBroadcastLogo,
  UpdateBroadcastLogoError,
  UpdateBroadcastLogoSuccess,
  LoadBroadcastLogo,
  LoadBroadcastLogoError,
  LoadBroadcastLogoSuccess,
  LoadBroadcastCertificate,
  LoadBroadcastCertificateError,
  LoadBroadcastCertificateSuccess,
  CreateFtpExport,
  CreateFtpExportError,
  CreateFtpExportSuccess,
  EditFtpExport,
  EditFtpExportError,
  EditFtpExportSuccess,
  LoadVinThreshold,
  LoadVinThresholdSuccess,
  LoadVinThresholdError,
  UpdateVinThreshold,
  UpdateVinThresholdSuccess,
  UpdateVinThresholdError,
  LoadExperianCountrySettings,
  LoadExperianCountrySettingsSuccess,
  LoadExperianCountrySettingsError,
  LoadCountryGdv,
  LoadCountryGdvError,
  LoadCountryGdvSuccess,
  UpdateCountryGdvUsernameSuccess,
  UpdateCountryGdvPasswordSuccess,
};
