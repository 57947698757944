import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { broadcastQuery } from './broadcast.selectors';
import {
  BroadcastActionTypes,
  CaseBroadcastResetStore,
  CloseBroadcastUpdateConfirmModal,
  LoadBroadcastingItemLogo,
  LoadPortalBroadcastRequestsHistory,
  LoadPortalBroadcastStatuses,
  OpenBroadcastUpdateConfirmModal,
  RefreshBroadcastStatuses,
  RenewMobileBroadcast,
  ToggleAllCaseBroadcastsIsActiveState,
  ValidateAndUpdateAdvert,
} from './broadcast.actions';
import { BroadcastPartialState } from './broadcast.reducer';
import { CreateCaseBroadcastSettingsCommand } from '@vpfa/rest-api/ad-broadcast';
import { UpdateCaseUniqueDataCommand, UpdateIdentifiedVehicleTechnicalDataCommand } from '@vpfa/rest-api/valuation';
import { Actions } from '@ngrx/effects';
import { CasesActionTypes } from '@vpfa/dealer/case/data';
import { filter, map } from 'rxjs';

@Injectable()
export class BroadcastFacade {
  activeCaseId$ = this.store.pipe(select(broadcastQuery.getActiveCaseId));
  isLoading$ = this.store.pipe(select(broadcastQuery.getIsLoadingSettings));
  broadcastSettings$ = this.store.pipe(select(broadcastQuery.getBroadcastSettings));
  broadcastingItemLogo$ = (adPortalId: string) =>
    this.store.pipe(select(broadcastQuery.getBroadcastingItemLogo, adPortalId));
  isBroadcastingItemLogoLoading$ = (adPortalId: string) =>
    this.store.pipe(select(broadcastQuery.getIsBroadcastingItemLogoLoading, adPortalId));
  isCreatingAdvert$ = this.store.pipe(select(broadcastQuery.getIsCreatingAdvert));
  updateError$ = this.store.pipe(select(broadcastQuery.getIsUpdateError));
  adPortalCaseBroadcastRequestHistoryList$ = this.store.pipe(
    select(broadcastQuery.getAdPortalCaseBroadcastRequestHistoryList),
  );
  isAdPortalCaseBroadcastRequestHistoryListLoading$ = this.store.pipe(
    select(broadcastQuery.getIsAdPortalCaseBroadcastRequestHistoryListLoading),
  );

  adPortalCaseBroadcastStatusList$ = this.store.pipe(select(broadcastQuery.getAdPortalCaseBroadcastStatusList));

  confirmModalOpen$ = this.store.pipe(select(broadcastQuery.getConfirmModalOpen));

  constructor(
    private store: Store<BroadcastPartialState>,
    private actions$: Actions,
  ) {}

  getBroadcastConfigUpdateStatus$() {
    return this.actions$.pipe(
      filter(
        x =>
          x.type === BroadcastActionTypes.UpdateAdvertConfigurationSuccess ||
          x.type === BroadcastActionTypes.UpdateAdvertConfigurationError ||
          x.type === BroadcastActionTypes.CloseBroadcastUpdateConfirmModal ||
          x.type === CasesActionTypes.CaseUpdateTechnicalDataError ||
          x.type === CasesActionTypes.CaseUpdateValuationUniqueDataError ||
          x.type === BroadcastActionTypes.ValidateAdvertError,
      ),
      map(x => {
        switch (x.type) {
          case BroadcastActionTypes.UpdateAdvertConfigurationSuccess:
            return 'success';
          case BroadcastActionTypes.UpdateAdvertConfigurationError:
          case CasesActionTypes.CaseUpdateTechnicalDataError:
          case CasesActionTypes.CaseUpdateValuationUniqueDataError:
            return 'error';
          case BroadcastActionTypes.CloseBroadcastUpdateConfirmModal:
            // return error to force close unsaved changes modal together with update config modal
            return 'error';
          case BroadcastActionTypes.ValidateAdvertError:
            return 'validationError';
        }
      }),
    );
  }

  loadBroadcastingItemLogo(broadcastConnectionConfigurationId: string) {
    this.store.dispatch(new LoadBroadcastingItemLogo(broadcastConnectionConfigurationId));
  }

  validateAndUpdateAdvert(
    broadcastSettings: CreateCaseBroadcastSettingsCommand,
    technicalData: UpdateIdentifiedVehicleTechnicalDataCommand,
  ) {
    this.store.dispatch(
      new ValidateAndUpdateAdvert({
        broadcastSettings: broadcastSettings,
        technicalData: technicalData,
        updatedFromBroadcast: true,
      }),
    );
  }

  validateAndUpdateAdvertWithUniqueData(
    broadcastSettings: CreateCaseBroadcastSettingsCommand,
    technicalData: UpdateIdentifiedVehicleTechnicalDataCommand,
    uniqueData: UpdateCaseUniqueDataCommand,
  ) {
    this.store.dispatch(
      new ValidateAndUpdateAdvert({
        broadcastSettings: broadcastSettings,
        technicalData: technicalData,
        updatedFromBroadcast: true,
        uniqueData: uniqueData,
      }),
    );
  }

  toggleAllPortalsDisabled() {
    this.store.dispatch(new ToggleAllCaseBroadcastsIsActiveState(false));
  }

  toggleAllPortalsEnabled() {
    this.store.dispatch(new ToggleAllCaseBroadcastsIsActiveState(true));
  }

  loadAdPortalCaseBroadcastHistoryList(aggregateRootId: string, broadcastConfigurationConnectionId: string) {
    this.store.dispatch(
      new LoadPortalBroadcastRequestsHistory({ caseId: aggregateRootId, broadcastConfigurationConnectionId }),
    );
  }

  refreshStatusesAndLoadSettings(caseId: string) {
    this.store.dispatch(new RefreshBroadcastStatuses(caseId));
  }

  loadAdPortalCaseBroadcastStatuses(broadcastConfigurationConnectionId: string) {
    this.store.dispatch(new LoadPortalBroadcastStatuses(broadcastConfigurationConnectionId));
  }

  renewMobileBroadcast(command) {
    this.store.dispatch(new RenewMobileBroadcast(command));
  }

  openConfirmModal() {
    this.store.dispatch(new OpenBroadcastUpdateConfirmModal());
  }

  closeConfirmModal() {
    this.store.dispatch(new CloseBroadcastUpdateConfirmModal());
  }

  resetStore() {
    this.store.dispatch(new CaseBroadcastResetStore());
  }
}
