import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { CountriesPartialState } from './countries.reducer';
import { countriesQuery } from './countries.selectors';
import {
  AddCountryAlternativeContentLanguage,
  AddCountryAlternativeLanguage,
  CountriesActionTypes,
  CountryCurrencyUpdated,
  CountryDefaultContentLanguageUpdated,
  CountryDefaultLanguageUpdated,
  CountryDetailsLoaded,
  CountryLocaleUpdated,
  CountryTimezoneUpdated,
  CreateFtpExport,
  DeleteCountryAlternativeContentLanguageCommand,
  DeleteCountryAlternativeLanguageCommand,
  EditFtpExport,
  fromCountriesActions,
  LoadBroadcastCertificate,
  LoadBroadcastCountryMappingList,
  LoadBroadcastDetails,
  LoadBroadcastList,
  LoadBroadcastLogo,
  LoadCountries,
  LoadCountryDetails,
  LoadCountryGdv,
  LoadCountryTaxType,
  LoadCountryTaxTypeSuccess,
  LoadExperianCountrySettings,
  LoadVinThreshold,
  LogoUploadCommand,
  RemoveCountryAlternativeContentLanguage,
  RemoveCountryAlternativeLanguage,
  UpdateCountryGdvPasswordSuccess,
  UpdateCountryGdvUsernameSuccess,
  UpdateCountryHotlineEmail,
  UpdateCountryHotlinePhone,
  UpdateCountryTaxType,
  UpdateCountryUrl,
  UpdateUCNumberSourceType,
  UpdateVinThreshold,
} from './countries.actions';

import {
  AddCountryAlternativeContentLanguageCommand,
  AddCountryAlternativeLanguageCommand,
  CountryDto,
  UpdateCountryHotlineEmailCommand,
  UpdateCountryHotlinePhoneCommand,
  UpdateCountryUcNumberSourceCommand,
  UpdateCountryUrlCommand,
  UpdateCountryVinThresholdCommand,
  UpdateTableConfigurationCommand,
} from '@vpfa/rest-api/admin';
import { CountryTaxDto, UpdateCountryTaxTypeCommand } from '@vpfa/rest-api/valuation';
import {
  CreateCountryBroadcastConnectionFtpConfigurationCommand,
  UpdateCountryBroadcastConnectionFtpConfigurationCommand,
} from '@vpfa/rest-api/ad-broadcast';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { camelCase, isNil } from 'lodash';
import { SelectOption } from '@vpfa/ui-kit';
import { LanguageEnum } from 'libs/shared/translate/enums/language.enum';
import { Actions } from '@ngrx/effects';
import { UnsavedChangesStatus } from '@vpfa/shared/types';

@Injectable()
export class CountriesFacade {
  isListLoaded$ = this.store.pipe(select(countriesQuery.getListLoaded));
  isListLoading$ = this.store.pipe(select(countriesQuery.getListLoading));
  isLoadingValuationListInitialSetOfFields$ = this.store.pipe(
    select(countriesQuery.getIsLoadingValuationListInitialSetOfFields),
  );
  isLoadingStockListInitialSetOfFields$ = this.store.pipe(
    select(countriesQuery.getIsLoadingStockListInitialSetOfFields),
  );
  isLoadingCustomersListInitialSetOfFields$ = this.store.pipe(
    select(countriesQuery.getIsLoadingCustomersListInitialSetOfFields),
  );
  getCountriesList$ = this.store.pipe(select(countriesQuery.getCountriesList));
  getCountriesListOptions$ = this.store.pipe(select(countriesQuery.getCountriesListOptions));
  getValuationListInitialSetOfFieldsData$ = this.store.pipe(
    select(countriesQuery.getValuationListInitialSetOfFieldsData),
  );
  getStockListInitialSetOfFieldsData$ = this.store.pipe(select(countriesQuery.getStockListInitialSetOfFieldsData));
  getCustomersListInitialSetOfFieldsData$ = this.store.pipe(
    select(countriesQuery.getCustomersListInitialSetOfFieldsData),
  );

  countryDetails$ = this.store.pipe(select(countriesQuery.getCountryDetails));
  countryHotlinePhone$ = this.store.pipe(select(countriesQuery.getCountryHotlinePhone));
  countryHotlineEmail$ = this.store.pipe(select(countriesQuery.getCountryHotlineEmail));
  countryCode$ = this.store.pipe(select(countriesQuery.getCountryCode));
  countryUCNumberSource$ = this.store.pipe(select(countriesQuery.getCountryUCNumberSource));
  countryCurrency$ = this.store.pipe(select(countriesQuery.getCountryCurrency));

  countryLanguagesOptions$ = this.countryDetails$.pipe(
    filter(country => !isNil(country)),
    map(country =>
      [country.defaultLanguage, ...country.alternativeLanguages].map(code => {
        return <SelectOption>{
          value: code,
          name: `admin.country.languages.${camelCase(code)}`,
          additional: {
            isDefaultLanguage: country.defaultLanguage === code,
            countryCode: country.countryCode,
          },
        };
      }),
    ),
  );

  isProcessingCountryUCNumberSource$ = this.store.pipe(select(countriesQuery.getisProcessCountryUCNumberSource));
  isCountryDetailsLoading$ = this.store.pipe(select(countriesQuery.getCountryDetailsLoading));

  isCountryDefaultLanguageUpdating$ = this.store.pipe(select(countriesQuery.getCountryDefaultLanguageUpdating));
  isCountryDefaultContentLanguageUpdating$ = this.store.pipe(
    select(countriesQuery.getCountryDefaultContentLanguageUpdating),
  );

  isCountryAlternativeLanguageUpdating$ = this.store.pipe(select(countriesQuery.getCountryAlternativeLanguageUpdating));
  isCountryAlternativeContentLanguageUpdating$ = this.store.pipe(
    select(countriesQuery.getCountryAlternativeContentLanguageUpdating),
  );

  countryTaxType$ = this.store.pipe(select(countriesQuery.getCountryTaxType));
  isProcessingCountryTax$ = this.store.pipe(select(countriesQuery.getIsProcessingCountryTax));
  broadcastList$ = this.store.pipe(select(countriesQuery.getBroadcastList));
  broadcastListOptions$ = this.store.pipe(select(countriesQuery.getBroadcastListOptions));
  broadcastListLoading$ = this.store.pipe(select(countriesQuery.getBroadcastListLoading));
  broadcastCountryId$ = this.store.pipe(select(countriesQuery.getBroadcastCountryId));

  getCountryGdvConfigId$ = this.store.pipe(select(countriesQuery.getCountryGdvConfigId));
  getCountryGdvConfigUsername$ = this.store.pipe(select(countriesQuery.getCountryGdvConfigUsername));
  getIsCountryGdvConfigPasswordSet$ = this.store.pipe(select(countriesQuery.getIsCountryGdvConfigPasswordSet));

  isCountryGdvLoading$ = this.store.pipe(select(countriesQuery.isCountryGdvLoading));

  isCountryContactUsCountryCodeUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsCountryCodeUpdating),
  );
  isCountryContactUsDefaultPriorityUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsDefaultPriorityUpdating),
  );
  isCountryContactUsDefaultTypeUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsDefaultTypeUpdating),
  );
  isCountryContactUsDefaultClassificationUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsDefaultClassificationUpdating),
  );
  isCountryContactUsEnquiryTypeUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsEnquiryTypeUpdating),
  );
  isCountryContactUsHeaderTextsUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsHeaderTextsUpdating),
  );

  get isCountryLocaleUpdating$() {
    return this.store.pipe(select(countriesQuery.getCountryLocaleUpdating));
  }

  get isCountryCurrencyUpdating$() {
    return this.store.pipe(select(countriesQuery.getCountryCurrencyUpdating));
  }

  get isCountryTimezoneUpdating$() {
    return this.store.pipe(select(countriesQuery.getCountryTimezoneUpdating));
  }

  get logo$() {
    return this.store.pipe(select(countriesQuery.getCountryLogo));
  }

  get isLogoLoading$() {
    return this.store.pipe(select(countriesQuery.isCountryLogoLoading));
  }

  get isLogoUploading$() {
    return this.store.pipe(select(countriesQuery.isCountryLogoUploading));
  }

  get countryUrl$() {
    return this.store.pipe(select(countriesQuery.getCountryUrl));
  }

  get isProcessingCountryUrl$() {
    return this.store.pipe(select(countriesQuery.getIsProcessingCountryUrl));
  }

  get isProcessingCountryHotlinePhone$() {
    return this.store.pipe(select(countriesQuery.getIsProcessingCountryHotlinePhone));
  }

  get isProcessingCountryHotlineEmail$() {
    return this.store.pipe(select(countriesQuery.getIsProcessingCountryHotlineEmail));
  }

  get broadcastListForMappingItems$() {
    return this.store.pipe(select(countriesQuery.getBroadcastListForMappingItems));
  }

  get isBroadcastListForMappingLoading$() {
    return this.store.pipe(select(countriesQuery.getBroadcastListForMappingLoading));
  }

  broadcastDetails$ = this.store.pipe(select(countriesQuery.getBroadcastDetails));
  broadcastDetailsLoading$ = this.store.pipe(select(countriesQuery.getBroadcastDetailsLoading));
  broadcastLogo$ = this.store.pipe(select(countriesQuery.getBroadcastLogo));
  broadcastCertificate$ = this.store.pipe(select(countriesQuery.getBroadcastCertificate));
  broadcastProcessing$ = this.store.pipe(select(countriesQuery.getBroadcastProcessing));
  ftpCreated$ = this.store.pipe(select(countriesQuery.getFtpCreated));
  ftpUpdated$ = this.store.pipe(select(countriesQuery.getFtpUpdated));

  getAvailableVinCallsThreshold$ = this.store.pipe(select(countriesQuery.getVinThreshold));
  isProcessingVinCalls$ = this.store.pipe(select(countriesQuery.isVinThresholdProcessing));

  experianCountryHostUrl$ = this.store.pipe(select(countriesQuery.getExperianCountryHostUrl));
  experianCountryTermsAndConditionsUrl$ = this.store.pipe(
    select(countriesQuery.getExperianCountryTermsAndConditionsUrl),
  );
  isExperianCountrySettingsLoading$ = this.store.pipe(select(countriesQuery.isExperianCountrySettingsLoading));
  isExperianCountrySettingsProcessing$ = this.store.pipe(select(countriesQuery.isExperianCountrySettingsProcessing));

  get countryListAfterLoaded$() {
    return combineLatest([this.getCountriesList$, this.isListLoaded$]).pipe(
      filter(([_, isLoaded]) => isLoaded),
      map(([list]) => list),
    );
  }

  constructor(
    private store: Store<CountriesPartialState>,
    private actions$: Actions,
  ) {}

  saveFtpExportStatus$(): Observable<UnsavedChangesStatus> {
    return this.actions$.pipe(
      filter(
        x =>
          x.type === CountriesActionTypes.EditFtpExportSuccess ||
          x.type === CountriesActionTypes.EditFtpExportError ||
          x.type === CountriesActionTypes.CreateFtpExportSuccess ||
          x.type === CountriesActionTypes.CreateFtpExportError,
      ),
      map(x => {
        switch (x.type) {
          case CountriesActionTypes.EditFtpExportSuccess:
          case CountriesActionTypes.CreateFtpExportSuccess:
            return 'success';
          case CountriesActionTypes.EditFtpExportError:
          case CountriesActionTypes.CreateFtpExportError:
            return 'error';
        }
      }),
    );
  }

  loadAll() {
    this.store.dispatch(new LoadCountries());
  }

  loadCountryDetails(countryId: string) {
    this.store.dispatch(new LoadCountryDetails(countryId));
  }

  loadBroadcastCountryMappingList(countryId: string) {
    this.store.dispatch(new LoadBroadcastCountryMappingList(countryId));
  }

  loadedCountryDetails(country: CountryDto) {
    this.store.dispatch(new CountryDetailsLoaded(country));
  }

  updateCountryDefaultLanguage(language: LanguageEnum) {
    this.store.dispatch(new CountryDefaultLanguageUpdated(language));
  }

  updateCountryDefaultContentLanguage(language: LanguageEnum) {
    this.store.dispatch(new CountryDefaultContentLanguageUpdated(language));
  }

  addCountryAlternativeLanguage(command: AddCountryAlternativeLanguageCommand) {
    this.store.dispatch(new AddCountryAlternativeLanguage(command));
  }

  removeCountryAlternativeLanguage(command: DeleteCountryAlternativeLanguageCommand) {
    this.store.dispatch(new RemoveCountryAlternativeLanguage(command));
  }

  addCountryAlternativeContentLanguage(command: AddCountryAlternativeContentLanguageCommand) {
    this.store.dispatch(new AddCountryAlternativeContentLanguage(command));
  }

  removeCountryAlternativeContentLanguage(command: DeleteCountryAlternativeContentLanguageCommand) {
    this.store.dispatch(new RemoveCountryAlternativeContentLanguage(command));
  }

  updateCountryLocale(locale: string) {
    this.store.dispatch(new CountryLocaleUpdated(locale));
  }

  updateCountryTimezone(timezone: string) {
    this.store.dispatch(new CountryTimezoneUpdated(timezone));
  }

  updateCountryCurrency(currency: string) {
    this.store.dispatch(new CountryCurrencyUpdated(currency));
  }

  loadTaxType(command: string) {
    this.store.dispatch(new LoadCountryTaxType(command));
  }

  taxTypeLoaded(command: CountryTaxDto) {
    this.store.dispatch(new LoadCountryTaxTypeSuccess(command));
  }

  updateTaxType(command: UpdateCountryTaxTypeCommand) {
    this.store.dispatch(new UpdateCountryTaxType(command));
  }

  updateCountryValuationListInitialSetOfFieldsUpdated(countryId: string, tableName: string) {
    this.store.dispatch(new fromCountriesActions.CountryValuationListInitialSetOfFieldsUpdated({ countryId, tableName }));
  }

  updateCountryUcNumberSource(updateCountryUcNumberSourceCommand: UpdateCountryUcNumberSourceCommand) {
    this.store.dispatch(new UpdateUCNumberSourceType(updateCountryUcNumberSourceCommand));
  }

  loadCountryValuationListInitialSetOfFields(countryId: string, tableName: string) {
    this.store.dispatch(new fromCountriesActions.CountryValuationListInitialSetOfFieldsLoad({ countryId, tableName }));
  }

  loadLogo(businessId: string) {
    this.store.dispatch(new fromCountriesActions.LogoLoad(businessId));
  }

  updateCountryStockListInitialSetOfFieldsUpdated(countryId: string, tableName: string) {
    this.store.dispatch(new fromCountriesActions.CountryStockListInitialSetOfFieldsUpdated({ countryId, tableName }));
  }

  loadCountryStockListInitialSetOfFields(countryId: string, tableName: string) {
    this.store.dispatch(new fromCountriesActions.CountryStockListInitialSetOfFieldsLoad({ countryId, tableName }));
  }

  countryCustomersListInitialSetOfFieldsUpdated(countryId: string, tableName: string) {
    this.store.dispatch(new fromCountriesActions.CountryCustomersListInitialSetOfFieldsUpdated({ countryId, tableName }));
  }

  countryCustomersListInitialSetOfFieldsLoad(countryId: string, tableName: string) {
    this.store.dispatch(new fromCountriesActions.CountryCustomersListInitialSetOfFieldsLoad({ countryId, tableName }));
  }

  updateCountryUrl(command: UpdateCountryUrlCommand) {
    this.store.dispatch(new UpdateCountryUrl(command));
  }

  updateCountryHotlinePhone(command: UpdateCountryHotlinePhoneCommand) {
    this.store.dispatch(new UpdateCountryHotlinePhone(command));
  }

  updateCountryHotlineEmail(command: UpdateCountryHotlineEmailCommand) {
    this.store.dispatch(new UpdateCountryHotlineEmail(command));
  }

  loadBroadcastList(countryId: string) {
    this.store.dispatch(new LoadBroadcastList(countryId));
  }

  loadBroadcastDetails(broadcastId: string, countryId: string) {
    this.store.dispatch(new LoadBroadcastDetails({ broadcastId, countryId }));
  }

  loadBroadcastLogo(broadcastId: string) {
    this.store.dispatch(new LoadBroadcastLogo(broadcastId));
  }

  loadBroadcastCertificate(broadcastId: string) {
    this.store.dispatch(new LoadBroadcastCertificate(broadcastId));
  }

  createFtpExport(ftpConfig: CreateCountryBroadcastConnectionFtpConfigurationCommand, file: any) {
    this.store.dispatch(new CreateFtpExport({ ftpConfig, file }));
  }

  editFtpExport(ftpConfig: UpdateCountryBroadcastConnectionFtpConfigurationCommand, file: any) {
    this.store.dispatch(new EditFtpExport({ ftpConfig, file }));
  }

  loadVinThreshold(countryId: string) {
    this.store.dispatch(new LoadVinThreshold(countryId));
  }

  updateVinThreshold(command: UpdateCountryVinThresholdCommand) {
    this.store.dispatch(new UpdateVinThreshold(command));
  }

  loadExperianCountrySettings(countryId: string) {
    this.store.dispatch(new LoadExperianCountrySettings(countryId));
  }

  loadCountryGdvConfiguration(countryId: string) {
    this.store.dispatch(new LoadCountryGdv(countryId));
  }

  countryGdvUsernameSuccess(username: string) {
    this.store.dispatch(new UpdateCountryGdvUsernameSuccess(username));
  }

  countryGdvPasswordSuccess(password: string) {
    this.store.dispatch(new UpdateCountryGdvPasswordSuccess(password));
  }
}
