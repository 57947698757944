import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { TextFieldComponent } from '../text-field/text-field.component';
import { BehaviorSubject } from 'rxjs';
import { take, filter, concatMapTo, withLatestFrom } from 'rxjs/operators';
import { getInputClass } from './utils/get-input-class';
import { CURRENCY_DISPLAY_FORMAT } from '../active-currency/active-currency.component';
import { LocaleFacade } from '@vpfa/locale';
import { isNil } from 'lodash';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'vpfa-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NumberFieldComponent extends TextFieldComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() set currencyHintMode(mode: CURRENCY_DISPLAY_FORMAT) {
    if (isNil(this._currencyHintMode)) {
      this.localeFacade.locale$
        .pipe(
          filter(x => !isNil(x)),
          take(1),
          withLatestFrom(this.localeFacade.isCurrencyPrefix$)
        )
        .subscribe(([, isCurrencyPrefix]) => {
          this.currencyPrefix = isCurrencyPrefix;
          this.changeDetectorRef.markForCheck();
        });
    }
    this._currencyHintMode = mode;
  }
  @Input() maxFractionNumber = 0;
  @Input() showFractionZeros = true;
  @Input() disableMaxWidth = false;
  @Input() hideErrorsOnBlur = true;
  @Input() showAsNegative = false;
  @Input() allowNegativeNumber = true;
  @Input() convertNegativeValue = true;
  @Input() left = false;
  @Input() right = false;
  @Input() size: NzSizeLDSType = 'small';
  @Input() allowClear = false;

  /**
   * Equivalent to *charCountLimit* inherited from TextFieldComponent
   */
  @Input() digitLimit = 15;
  @Input() isCurrency = true;

  @Output() escapeKeyAction = new EventEmitter();
  @Output() currentValue = new EventEmitter();

  getInputClass = getInputClass;

  activeCurrencyDisplayFormat$ = new BehaviorSubject<CURRENCY_DISPLAY_FORMAT>(
    this.isCurrency ? CURRENCY_DISPLAY_FORMAT.CODE : null
  );

  get currencyHintMode(): CURRENCY_DISPLAY_FORMAT {
    return this._currencyHintMode;
  }

  currencyPrefix = true;
  private _currencyHintMode: CURRENCY_DISPLAY_FORMAT;

  constructor(changeDetectorRef: ChangeDetectorRef, private localeFacade: LocaleFacade) {
    super(changeDetectorRef);
  }

  ngOnInit(): void {

    this.getFormControl().valueChanges.subscribe( value => {
      this.currentValue.emit(value);
    });

  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.isCurrency) {
      this.activeCurrencyDisplayFormat$.pipe(take(1)).subscribe(value => this.changeDetectorRef.detectChanges());
    }
  }

  getErrors() {
    return this.parentFormGroup.get(this.fcName).errors;
  }

  ngOnDestroy(): void {
    this.activeCurrencyDisplayFormat$.complete();
  }

  getDigitLimit() {
    return this.digitLimit;
  }

  shouldDisplayErrors() {
    return this.shouldDisplayErrors$.getValue() && this.getFormControl().dirty;
  }

  onBlur() {
    this.hasFocus = false;
    if (this.hideErrorsOnBlur) {
      this.shouldDisplayErrors$.next(false);
    }
    this.fieldBlur.emit();
  }

  onClear(){
    this.getFormControl()?.setValue(null);
  }

}
