import { BranchDto, BranchListItemDto, TwinnerCredentialsDto } from '@vpfa/rest-api/admin';
import { produce, Draft } from 'immer';
import { BranchesAction, BranchesActionTypes, LoadBranchesForDealerNetwork } from './branches.actions';
import { BranchConfigurationDto, OfferEmailTemplateDto, QuoteEmailTemplateDto } from '@vpfa/rest-api/valuation';
import { GdvInsuranceClaimingBranchConfigurationDto } from '@vpfa/rest-api/vehicle-insurance-claiming';

export const BRANCHES_FEATURE_KEY = 'branches';

export interface BranchConfigurationState {
  configuration: BranchConfigurationDto;
  updatingStockDays: boolean;
  updatingTolerances: boolean;
  updatingUrl: boolean;
  isImportingCustomers: boolean;
  loading: boolean;
}

export interface BranchDetailsState {
  branch: BranchDto;
  loading: boolean;
}

export interface BranchListState {
  items: BranchListItemDto[];
  loading: boolean;
}

export interface GdvConfigurationState {
  loading: boolean;
  processing: boolean;
  config: GdvInsuranceClaimingBranchConfigurationDto;
}

export interface ResellersEmailsTemplateState {
  details: { subject: string; body: string };
  processing: boolean;
  loading: boolean;
}

export interface QuotesEmailTemplateState {
  details: QuoteEmailTemplateDto;
  processing: boolean;
  loading: boolean;
}

export interface OfferEmailTemplateState {
  details: OfferEmailTemplateDto;
  processing: boolean;
  loading: boolean;
}

export interface TwinnerCredentialsState {
  credentials: TwinnerCredentialsDto;
  loading: boolean;
}

export interface BranchesState {
  list: BranchListState;
  branchesForDealerNetwork: BranchListState;
  details: BranchDetailsState;
  configuration: BranchConfigurationState;
  resellersEmailsTemplate: ResellersEmailsTemplateState;
  quotesEmailTemplate: QuotesEmailTemplateState;
  offerEmailTemplate: OfferEmailTemplateState;
  twinner: TwinnerCredentialsState;
  gdv: GdvConfigurationState;
}

export interface BranchesPartialState {
  readonly [BRANCHES_FEATURE_KEY]: BranchesState;
}

export const initialState: BranchesState = {
  list: {
    items: [],
    loading: false,
  },
  branchesForDealerNetwork: {
    items: [],
    loading: false,
  },
  details: {
    loading: false,
    branch: null,
  },
  configuration: {
    configuration: {
      branchId: null,
      liveRetailConfiguration: null,
      numberOfDaysSlowMovingKPI: null,
    },
    updatingStockDays: false,
    updatingTolerances: false,
    updatingUrl: false,
    isImportingCustomers: false,
    loading: false,
  },
  resellersEmailsTemplate: {
    details: null,
    loading: false,
    processing: false,
  },
  quotesEmailTemplate: {
    details: null,
    loading: false,
    processing: false,
  },
  offerEmailTemplate: {
    details: null,
    loading: false,
    processing: false,
  },
  twinner: {
    credentials: {
      username: null,
      isPasswordSet: false,
    },
    loading: false,
  },
  gdv: {
    loading: false,
    processing: false,
    config: null,
  },
};

export function reducer(state: BranchesState = initialState, action: BranchesAction): BranchesState {
  switch (action.type) {
    case BranchesActionTypes.LoadBranchesByBusiness:
      return produce(state, newState => {
        newState.list.loading = true;
      });
    case BranchesActionTypes.BranchesLoaded:
      return produce(state, newState => {
        newState.list.items = action.payload;
        newState.list.loading = false;
      });
    case BranchesActionTypes.BranchesLoadError:
      return produce(state, newState => {
        newState.list.loading = false;
      });

    case BranchesActionTypes.UpdateBranchContact:
      return produce(state, newState => {
        newState.details.loading = true;
      });
    case BranchesActionTypes.UpdateBranchContactSuccess:
      return produce(state, newState => {
        newState.details.loading = false;
      });
    case BranchesActionTypes.UpdateBranchContactError:
      return produce(state, newState => {
        newState.details.loading = false;
      });

    case BranchesActionTypes.LoadBranchesForDealerNetwork:
      return produce(state, newState => {
        newState.branchesForDealerNetwork.loading = true;
      });
    case BranchesActionTypes.LoadBranchesForDealerNetworkCompleted:
      return produce(state, newState => {
        newState.branchesForDealerNetwork.items = action.payload;
        newState.branchesForDealerNetwork.loading = false;
      });
    case BranchesActionTypes.LoadBranchesForDealerNetworkError:
      return produce(state, newState => {
        newState.branchesForDealerNetwork.loading = false;
      });

    case BranchesActionTypes.LoadBranchDetails:
      return produce(state, newState => {
        newState.details.loading = true;
      });
    case BranchesActionTypes.BranchDetailsLoaded:
      return produce(state, newState => {
        newState.details.branch = action.payload;
        newState.details.loading = false;
      });
    case BranchesActionTypes.BranchDetailsLoadError:
      return produce(state, newState => {
        newState.details.loading = false;
      });
    case BranchesActionTypes.BranchUrlUpdate:
      return produce(state, newState => {
        newState.configuration.updatingUrl = true;
      });
    case BranchesActionTypes.BranchUrlUpdated:
    case BranchesActionTypes.BranchUrlUpdateError:
      return produce(state, newState => {
        newState.configuration.updatingUrl = false;
      });
    case BranchesActionTypes.ImportCustomersFromCsv:
      return produce(state, newState => {
        newState.configuration.isImportingCustomers = true;
      });
    case BranchesActionTypes.ImportCustomersFromCsvSuccess:
    case BranchesActionTypes.ImportCustomersFromCsvError:
      return produce(state, newState => {
        newState.configuration = {
          ...state.configuration,
          isImportingCustomers: false,
        };
      });

    case BranchesActionTypes.LoadResellersEmailTemplate:
      return produce<BranchesState>(state, (newState: Draft<BranchesState>) => {
        newState.resellersEmailsTemplate.loading = true;
      });
    case BranchesActionTypes.ResellersEmailTemplateLoaded:
      return produce<BranchesState>(state, (newState: Draft<BranchesState>) => {
        newState.resellersEmailsTemplate.loading = false;
        newState.resellersEmailsTemplate.details = action.payload;
      });

    case BranchesActionTypes.ResellersEmailTemplateLoadError:
      return produce<BranchesState>(state, (newState: Draft<BranchesState>) => {
        newState.resellersEmailsTemplate.loading = false;
      });

    case BranchesActionTypes.LoadQuotesEmailTemplateByBranch:
    case BranchesActionTypes.LoadQuotesEmailTemplateByUserContext:
      return produce<BranchesState>(state, (newState: Draft<BranchesState>) => {
        newState.quotesEmailTemplate.loading = true;
      });
    case BranchesActionTypes.QuotesEmailTemplateByBranchLoaded:
    case BranchesActionTypes.QuotesEmailTemplateByUserContextLoaded:
      return produce<BranchesState>(state, (newState: Draft<BranchesState>) => {
        newState.quotesEmailTemplate.loading = false;
        newState.quotesEmailTemplate.details = action.payload;
      });

    case BranchesActionTypes.QuotesEmailTemplateByBranchLoadError:
    case BranchesActionTypes.QuotesEmailTemplateByUserContextLoadError:
      return produce<BranchesState>(state, (newState: Draft<BranchesState>) => {
        newState.quotesEmailTemplate.loading = false;
      });

    case BranchesActionTypes.LoadOfferEmailTemplateByBranch:
    case BranchesActionTypes.LoadOfferEmailTemplateByUserContext:
      return produce<BranchesState>(state, (newState: Draft<BranchesState>) => {
        newState.offerEmailTemplate.loading = true;
      });
    case BranchesActionTypes.OfferEmailTemplateByBranchLoaded:
    case BranchesActionTypes.OfferEmailTemplateByUserContextLoaded:
      return produce<BranchesState>(state, (newState: Draft<BranchesState>) => {
        newState.offerEmailTemplate.loading = false;
        newState.offerEmailTemplate.details = action.payload;
      });

    case BranchesActionTypes.OfferEmailTemplateByBranchLoadError:
    case BranchesActionTypes.OfferEmailTemplateByUserContextLoadError:
      return produce<BranchesState>(state, (newState: Draft<BranchesState>) => {
        newState.offerEmailTemplate.loading = false;
      });

    case BranchesActionTypes.LoadBranchConfiguration:
      return produce<BranchesState>(state, newState => {
        newState.configuration.loading = true;
      });

    case BranchesActionTypes.LoadBranchConfigurationSuccess:
      return produce<BranchesState>(state, newState => {
        newState.configuration = {
          configuration: action.payload,
          updatingUrl: false,
          updatingTolerances: false,
          loading: false,
          updatingStockDays: false,
          isImportingCustomers: false,
        };
      });
    case BranchesActionTypes.ClearBranchConfiguration:
      return produce<BranchesState>(state, newState => {
        newState.configuration = {
          configuration: {
            branchId: null,
            liveRetailConfiguration: null,
            numberOfDaysSlowMovingKPI: null,
          },
          updatingUrl: false,
          updatingTolerances: false,
          loading: false,
          updatingStockDays: false,
          isImportingCustomers: false,
        };
      });

    case BranchesActionTypes.LoadBranchConfigurationError:
      return produce<BranchesState>(state, newState => {
        newState.configuration.loading = false;
      });

    case BranchesActionTypes.UpdateBranchTolerances:
      return produce<BranchesState>(state, newState => {
        newState.configuration.updatingTolerances = true;
      });

    case BranchesActionTypes.UpdateBranchTolerancesError:
      return produce<BranchesState>(state, newState => {
        newState.configuration.updatingTolerances = false;
      });
    case BranchesActionTypes.UpdateBranchStockDaysNumber:
      return produce<BranchesState>(state, newState => {
        newState.configuration.updatingStockDays = true;
      });

    case BranchesActionTypes.UpdateBranchStockDaysNumberSuccess:
    case BranchesActionTypes.UpdateBranchStockDaysNumberError:
      return produce<BranchesState>(state, newState => {
        newState.configuration.updatingStockDays = false;
      });

    case BranchesActionTypes.LoadTwinnerCredentials: {
      return produce<BranchesState>(state, newState => {
        newState.twinner.loading = true;
      });
    }

    case BranchesActionTypes.LoadTwinnerCredentialsSuccess:
      return produce<BranchesState>(state, newState => {
        newState.twinner.credentials = action.payload;
        newState.twinner.loading = false;
      });

    case BranchesActionTypes.LoadTwinnerCredentialsError:
      return produce<BranchesState>(state, newState => {
        newState.twinner.loading = false;
      });

    case BranchesActionTypes.ClearTwinnerCredentials:
      return produce<BranchesState>(state, newState => {
        newState.twinner.credentials = {
          isPasswordSet: false,
          username: null,
        };
        newState.twinner.loading = false;
      });

    case BranchesActionTypes.LoadBranchGdvConfiguration:
      return produce<BranchesState>(state, newState => {
        newState.gdv.loading = true;
      });

    case BranchesActionTypes.LoadBranchGdvConfigurationSuccess:
      return produce<BranchesState>(state, newState => {
        newState.gdv.config = action.payload;
        newState.gdv.loading = false;
      });

    case BranchesActionTypes.LoadBranchGdvConfigurationError:
      return produce<BranchesState>(state, newState => {
        newState.gdv.loading = false;
      });

    case BranchesActionTypes.UpdateBranchGdvConfiguration:
      return produce<BranchesState>(state, newState => {
        newState.gdv.processing = true;
      });

    case BranchesActionTypes.UpdateBranchGdvConfigurationSuccess:
    case BranchesActionTypes.UpdateBranchGdvConfigurationError:
      return produce<BranchesState>(state, newState => {
        newState.gdv.processing = false;
      });
  }
  return state;
}
