import { Subject, Observable, first, tap, concat, catchError, of, onErrorResumeNextWith } from 'rxjs';

export type SequentialActions = Array<{ observable: Observable<any>; afterSuccess?: () => void }>;

const errorResult = 'error';

export type ActionsResult = 'succeeded' | 'someFailed' | 'allFailed';

/**
 * Warning: `observable` must emit and complete because this function will not close the subscription
 * (RestApi lib is using Angular's HttpClient, which is self-completing, so it is fine to use it here)
 *
 * @param isLoading$ subject that will be updated when requests are executed and completed (both success or error)
 * @param observablesWithEffects
 */
export function executeRequestsSequentially(
  isLoading$: Subject<boolean>,
  observablesWithEffects: SequentialActions,
  finishedCallback?: (numberOfFailedRequests: number) => void,
): Observable<ActionsResult> {
  if (!observablesWithEffects?.length) {
    // nothing to do, return success to close unsaved changes modal
    return of('succeeded');
  }

  const resultSubject = new Subject<ActionsResult>();
  let currentRequestsNumber = 0;
  let numberOfFailedRequests = 0;

  const observables = observablesWithEffects
    .filter(x => !!x)
    .map(item =>
      item.observable.pipe(
        // return error string in case of error, otherwise `concat` will brake the flow and some requests may not be executed
        onErrorResumeNextWith(of(errorResult)),
        tap((res: unknown) => {
          if (res !== errorResult) {
            item.afterSuccess?.();
          }
        }),
        first(),
      ),
    );

  const totalNumberOfRequests = observables.length;

  function handleResponse(wasSuccessful: boolean) {
    currentRequestsNumber++;

    if (!wasSuccessful) {
      numberOfFailedRequests++;
    }

    if (currentRequestsNumber == totalNumberOfRequests) {
      finishedCallback?.(numberOfFailedRequests);

      if (numberOfFailedRequests === 0) {
        resultSubject.next('succeeded');
      } else if (numberOfFailedRequests < totalNumberOfRequests) {
        resultSubject.next('someFailed');
      } else {
        resultSubject.next('allFailed');
      }
      resultSubject.complete();
    } else if (currentRequestsNumber > totalNumberOfRequests) {
      console.error('Current requests number exceeded total number of requests');
    }
  }

  isLoading$.next(true);

  concat(...observables).subscribe({
    next: x => {
      handleResponse(x !== errorResult);
    },
    error: error => {
      console.error('Unexpected Error in executeRequestsSequentially', error);
      handleResponse(false);
    },
    complete: () => {
      isLoading$.next(false);
    },
  });

  return resultSubject;
}
