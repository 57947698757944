import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-image-renderer',
  templateUrl: './image-renderer.component.html',
  styleUrls: ['./image-renderer.component.scss'],
})
export class ImageRendererComponent implements ICellRendererAngularComp, OnDestroy {
  currentImageUrl: string = null;
  originalImageUrl: string = null;
  loading = true;
  align = 'center';
  maxWidth = null;

  constructor(private httpClient: HttpClient) {}

  agInit(params: ICellRendererParams): void {
    if (params.colDef.cellRendererParams) {
      const cellRendererParams = params.colDef.cellRendererParams;
      if (!isNil(cellRendererParams.align)) {
        this.align = params.colDef.cellRendererParams.align;
        this.maxWidth = params.colDef.cellRendererParams.maxWidth;
      }
    }
    this.originalImageUrl = params.valueFormatted;
    this.getCurrentImage();
  }

  refresh(params: any): boolean {
    this.originalImageUrl = params.valueFormatted;
    this.getCurrentImage();
    return true;
  }

  ngOnDestroy() {
    if (this.currentImageUrl !== null) {
      URL.revokeObjectURL(this.currentImageUrl);
    }
  }

  private getCurrentImage() {
    if (isNil(this.originalImageUrl)) {
      this.loading = false;
      return;
    }

    this.loading = true;

    this.httpClient
      .get(this.originalImageUrl, {
        responseType: 'blob',
      })
      .subscribe(
        image => {
          this.loading = false;
          this.currentImageUrl = URL.createObjectURL(image);
        },
        () => {
          this.loading = false;
        },
      );
  }
}
