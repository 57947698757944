import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vpfa-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFieldComponent implements AfterViewInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  @ViewChild('input') inputElementRef: ElementRef;
  @ViewChild('inputUppercase') inputUppercaseElementRef: ElementRef;

  @Input() inputType: 'text' | 'password' = 'text';
  @Input() readonly = false;
  @Input() uppercase = false;

  /**
   * # optional #
   * [***Layout***] Disables the effect of *fieldWidth* input.
   */
  @Input() disableMaxWidth = false;
  @Input() fieldWidth: string;

  /**
   *  # optional #
   * Limit number of characters in the input
   */
  @Input() charCountLimit: number;
  @Input() placeholder = '';

  /**
   * # required #
   * Field control name
   */
  @Input() fcName: string;
  /**
   * # required #
   * Parent form group instance
   */
  @Input() parentFormGroup: UntypedFormGroup;

  @Input() formControl: UntypedFormControl;

  /**
   * Here we can pass inline styles to be passed to input native control
   */
  @Input() style: Partial<CSSStyleDeclaration>;

  @Input() setFocusAfterInit = false;

  /**
   *  Do not submit value via enter key ( submits value on blur )
   */
  @Input() preventDefaultForEnterKey = false;
  @Input() errorMsgVertPosition: 'inside' | 'outside' | 'outsideLeft' | 'leftAlign' | 'suffixPrefix';

  /**
   * # optional #
   * If should display errors in popover
   */
  @Input() errorsInPopover = true;

  /**
   * #optional# => [  if not provided errors will not be displayed ]
   * List of all validation error messages we want to display in the popover
   */
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }> = [];

  /**
   * We dont display errors when field is not in focus state
   */
  shouldDisplayErrors$ = new BehaviorSubject(Boolean(this.errorMessages?.length));
  @Input() set displayErrors(setValue: boolean) {
    this.shouldDisplayErrors$.next(setValue);
  }

  /**
   * This parameter should only be needed for non-standard height of the input ( like on search view )
   */
  @Input() heightCompensationPx: number | string;

  @Input() autocomplete: 'on' | 'new-password' | 'name' | 'email' | 'phone' | 'postal-code' = 'on';

  @Output() submitValue = new EventEmitter();
  @Output() fieldBlur = new EventEmitter();
  @Output() inputFocus = new EventEmitter();
  @Output() currentValue = new EventEmitter();

  hasFocus = false;

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {

    this.getFormControl().valueChanges.subscribe( value => {
      this.currentValue.emit(value);
    });

  }


  ngAfterViewInit(): void {
    if (this.setFocusAfterInit) {
      this.focus();
    }
    this.shouldDisplayErrors$.pipe(distinctUntilChanged(), takeUntil(this._onDestroy$)).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  onSubmit(event: any) {
    if (this.preventDefaultForEnterKey) {
      event.preventDefault();
    }
    this.submitValue.emit();
  }

  onFocus() {
    this.hasFocus = true;
    this.inputFocus.emit();
    this.shouldDisplayErrors$.next(Boolean(this.errorMessages?.length));
  }

  onBlur() {
    this.hasFocus = false;
    this.shouldDisplayErrors$.next(false);
    this.fieldBlur.emit();
  }

  getFormControl(): UntypedFormControl {
    return this.formControl ? this.formControl : (this.parentFormGroup.get(this.fcName) as UntypedFormControl);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  public focus() {
    if (this.inputElementRef?.nativeElement) {
      this.inputElementRef.nativeElement.focus();
    } else {
      this.inputUppercaseElementRef?.nativeElement.focus();
    }
  }
  
}
