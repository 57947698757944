import { Component, EventEmitter, ViewChild, TemplateRef, Input } from '@angular/core';
import { ModalContentComponent } from '../modal-content/modal-content.component';

@Component({
  selector: 'vpfa-confirm-changes-modal-content',
  templateUrl: './confirm-changes-modal-content.component.html',
  styleUrls: ['./confirm-changes-modal-content.component.scss'],
})
export class ConfirmChangesModalContentComponent {
  @ViewChild('ctr') modalContentTemplate: TemplateRef<ModalContentComponent>;

  isLoading = false;

  result = new EventEmitter<'saveChangesAndExit' | 'dismissChangesAndExit' | 'cancel'>();

  save() {
    this.result.emit('saveChangesAndExit');
  }

  dismiss() {
    this.result.emit('dismissChangesAndExit');
  }

  cancel() {
    this.result.emit('cancel');
  }
}
