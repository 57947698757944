import { ComponentRef, Injectable } from '@angular/core';
import { NotificationType } from '../models/notification-type.enum';
import { NotificationToastComponent } from '../components/notification-toast/notification-toast.component';

interface ToastData {
  title: string;
  content?: string;
  interpolateParams?: Object;
}

@Injectable()
export class ToastsNotificationService {
  toastsQueue: ToastData[] = [];
  visibleToasts: ComponentRef<NotificationToastComponent>[] = [];

  createToastHandler: (
    title: string,
    content: string,
    type: NotificationType,
    interpolateParams?: Object,
  ) => ComponentRef<NotificationToastComponent>;

  success(title: string, content?: string, interpolateParams?: Object): void {
    if (!content) {
      // if we do not have content, display title as a content for better look and feel
      this.toastsQueue.push({
        title: undefined,
        content: title,
        interpolateParams,
      });
    } else {
      this.toastsQueue.push({
        title,
        content,
        interpolateParams,
      });
    }

    this.processToastsQueue();
  }

  private showNextToast() {
    this.visibleToasts.shift();

    let nextToast = this.visibleToasts[0];

    if (nextToast) {
      nextToast.instance.isStickingOut = false;
      nextToast.instance.showIds();
      nextToast.changeDetectorRef.detectChanges();
    }

    const newToast = this.toastsQueue.shift();

    if (!newToast) {
      return;
    }

    this.createToast(newToast);
  }

  private createToast(params: ToastData) {
    const toast = this.createToastHandler(
      params.title,
      params.content,
      NotificationType.Success,
      params.interpolateParams,
    );

    toast.instance.close = () => {
      this.showNextToast();
      toast.destroy();
    };

    toast.instance.isStickingOut = this.visibleToasts.length > 0;
    if (!toast.instance.isStickingOut) {
      toast.instance.showIds();
    }
    this.visibleToasts.push(toast);
  }

  private processToastsQueue() {
    const visibleToastsNumber = this.visibleToasts.length;

    if (visibleToastsNumber < 2) {
      const toastData = this.toastsQueue.shift();

      if (toastData) {
        this.createToast(toastData);
      }
    }
  }
}
