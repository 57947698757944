import { EnvironmentConfigDto, UserContext } from '@vpfa/rest-api/admin';
import { AssetsTranslateFileDir, CdnTranslateFileDir } from '../constants/translate-file-dir';
import { deploymentVersionParamName, DummyFilename, EnInternationalFilename } from '../constants/translate-file-name';
import { registerLocaleData } from '@angular/common';
import { de_DE, en_GB, fr_FR, it_IT, NzI18nInterface, NzI18nService } from 'ng-zorro-antd/i18n';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import { LanguageEnum } from '../enums/language.enum';
import { isNil } from 'lodash';
import { enGB as dateFnsEn, de as dateFnsDe, fr as dateFnsFr, it as dateFnsIt } from 'date-fns/locale';

export const langCountrySeparator = '_';

export function getLanguageFromUiLanguageCode(uiLanguageCode: string) {
  const internal = [LanguageEnum.DUMMY.toString().toLowerCase(), LanguageEnum.ENInternational.toString().toLowerCase()];

  if (uiLanguageCode.includes(uiLanguageCode.toLowerCase())) {
    return 'en';
  }

  // uiLanguageCode has following format: `${LANG}${langCountrySeparator}${COUNTRY}` as in `getTranslateFileName()`
  return uiLanguageCode?.split(langCountrySeparator)?.[0]?.toLowerCase();
}

export function getTranslateFileName(language: LanguageEnum, countryCode: string): string {
  switch (language) {
    case LanguageEnum.DUMMY:
      return 'dummy';
    case LanguageEnum.ENInternational:
      return EnInternationalFilename;
    default:
      return `${language?.toLowerCase()}${langCountrySeparator}${countryCode?.toUpperCase()}`;
  }
}

export function getTranslateFilePath(
  language: LanguageEnum,
  countryCode: string,
  envConfig: EnvironmentConfigDto,
  deploymentVersion: number,
): string {
  const fileName = getTranslateFileName(language, countryCode);
  let prefix = AssetsTranslateFileDir;

  if (fileName !== EnInternationalFilename && fileName !== DummyFilename) {
    prefix = envConfig.cdnUrl + CdnTranslateFileDir;
  }

  return `${prefix}${fileName}.json${deploymentVersionParamName}${deploymentVersion}`;
}

export const defaultUiConfig = {
  uiLang: LanguageEnum.ENInternational,
  countryCode: 'GB',
};

/**
 * Function to convert internal language and country code to ISO 639-1.
 * @returns lang ISO Code
 */
export function convertLangToIso(userContext: UserContext) {
  const userLang = userContext.uiLanguage.toLowerCase();
  const userCountry = userContext.countryCode.toUpperCase();

  const fakeIsoCode = `${userLang}-${userCountry}`;

  // INFO: if you adding new language support, remember also about `setNgZorroLocale`
  switch (fakeIsoCode) {
    case 'en-GB':
    case 'fr-CH':
    case 'it-CH':
      return fakeIsoCode;
    default:
      return userLang;
  }
}

/**
 * Removes internally used languages codes like Dummy or EnInternational
 */
export function sanitizeLanguageCode(languageCode: string): string {
  const defaultLang = 'en';

  if (
    isNil(languageCode) ||
    languageCode.toUpperCase() === LanguageEnum.DUMMY.toString().toUpperCase() ||
    languageCode.toUpperCase() === LanguageEnum.ENInternational.toString().toUpperCase()
  ) {
    return defaultLang;
  }

  return languageCode;
}

export function setNgZorroLocale(uiLang: LanguageEnum, i18n: NzI18nService) {
  // INFO: if you adding new language support, remember also about `convertLangToIso`
  switch (uiLang) {
    case LanguageEnum.DE:
      registerLocaleData(localeDE);
      i18n.setLocale(de_DE as NzI18nInterface);
      i18n.setDateLocale(dateFnsDe);
      break;
    case LanguageEnum.FR:
      registerLocaleData(localeFR);
      i18n.setLocale(fr_FR as NzI18nInterface);
      i18n.setDateLocale(dateFnsFr);
      break;
    case LanguageEnum.IT:
      registerLocaleData(localeIT);
      i18n.setLocale(it_IT as NzI18nInterface);
      i18n.setDateLocale(dateFnsIt);
      break;
    default:
      registerLocaleData(localeEN);
      i18n.setLocale(en_GB as NzI18nInterface);
      i18n.setDateLocale(dateFnsEn);
      break;
  }
}
