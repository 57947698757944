<vpfa-section-container [name]="'provenanceCheck.title' | translate" [toggleLabel]="statusTmp" [isLoading]="isLoading">
  <div class="check-info">
    <p *ngIf="!provenanceCheckData; else lastCheckDate">{{ 'provenanceCheck.notRequestedInfo' | translate }}</p>
  </div>
  <div [fxLayout]="'row'" fxLayoutAlign="space-between center" class="terms" *ngIf="canCheck">
    <div [fxLayout]="'row'">
      <vpfa-switch [fc]="termsFormGroup.get('terms')" [parentFormGroup]="termsFormGroup"></vpfa-switch>
      <p>
        {{ 'provenanceCheck.termsLabel.info' | translate }}
        <a [href]="termsUrl ? termsUrl : ''" target="_blank">{{ 'provenanceCheck.termsLabel.link' | translate }}</a>
      </p>
    </div>

    <vpfa-action-button (click)="onCheckClick()" [disabled]="checkButtonDisable">
      {{ buttonLabelKey | translate }}</vpfa-action-button
    >
  </div>
  <ng-container *ngIf="provenanceCheckData">
    <hr class="section-separator" />
    <h3 class="section-header">{{ 'provenanceCheck.vehicleConfirmationStatus.title' | translate }}</h3>
    <ng-container
      [ngTemplateOutlet]="sectionBase"
      [ngTemplateOutletContext]="{ $implicit: provenanceCheckData.vehicleConfirmation }"
    ></ng-container>
    <hr class="section-separator" />
    <h3 class="section-header">{{ 'provenanceCheck.keepers.title' | translate }}</h3>
    <ng-container *ngTemplateOutlet="previousKeeperChange"></ng-container>
    <hr class="section-separator" />
    <h3 class="section-header">{{ 'provenanceCheck.mileage.title' | translate }}</h3>
    <ng-container *ngTemplateOutlet="sectionMileageDetails"></ng-container>
    <hr class="section-separator" />
    <h3 class="section-header">{{ 'provenanceCheck.environmentalReportDetails.title' | translate }}</h3>
    <ng-container *ngTemplateOutlet="environmentalReport"></ng-container>
  </ng-container>

  <ng-container right-section *ngTemplateOutlet="statusTmp"></ng-container>
  
</vpfa-section-container>

<ng-template #statusTmp>
  <p class="status" [ngClass]="statusClass">{{ statusMessageKey | translate }}</p>
</ng-template>

<ng-template #sectionBase let-vehicleConfirmation>
  <vpfa-web-provenance-check-section-confirmation-status
    [vehicleConfirmation]="vehicleConfirmation"
  ></vpfa-web-provenance-check-section-confirmation-status>
</ng-template>

<ng-template #previousKeeperChange>
  <vpfa-provenance-check-section-keepers 
    [keepersChangeDetails]="provenanceCheckData?.keepersChangeDetails"
  ></vpfa-provenance-check-section-keepers>
</ng-template>

<ng-template #sectionMileageDetails>
  <vpfa-provenance-check-section-mileage 
    [mileageDetails]="provenanceCheckData?.mileageDetails"
  ></vpfa-provenance-check-section-mileage>
</ng-template>

<ng-template #environmentalReport>
  <vpfa-provenance-check-section-environmental 
    [isMobile]="false"
    [co2Emission]="provenanceCheckData?.vehicleConfirmation?.cO2Emissions"
  ></vpfa-provenance-check-section-environmental>
</ng-template>

<ng-template #lastCheckDate>
  <p>
    {{ 'provenanceCheck.checkTime' | translate }}: {{ provenanceCheckData?.dateOfLastCheck | dateLocale: 'short' }}.
  </p>
</ng-template>
