import { AbstractControl } from '@angular/forms';

export type FormStatus = {
  valid: boolean;
  invalid: boolean;
  pristine: boolean;
  dirty: boolean;
  touched: boolean;
};

export function getFormStatus(form: AbstractControl): FormStatus {
  return {
    dirty: form.dirty,
    invalid: form.invalid,
    pristine: form.pristine,
    touched: form.touched,
    valid: form.valid,
  };
}

export const pristineFormStatus = {
  dirty: false,
  invalid: false,
  pristine: true,
  touched: false,
  valid: true,
};
