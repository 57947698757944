import { Component, Input, OnInit } from '@angular/core';
import { LiveRetailInsightsDto } from '@vpfa/rest-api/valuation';
import { isNil, isEmpty } from 'lodash';
import { ProfileFacade } from '@vpfa/profile/data';
import { filter, map, take } from 'rxjs/operators';
import { LocaleFacade } from '@vpfa/locale';
import { Features } from '@vpfa/rest-api/admin';
import { getLiveRetailTaxType } from '@vpfa/utils';

@Component({ template: '' })
export class VehicleRetailMarketStatsComponent implements OnInit {
  @Input() retailMarketDetails: LiveRetailInsightsDto;
  @Input() isInClientMode = false;
  @Input() notAvailable: boolean;
  @Input() isLoading: boolean;
  @Input() isNewVehicle: boolean;
  @Input() vehicleType: number;
  @Input() isLiveRetailEnabled: boolean;
  mileageUnit: string;

  showSellingSpotPrice$ = this.localeFacade.isFeatureConfigurationEnabled(Features.ShowSellingSpotPrice);
  countryCode$ = this.profileFacade.userContext$.pipe(map(x => x.countryCode));

  taxInformation$ = this.countryCode$.pipe(
    map(countryCode => {
      return getLiveRetailTaxType(this.vehicleType, countryCode);
    }),
  );

  get liveRetailNotAvailable(): boolean {
    return this.notAvailable && !this.isLiveRetailEnabled;
  }

  constructor(
    private profileFacade: ProfileFacade,
    private localeFacade: LocaleFacade,
  ) {}

  ngOnInit(): void {
    this.profileFacade.userContext$
      .pipe(
        filter(context => !isNil(context)),
        take(1),
      )
      .subscribe(context => {
        this.mileageUnit = ` (${context.mileageUnit})`;
      });
  }
}
