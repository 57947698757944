import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  AddCaseDocument,
  CaseDocumentUploadSetTotalCount,
  CaseQRCodeLoad,
  CasesActionTypes,
  DeleteCaseDocument,
  DownloadCaseDocument,
  fromCasesActions,
  LoadCaseDocumentList,
} from './cases.actions';
import { CasesPartialState } from './cases.reducer';
import { casesQuery } from './cases.selectors';
import {
  CaseDueToInStockCommand,
  CasePurchaseVehicleCommand,
  CaseReturnToStockCommand,
  CaseSellVehicleCommand,
  CaseSummaryDto,
  CaseUpdateRegistrationDateBody,
  CreateCaseQuoteCommand,
  CreateCaseStockOfferCommand,
  LightDamageAreaDto,
  PriceHistoryDto,
  ResendCaseLatestQuoteCommand,
  ResendCaseLatestStockOfferCommand,
  ReturnReportDto,
  UpdateCaseLightDamagePartsCommand,
  UpdateCaseNotesCommand,
  UpdateCaseOwnersCommand,
  UpdateCasePlateNumberCommand,
  UpdateCasePreviousOwnersCommand,
  UpdateCaseProspectCustomersCommand,
  UpdateCasePublicSiteConfigurationCommand,
  UpdateCaseRegistrationDateCommand,
  UpdateCaseResellerSiteConfigurationCommand,
  UpdateCaseReturnReportCommand,
  UpdateCaseSoldPricesCommand,
  UpdateCaseStockPricesCommand,
  UpdateCaseUniqueDataCommand,
  UpdateCaseValuationDateCommand,
  UpdateCaseVehicleMileageCommand,
  UpdateCaseYoutubeUrlCommand,
  UpdateIdentifiedVehicleFuelSubtypesCommand,
  UpdateIdentifiedVehicleKBACommand,
  UpdateIdentifiedVehicleMatriculationNumberCommand,
  UpdateIdentifiedVehicleTechnicalDataCommand,
  UpdateIdentifiedVehicleVinCommand,
  UpdateTwinnerImagesCommand,
} from '@vpfa/rest-api/valuation';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FileEntity } from '../models/file-entity';
import { Actions } from '@ngrx/effects';

export interface CaseHistoricalPrices {
  askingPriceHistory: PriceHistoryDto[];
  effectiveTradeInPriceHistory: PriceHistoryDto[];
  soldPriceHistory: PriceHistoryDto[];
}

@Injectable()
export class CasesFacade {
  loaded$ = this.store.pipe(select(casesQuery.getLoaded));
  loading$ = this.store.pipe(select(casesQuery.getLoading));
  isValuating$ = this.store.pipe(select(casesQuery.getIsValuating));
  valuationAttemptFailed$ = this.store.select(casesQuery.getValuationAttemptFailed);
  isValuationNotAvailable$ = this.store.select(casesQuery.getIsValuationNotAvailable);

  allCases$ = this.store.pipe(select(casesQuery.getAllCases));
  caseDocumentList$ = this.store.pipe(select(casesQuery.getAllDocuments));
  documentsTotalSize$ = this.store.pipe(select(casesQuery.getDocumentsTotalSize));
  isCaseDocumentListLoading$ = this.store.pipe(select(casesQuery.getDocumentsLoading));
  isCaseDocumentListLoaded$ = this.store.pipe(select(casesQuery.getDocumentsLoading));
  isProcessingForm$ = this.store.pipe(select(casesQuery.getIsProcessingForm));
  hasFormProcessingError$ = this.store.pipe(select(casesQuery.getHasFormProcessingError));
  allStockItems$ = this.store.pipe(select(casesQuery.getAllStockItems));
  allValuationItems$ = this.store.pipe(select(casesQuery.getAllValuationItems));
  selectedCases$ = this.store.pipe(select(casesQuery.getSelectedCases));
  activeCaseData$ = this.store.pipe(select(casesQuery.getActiveCaseData));
  isCaseValuationForecasted$ = this.store.pipe(select(casesQuery.getIsCaseValuationForecasted));
  activeCaseNotesData$ = this.store.pipe(select(casesQuery.getActiveCaseNotesData));
  dateOfAcceptance$ = this.store.pipe(select(casesQuery.getDateOfAcceptance));
  youtubeUrl$ = this.store.pipe(select(casesQuery.getYoutubeUrl));
  activeCaseNotes$ = this.store.pipe(select(casesQuery.getActiveCaseNotes));
  isUpdatingValuationUniqueData$ = this.store.pipe(select(casesQuery.getIsUpdatingValuationUniqueData));
  isUniqueDataModalOpen$ = this.store.pipe(select(casesQuery.getIsUniqueDataModalOpen));
  isUpdatingTechnicalData$ = this.store.pipe(select(casesQuery.getIsUpdatingTechnicalData));
  isTechnicalDataModalOpen$ = this.store.pipe(select(casesQuery.getIsTechnicalDataModalOpen));
  technicalData$ = this.store.pipe(select(casesQuery.getTechnicalData));
  hasError$ = this.store.pipe(select(casesQuery.getError), filter(Boolean), distinctUntilChanged());
  hasCaseDocumentFormProcessingError$ = this.store.pipe(select(casesQuery.getHasCaseDocumentFormProcessingError));
  isCaseDocumentProcessingForm$ = this.store.pipe(select(casesQuery.getIsCaseDocumentProcessingForm));
  isCaseDocumentUploadNotProcessingAndNotCompleted$ = this.store.pipe(
    select(casesQuery.getIsCaseDocumentUploadNotProcessingAndNotCompleted),
  );

  activeCaseId$ = this.store.select(casesQuery.getActiveCaseId);
  activeCaseQrCode$ = this.store.select(casesQuery.getCaseQrCode);

  isPurchasingVehicle$ = this.store.select(casesQuery.getIsPurchaseProcessing);
  purchaseModalOpen$ = this.store.select(casesQuery.getPurchaseModalOpen);

  isReservingVehicle$ = this.store.select(casesQuery.getIsReservingVehicle);
  isCancellingReservation$ = this.store.select(casesQuery.getIsCancellingReservation);

  isProcessingSellVehicle$ = this.store.select(casesQuery.getIsProcessingSellVehicle);
  isSellModalOpen$ = this.store.select(casesQuery.getIsSellModalOpen);

  isUpdatingVin$ = this.store.select(casesQuery.getIsUpdatingVin);
  isUpdatingVinError$ = this.store.select(casesQuery.getIsUpdatingVinError);

  isUpdatingMatriculationNumber$ = this.store.select(casesQuery.getIsUpdatingMatriculationNumber);
  isUpdatingMatriculationNumberError$ = this.store.select(casesQuery.getIsUpdatingMatriculationNumberError);

  isUpdatingKba$ = this.store.select(casesQuery.getIsUpdatingKba);
  isUpdatingPlateNo$ = this.store.select(casesQuery.getIsUpdatingPlateNo);
  isUpdatingPlateNoError$ = this.store.select(casesQuery.getIsUpdatingPlateNoError);
  isUpdatingMileage$ = this.store.select(casesQuery.getIsUpdatingMileage);
  isUpdatingRegDate$ = this.store.select(casesQuery.getIsUpdatingRegDate);
  isUpdatingValuationDate$ = this.store.select(casesQuery.getIsUpdatingValuationDate);
  isUpdateValuationDateModalOpen$ = this.store.select(casesQuery.getIsUpdateValuationDataModalOpen);

  lightDamageEstimatorAreas$ = this.store.select(casesQuery.getLightDamageEstimatorAreas);
  isLightDamageEstimatorLoading$ = this.store.select(casesQuery.getLightDamageEstimatorLoading);
  isLightDamageEstimatorSaving$ = this.store.select(casesQuery.getLightDamageEstimatorSaving);
  isLightDamageEstimatorSaved$ = this.store.select(casesQuery.getLightDamageEstimatorSaved);
  lightDamageEstimatorTotal$ = this.store.select(casesQuery.getLightDamageEstimatorTotal);
  repairEstimatorTotal$ = this.store.select(casesQuery.getRepairEstimatorTotal);
  isReserveModalOpen$ = this.store.select(casesQuery.isReserveModalOpen);
  isReservationViewModalOpen$ = this.store.select(casesQuery.isReservationViewModalOpen);
  reservation$ = this.store.select(casesQuery.getReservation);
  activeCaseVehicleEquipment$ = this.store.select(casesQuery.getActiveCaseVehicleEquipment);
  activeCaseStatus$ = this.store.select(casesQuery.getActiveCaseStatus);

  retailMarketDetails$ = this.store.select(casesQuery.getRetailMarketDetails);
  isLiveRetailAvailable$ = this.store.select(casesQuery.isLiveRetailAvailable);
  retailMarketDetailsLiveRetailPrice$ = this.store.select(casesQuery.getRetailMarketDetailsLiveRetailPrice);
  retailMarketLoading$ = this.store.select(casesQuery.getRetailMarketLoading);
  valuationVersionAtPurchase$ = this.store.select(casesQuery.getValuationVersionAtPurchase);
  activeCaseDataPurchasedTime$ = this.store.select(casesQuery.getActiveCaseDataPurchasedTime);
  activeCaseDataTaxes$ = this.store.select(casesQuery.getActiveCaseDataTaxes);
  activeCaseDataTaxedPrice$ = this.store.select(casesQuery.getActiveCaseDataTaxedPrice);
  isReturnToStockModalOpen$ = this.store.select(casesQuery.isReturnToStockModalOpen);
  isReturningVehicleToStock$ = this.store.select(casesQuery.isReturningVehicleToStock);

  caseDocumentsTotalSize$ = this.store.select(casesQuery.getCaseDocumentsTotalSize);
  activeCaseManufacturerColorList$ = this.store.select(casesQuery.getActiveCaseManufacturerColorList);

  isNewVehicle$ = this.store.select(casesQuery.getIsNewVehicle);
  isOldVehicle$ = this.store.select(casesQuery.getIsOldVehicle);
  isOutOfValuationRange$ = this.store.select(casesQuery.getIsOutOfValuationRange);

  publicSiteOption$ = this.store.select(casesQuery.getPublicSiteOption);
  isPublicSiteUpdating$ = this.store.select(casesQuery.isUpdatingPublicSite);

  isResellerSiteConfigurationModalOpen$ = this.store.select(casesQuery.getIsResellerSiteConfigurationModalOpen);
  isPublishToResellerProcessing$ = this.store.select(casesQuery.getIsPublishToResellerProcessing);
  isPublishToResellerProcessingCompleted$ = this.store.select(casesQuery.getIsPublishToResellerProcessingCompleted);

  activeCaseCO2EfficiencyClass$ = this.store.select(casesQuery.getActiveCaseCO2EmissionEfficiencyClass);
  activeCaseCO2EfficiencyClassImage$ = this.store.select(casesQuery.getActiveCaseCO2EmissionEfficiencyClassImage);
  isLoadingActiveCaseCO2EfficiencyClassImage$ = this.store.select(casesQuery.getIsLoadingCO2EmissionStickers);
  isUpdatingPricingDetails$ = this.store.select(casesQuery.getIsUpdatingPricingDetails);
  isPricingDetailsModalOpen$ = this.store.select(casesQuery.getIsPricingDetailsModalOpen);

  isResendingCaseLatestQuote$ = this.store.select(casesQuery.getIsResendingCaseLatestQuote);

  isResendingCaseLatestOffer$ = this.store.select(casesQuery.getIsResendingCaseLatestOffer);

  documentsUploadCurrentIndex$ = this.store.select(casesQuery.getDocumentsUploadCurrentIndex);
  documentsUploadTotalCount$ = this.store.select(casesQuery.getDocumentsUploadTotalCount);
  documentsUploadStepsCompleted$ = this.store.select(casesQuery.getDocumentsUploadStepsCompleted);
  documentUploadIsCompleted$ = this.store.select(casesQuery.getDocumentsUploadIsCompleted);
  documentsUploadProcessing$ = this.store.select(casesQuery.getDocumentsUploadProcessing);
  manufacturerColor$ = this.store.select(casesQuery.getUniqueDataManufacturerColor);
  manufacturerOriginalColor$ = this.store.select(casesQuery.getUniqueDataManufacturerOriginalColor);
  valuationUniqueData$ = this.store.select(casesQuery.getValuationUniqueData);
  stockUniqueData$ = this.store.select(casesQuery.getStockUniqueData);
  soldUniqueData$ = this.store.select(casesQuery.getSoldUniqueData);

  getIsLoadingFuelSubtypes$ = this.store.select(casesQuery.getIsLoadingFuelSubtypes);
  isProcessingFuelSubtypes$ = this.store.select(casesQuery.getIsProcessingFuelSubtypes);
  getFuelSubtypes$ = this.store.select(casesQuery.getFuelSubtypes);
  getIsLoadingEmissionInfo$ = this.store.select(casesQuery.getIsLoadingEmissionInfo);
  getEmissionInfo$ = this.store.select(casesQuery.getEmissionInfo);
  roadTax$ = this.store.select(casesQuery.getRoadTax);

  activeCaseBlackBoxPricingDetailsLoading$ = this.store.select(casesQuery.getActiveCaseBlackBoxPricingDetailsLoading);
  activeCaseBlackBoxPricingDetailsData$ = this.store.select(casesQuery.getActiveCaseBlackBoxPricingDetailsData);

  returnReport$ = this.store.select(casesQuery.getReturnReport);
  isReturnReportProcessing$ = this.store.select(casesQuery.getIsReturnReportProcessing);
  isReturnReportModalOpen$ = this.store.select(casesQuery.getIsReturnReportModalOpen);
  isUpdatingGalleryFromTwinner$ = this.store.select(casesQuery.getIsUpdatingGalleryFromTwinner);
  isUpdatedGalleryFromTwinner$ = this.store.select(casesQuery.getIsUpdatedGalleryFromTwinner);

  isProcessingCaseOwnersList$ = this.store.select(casesQuery.getIsProcessingCaseOwnersList);
  isProcessingPreviousCaseOwnersList$ = this.store.select(casesQuery.getIsProcessingPreviousCaseOwnersList);
  isProcessingProspectCaseOwnersList$ = this.store.select(casesQuery.getIsProcessingProspectCaseOwnersList);

  ownersLength$ = this.store.select(casesQuery.getOwnersLength);
  previousOwnersLength$ = this.store.select(casesQuery.getPreviousOwnersLength);
  prospectCustomersLength$ = this.store.select(casesQuery.getProspectCustomersLength);

  isMoveToStockModalOpen$ = this.store.select(casesQuery.getIsMoveToStockModalOpen);
  isProcessingMoveToStock$ = this.store.select(casesQuery.getIsProcessingMoveToStock);

  isStockOfferModalOpen$ = this.store.select(casesQuery.getIsStockOfferModalOpen);
  isProcessingStockOffer$ = this.store.select(casesQuery.getIsProcessingStockOffer);
  getStockOfferUsedCustomerId$ = this.store.select(casesQuery.getStockOfferUsedCustomerId);

  isQuoteModalOpen$ = this.store.select(casesQuery.getIsQuoteModalOpen);
  isRegisterNewOpen$ = this.store.select(casesQuery.getIsRegisterNewModalOpen);
  isRegisterNewModalProcessing$ = this.store.select(casesQuery.getRegisterNewModalProcessing);

  isProcessingQuote$ = this.store.select(casesQuery.getIsProcessingQuote);

  isUpdatingBuildabilityData$ = this.store.select(casesQuery.getIsUpdatingBuildabilityData);

  canUserMakeValuation$ = this.store.select(casesQuery.getCanUserMakeValuation);

  activeCaseVehicleEngineFuel$ = this.store.select(casesQuery.getActiveCaseVehicleEngineFuel);

  get isEquipmentsValid$() {
    return this.store.select(casesQuery.getIsEquipmentsValid);
  }

  get isAllVinEquipmentsSelected$() {
    return this.store.select(casesQuery.getIsAllVinEquipmentsSelected);
  }

  get historicalPrices$() {
    return this.store.select(casesQuery.getHistoricalPrices);
  }

  get caseVinEreDataLoading$() {
    return this.store.select(casesQuery.getCaseVinEreDataLoading);
  }

  get isCaseIdentifiedByVin$() {
    return this.store.select(casesQuery.getIsCaseIdentifiedByVin);
  }

  constructor(
    private store: Store<CasesPartialState>,
    private actions$: Actions,
  ) {}

  getYoutubeUpdateStatus$() {
    return this.actions$.pipe(
      filter(
        x => x.type === CasesActionTypes.UpdateYoutubeUrlSuccess || x.type === CasesActionTypes.UpdateYoutubeUrlError,
      ),
      map(x => {
        switch (x.type) {
          case CasesActionTypes.UpdateYoutubeUrlSuccess:
            return 'success';
          case CasesActionTypes.UpdateYoutubeUrlError:
            return 'error';
        }
      }),
    );
  }

  isEquipmentEnabledBySoaCode$(soaCode: string) {
    return this.store.select(casesQuery.getIsEquipmentEnabledBySoaCode(soaCode));
  }

  isEquipmentContentEnabledBySoaCode$(packageSoaCode: string, contentSoaCode: string) {
    return this.store.select(casesQuery.getIsPackageEquipmentEnabledBySoaCode(packageSoaCode, contentSoaCode));
  }

  resetCaseData() {
    this.store.dispatch(new fromCasesActions.ResetCaseData());
  }

  loadBackBoxPriceDetails() {
    this.store.dispatch(new fromCasesActions.LoadCaseBlackBoxPriceDetails());
  }

  resetCaseLoadedStatus() {
    this.store.dispatch(new fromCasesActions.ResetCaseLoadedStatus());
  }

  loadAll() {
    this.store.dispatch(new fromCasesActions.LoadCases());
  }

  loadCase(id: string) {
    this.store.dispatch(new fromCasesActions.LoadCase(id));
  }

  loadedCase(caseSummaryDto: CaseSummaryDto) {
    this.store.dispatch(new fromCasesActions.CaseLoadCompleted(caseSummaryDto));
  }

  loadCaseFailed(error: HttpErrorResponse) {
    this.store.dispatch(new fromCasesActions.CaseLoadError(error));
  }

  updateCaseRequestValuation(caseId: string, force = false) {
    this.store.dispatch(new fromCasesActions.UpdateCaseRequestValuation({ caseId, force }));
  }

  updateSaveMileageAdjustment(command: UpdateCaseVehicleMileageCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateSaveMileageAdjustment(command));
  }

  updateCaseValuationDate(command: UpdateCaseValuationDateCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateValuationDate(command));
  }

  updateRegistrationDate(command: Pick<UpdateCaseRegistrationDateCommand, 'aggregateRootId' | 'registrationDate'>) {
    this.store.dispatch(new fromCasesActions.CaseUpdateRegistrationDate(command));
  }

  updateSaveQuote(command: CreateCaseQuoteCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateSaveQuote(command));
  }

  updateStockOffer(command: CreateCaseStockOfferCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateStockOffer(command));
  }

  updateVehicleKba(command: UpdateIdentifiedVehicleKBACommand, caseId: string) {
    this.store.dispatch(new fromCasesActions.CaseUpdateVehicleKba({ caseId, vehicle: command }));
  }

  updatePlateNo(command: UpdateCasePlateNumberCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdatePlateNo(command));
  }

  updateMatriculationNumber(command: UpdateIdentifiedVehicleMatriculationNumberCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateMatriculationNumber(command));
  }

  updatePurchaseVehicle(command: CasePurchaseVehicleCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdatePurchaseVehicle(command));
  }

  openPurchaseModal() {
    this.store.dispatch(new fromCasesActions.CasePurchaseModalOpen());
  }

  closePurchaseModal() {
    this.store.dispatch(new fromCasesActions.CasePurchaseModalClose());
  }

  updateSellVehicle(command: CaseSellVehicleCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateSellVehicle(command));
  }

  updateUpdateCaseOwners(command: UpdateCaseOwnersCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateUpdateCaseOwners(command));
  }

  updateUpdateCasePreviousOwners(command: UpdateCasePreviousOwnersCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateUpdateCasePreviousOwners(command));
  }

  updateUpdateCaseProspectOwners(command: UpdateCaseProspectCustomersCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateUpdateCaseProspectCustomers(command));
  }

  updateVin(caseId: string, vehicle: UpdateIdentifiedVehicleVinCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateVin({ caseId, vehicle: vehicle }));
  }

  loadQrCode(caseId: string) {
    this.store.dispatch(new CaseQRCodeLoad({ caseId }));
  }

  loadLightDamageEstimator(caseId: string) {
    this.store.dispatch(new fromCasesActions.CaseLoadLightDamageEstimator(caseId));
  }

  loadedLightDamageEstimator(estimator: LightDamageAreaDto[]) {
    this.store.dispatch(new fromCasesActions.CaseLightDamageEstimatorLoadCompleted(estimator));
  }

  updateLightDamageEstimator(command: UpdateCaseLightDamagePartsCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateLightDamageEstimator(command));
  }

  savePublicSiteConfiguration(payload: {
    command: UpdateCasePublicSiteConfigurationCommand;
    initialPublishedValue: boolean;
  }) {
    this.store.dispatch(new fromCasesActions.CaseUpdateSavePublicSiteConfiguration(payload));
  }

  openResellerSiteConfigurationModal() {
    this.store.dispatch(new fromCasesActions.OpenResellerSiteConfigurationModal());
  }

  closeResellerSiteConfigurationModal() {
    this.store.dispatch(new fromCasesActions.CloseResellerSiteConfigurationModal());
  }

  updateCaseResellerSiteConfiguration(payload: {
    command: UpdateCaseResellerSiteConfigurationCommand;
    initialPublishedValue: boolean;
  }) {
    this.store.dispatch(new fromCasesActions.CaseUpdateCaseResellerSiteConfiguration(payload));
  }

  updateReserveVehicle(payload: { endDate: string; customerId: string; information: string }) {
    this.store.dispatch(new fromCasesActions.CaseUpdateReserveVehicle(payload));
  }

  updateCancelVehicleReservation() {
    this.store.dispatch(new fromCasesActions.CaseUpdateCancelReservation());
  }

  openReservationModal() {
    this.store.dispatch(new fromCasesActions.CaseUpdateOpenReservationModal());
  }

  closeReservationModal() {
    this.store.dispatch(new fromCasesActions.CaseUpdateCloseReservationModal());
  }

  updateValuationUniqueData(payload: UpdateCaseUniqueDataCommand, changedBranch: boolean) {
    this.store.dispatch(
      new fromCasesActions.CaseUpdateValuationUniqueDataValidateBroadcast({
        uniqueData: payload,
        updatedFromBroadcast: false,
        changedBranch,
      }),
    );
  }

  openUniqueDataModal() {
    this.store.dispatch(new fromCasesActions.CaseOpenUniqueDataModal());
  }

  closeUniqueDataModal() {
    this.store.dispatch(new fromCasesActions.CaseCloseUniqueDataModal());
  }

  loadCaseDocumentList(caseId: string) {
    this.store.dispatch(new LoadCaseDocumentList(caseId));
  }

  addCaseDocument(updateDocumentCommand: FileEntity, isUpdate: boolean) {
    this.store.dispatch(new AddCaseDocument(updateDocumentCommand, isUpdate));
  }

  documentUploadSetTotalCount(totalCount: number) {
    this.store.dispatch(new CaseDocumentUploadSetTotalCount(totalCount));
  }

  deleteDocument(aggregateRootId: string, fileKey: string) {
    this.store.dispatch(new DeleteCaseDocument({ fileKey, aggregateRootId }));
  }

  downloadDocument(caseId: string, fileKey: string, fileName: string) {
    this.store.dispatch(new DownloadCaseDocument({ fileKey, caseId, fileName }));
  }

  openReturnToStockModal() {
    this.store.dispatch(new fromCasesActions.CaseUpdateOpenReturnToStockModal());
  }

  closeReturnToStockModal() {
    this.store.dispatch(new fromCasesActions.CaseUpdateCloseReturnToStockModal());
  }

  updateTechnicalData(caseId: string, command: UpdateIdentifiedVehicleTechnicalDataCommand) {
    this.store.dispatch(
      new fromCasesActions.CaseUpdateTechnicalData({
        caseId: caseId,
        technicalData: command,
        broadcastSettings: null,
        updatedFromBroadcast: false,
      }),
    );
  }

  openTechnicalDataModal() {
    this.store.dispatch(new fromCasesActions.CaseOpenTechnicalDataModal());
  }

  closeTechnicalDataModal() {
    this.store.dispatch(new fromCasesActions.CaseCloseTechnicalDataModal());
  }

  returnToStock(command: CaseReturnToStockCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateReturnToStock(command));
  }

  moveDueToInStock(command: CaseDueToInStockCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateDueToInStock(command));
  }

  updateCaseNotes(updateCaseNotesCommand: UpdateCaseNotesCommand) {
    this.store.dispatch(new fromCasesActions.UpdateCaseNotes(updateCaseNotesCommand));
  }

  updateActiveCaseVehicleEquipmentIsEnabled(payload: { soaCode: string; isEnabled: boolean }) {
    this.store.dispatch(new fromCasesActions.CaseUpdateActiveCaseVehicleEquipmentIsEnabled(payload));
  }

  loadActiveCaseCO2EfficiencyStickerImage() {
    this.store.dispatch(new fromCasesActions.LoadEmissionStickerImage());
  }

  updateStockPricingDetails(command: UpdateCaseStockPricesCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateStockPricingDetails(command));
  }

  updateSoldPricingDetails(command: UpdateCaseSoldPricesCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateSoldPricingDetails(command));
  }

  changePricingModalVisibility(isVisible: boolean) {
    this.store.dispatch(new fromCasesActions.CaseChangePricingDetailsModalVisibility(isVisible));
  }

  resendCaseLatestQuote(command: ResendCaseLatestQuoteCommand) {
    this.store.dispatch(new fromCasesActions.ResendCaseLatestQuote(command));
  }

  resendCaseLatestOffer(command: ResendCaseLatestStockOfferCommand) {
    this.store.dispatch(new fromCasesActions.ResendCaseLatestOffer(command));
  }

  loadFuelSubtypes(caseId: string) {
    this.store.dispatch(new fromCasesActions.CaseLoadFuelSubtypes(caseId));
  }

  loadEmissionInfo(caseId: string) {
    this.store.dispatch(new fromCasesActions.CaseLoadEmissionInfo(caseId));
  }

  loadRoadTax(caseId: string) {
    this.store.dispatch(new fromCasesActions.CaseLoadRoadTax(caseId));
  }

  resetWasValuation() {
    this.store.dispatch(new fromCasesActions.ResetWasValuation());
  }

  loadReturnReport(caseId: string) {
    this.store.dispatch(new fromCasesActions.CaseLoadReturnReport(caseId));
  }

  returnReportLoaded(report: ReturnReportDto) {
    this.store.dispatch(new fromCasesActions.CaseLoadReturnReportCompleted(report));
  }

  openReturnReportModal() {
    this.store.dispatch(new fromCasesActions.CaseOpenReturnReportModal());
  }

  closeReturnReportModal() {
    this.store.dispatch(new fromCasesActions.CaseCloseReturnReportModal());
  }

  updateReturnReport(command: UpdateCaseReturnReportCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateReturnReport(command));
  }

  updateYoutubeUrl(youtubeUrlCommand: UpdateCaseYoutubeUrlCommand) {
    this.store.dispatch(new fromCasesActions.UpdateYoutubeUrl(youtubeUrlCommand));
  }

  updateTwinnerImages(updateTwinnerImagesCommand: UpdateTwinnerImagesCommand) {
    this.store.dispatch(new fromCasesActions.CaseUpdateTwinnerImages(updateTwinnerImagesCommand));
  }

  checkCaseEquipmentValid() {
    this.store.dispatch(new fromCasesActions.CaseLoadEquipmentsValid());
  }

  openMoveToStockModal() {
    this.store.dispatch(new fromCasesActions.CaseOpenMoveToStockModal());
  }

  closeMoveToStockModal() {
    this.store.dispatch(new fromCasesActions.CaseCloseMoveToStockModal());
  }

  openStockOfferModal() {
    this.store.dispatch(new fromCasesActions.CaseOpenStockOfferModal());
  }

  closeStockOfferModal() {
    this.store.dispatch(new fromCasesActions.CaseCloseStockOfferModal());
  }

  openQuoteModal() {
    this.store.dispatch(new fromCasesActions.CaseOpenQuoteModal());
  }

  closeQuoteModal() {
    this.store.dispatch(new fromCasesActions.CaseCloseQuoteModal());
  }

  openRegisterNewVehicleModal() {
    this.store.dispatch(new fromCasesActions.CaseOpenRegisterNewModal());
  }

  closeRegisterNewVehicleModal() {
    this.store.dispatch(new fromCasesActions.CaseCloseRegisterNewModal());
  }

  openSellModal() {
    this.store.dispatch(new fromCasesActions.CaseOpenSellModal());
  }

  closeSellModal() {
    this.store.dispatch(new fromCasesActions.CaseCloseSellModal());
  }

  setBuildabilityData(payload: { identifiedVehicleId: string; caseId: string }) {
    this.store.dispatch(new fromCasesActions.UpdateIdentifiedVehicleBuildabilityData(payload));
  }

  registerNewVehicle(data: CaseUpdateRegistrationDateBody) {
    this.store.dispatch(new fromCasesActions.RegisterNewVehicle(data));
  }

}
