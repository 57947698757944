<nz-select
  [nzBackdrop]="true"
  [nzMaxTagCount]="0"
  [nzMode]="'multiple'"
  [nzSize]="'small'"
  [nzMaxTagPlaceholder]="tagPlaceHolder"
  [compareWith]="compareOptions"
  [(ngModel)]="selectedValues"
  [nzShowArrow]="true"
  (ngModelChange)="onValueChange($event)"
  (nzOpenChange)="dropdownOpen = $event"
>
  <!-- Without grouping -->
  <ng-container *ngIf="!showGroups">
    <nz-option
      *ngFor="let option of options"
      [nzValue]="option.value"
      [nzLabel]="isTranslatable() ? (option.name | translate) : option.name"
      nzCustomContent
    >
      <span [title]="isTranslatable() ? (option.name | translate) : option.name">
        {{ isTranslatable() ? (option.name | translate) : option.name }}
      </span>
    </nz-option>
  </ng-container>

  <!-- With grouping -->
  <ng-container *ngIf="showGroups">
    <nz-option-group *ngFor="let groupLabel of groupLabelsFromOptions" [nzLabel]="groupLabel | translate">
      <nz-option
        *ngFor="let option of optionsByGroup(groupLabel)"
        [nzValue]="option.value"
        [nzLabel]="isTranslatable() ? (option.name | translate) : option.name"
        nzCustomContent
      >
        <span [title]="isTranslatable() ? (option.name | translate) : option.name">
          {{ isTranslatable() ? (option.name | translate) : option.name }}
        </span>
      </nz-option>
    </nz-option-group>
  </ng-container>
</nz-select>

<ng-template #tagPlaceHolder let-selectedList>
  {{ selectedList.length }} {{ 'dataTable.dropdownFilter.selected' | translate }}
</ng-template>
