import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import {
  BranchDto,
  BranchListItemDto,
  UpdateBranchContactCommand,
  UpdateBranchUrlCommand
} from '@vpfa/rest-api/admin';

import { BranchesPartialState } from './branches.reducer';
import { branchesQuery } from './branches.selectors';
import {
  BranchDetailsLoaded,
  BranchesLoaded,
  fromBranchesActions,
  LoadBranchDetails,
  LoadBranchesByBusiness,
  LoadBranchesForDealerNetwork,
  UpdateBranchContact,
  BranchesActionTypes,
} from './branches.actions';
import {
  LiveRetailConfigurationDto,
  UpdateBranchSlowMovingKPINumberOfDaysCommand
} from '@vpfa/rest-api/valuation';
import { UpdateGdvInsuranceClaimingBranchConfigurationCommand } from '@vpfa/rest-api/vehicle-insurance-claiming';
import { Actions } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class BranchesFacade {
  listLoading$ = this.store.pipe(select(branchesQuery.getListLoading));
  allBranches$ = this.store.pipe(select(branchesQuery.getAllBranches));

  branchesForDealerNetworkLoading$ = this.store.pipe(select(branchesQuery.getBranchesForDealerNetworkLoading));
  branchesForDealerNetwork$ = this.store.pipe(select(branchesQuery.getBranchesForDealerNetwork));

  isImportingCustomers$ = this.store.pipe(select(branchesQuery.isImportingCustomers));

  resellersEmailTemplateDetails$ = this.store.pipe(select(branchesQuery.getResellersEmailTemplateDetails));

  getIsResellersEmailTemplateProcessing$ = this.store.pipe(select(branchesQuery.getIsResellersEmailTemplateProcessing));

  isResellersEmailTemplateDetailsLoading$ = this.store.pipe(
    select(branchesQuery.getIsResellersEmailTemplateDetailsLoading),
  );

  quotesEmailTemplateDetails$ = this.store.pipe(select(branchesQuery.getQuotesEmailTemplateDetails));

  isProcessingQuotesEmailTemplateDetailsForm$ = this.store.pipe(
    select(branchesQuery.getIsProcessingQuotesEmailTemplateDetailsForm),
  );

  isQuotesEmailTemplateDetailsLoading$ = this.store.pipe(select(branchesQuery.getIsLoadingQuotesEmailTemplateDetails));

  offerEmailTemplateDetails$ = this.store.pipe(select(branchesQuery.getOfferEmailTemplateDetails));

  isProcessingOfferEmailTemplateDetailsForm$ = this.store.pipe(
    select(branchesQuery.getIsProcessingOfferEmailTemplateDetailsForm),
  );

  isOfferEmailTemplateDetailsLoading$ = this.store.pipe(select(branchesQuery.getIsLoadingOfferEmailTemplateDetails));

  getBranchDetails$ = this.store.pipe(select(branchesQuery.getBranchDetails));
  getBranchDetailsLoading$ = this.store.pipe(select(branchesQuery.getBranchDetailsLoading));

  isBranchDetailsLoading$ = this.store.pipe(select(branchesQuery.getBranchDetailsLoading));
  getBranchUrl$ = this.store.pipe(select(branchesQuery.getBranchUrl));

  liveRetailConfiguration$ = this.store.pipe(select(branchesQuery.getLiveRetailConfiguration));
  numberOfDaysSlowMovingKPI$ = this.store.pipe(select(branchesQuery.getNumberOfDaysSlowMovingKPI));
  isBranchConfigurationLoading$ = this.store.pipe(select(branchesQuery.isBranchConfigurationLoading));
  isBranchTolerancesUpdating$ = this.store.pipe(select(branchesQuery.isBranchTolerancesUpdating));
  isBranchStockDaysUpdating$ = this.store.pipe(select(branchesQuery.isBranchStockDaysUpdating));

  isBranchUrlUpdating$ = this.store.pipe(select(branchesQuery.isBranchURLUpdating));

  getTwinnerUsername$ = this.store.pipe(select(branchesQuery.getTwinnerUsername));
  getIsTwinnerPasswordSet$ = this.store.pipe(select(branchesQuery.getIsTwinnerPasswordSet));
  isTwinnerLoading$ = this.store.pipe(select(branchesQuery.isTwinnerLoading));

  getBranchGdvConfiguration$ = this.store.pipe(select(branchesQuery.getBranchGdvConfiguration));
  isBranchGdvConfigurationLoading$ = this.store.pipe(select(branchesQuery.isBranchGdvConfigurationLoading));
  isBranchGdvConfigurationProcessing$ = this.store.pipe(select(branchesQuery.isBranchGdvConfigurationProcessing));

  constructor(
    private store: Store<BranchesPartialState>,
    private actions$: Actions,
  ) {}

  updateBranchContactStatus$() {
    return this.actions$.pipe(
      filter(
        x =>
          x.type === BranchesActionTypes.UpdateBranchContactSuccess ||
          x.type === BranchesActionTypes.BranchDetailsLoadError,
      ),
      map(x => {
        switch (x.type) {
          case BranchesActionTypes.UpdateBranchContactSuccess:
            return 'success';
          case BranchesActionTypes.BranchDetailsLoadError:
            return 'error';
        }
      }),
    );
  }

  updateBranchContact(payload: UpdateBranchContactCommand) {
    this.store.dispatch(new UpdateBranchContact(payload));
  }

  loadByBusiness(businessId: string) {
    this.store.dispatch(new LoadBranchesByBusiness(businessId));
  }

  loadBranchesForDealerNetwork(businessId: string) {
    this.store.dispatch(new LoadBranchesForDealerNetwork(businessId));
  }

  branchesLoaded(branches: BranchListItemDto[]) {
    this.store.dispatch(new BranchesLoaded(branches));
  }

  loadBranchDetails(branchId: string) {
    this.store.dispatch(new LoadBranchDetails(branchId));
  }

  loadedBranchDetails(branch: BranchDto) {
    this.store.dispatch(new BranchDetailsLoaded(branch));
  }

  updateBranchUrl(command: UpdateBranchUrlCommand) {
    this.store.dispatch(new fromBranchesActions.BranchUrlUpdate(command));
  }

  updatedBranchUrl(command: string) {
    this.store.dispatch(new fromBranchesActions.BranchUrlUpdated(command));
  }

  importCustomersFromCsv(businessId: string, branchId: string, file: Blob) {
    this.store.dispatch(new fromBranchesActions.ImportCustomersFromCsv({ businessId, branchId, file }));
  }

  loadResellersEmailTemplateDetails(branchId: string) {
    this.store.dispatch(new fromBranchesActions.LoadResellersEmailTemplate(branchId));
  }

  loadQuotesEmailTemplateDetailsByBranch(branchId: string) {
    this.store.dispatch(new fromBranchesActions.LoadQuotesEmailTemplateByBranch(branchId));
  }

  loadQuotesEmailTemplateDetailsByUserContext() {
    this.store.dispatch(new fromBranchesActions.LoadQuotesEmailTemplateByUserContext());
  }

  loadOfferEmailTemplateDetailsByBranch(branchId: string) {
    this.store.dispatch(new fromBranchesActions.LoadOfferEmailTemplateByBranch(branchId));
  }

  loadOfferEmailTemplateDetailsByUserContext() {
    this.store.dispatch(new fromBranchesActions.LoadOfferEmailTemplateByUserContext());
  }

  loadBranchConfiguration(branchId: string) {
    this.store.dispatch(new fromBranchesActions.LoadBranchConfiguration(branchId));
  }

  clearBranchConfiguration() {
    this.store.dispatch(new fromBranchesActions.ClearBranchConfiguration());
  }

  updateBranchTolerances(configuration: LiveRetailConfigurationDto) {
    this.store.dispatch(new fromBranchesActions.UpdateBranchTolerances(configuration));
  }

  updateStockDaysNumber(command: UpdateBranchSlowMovingKPINumberOfDaysCommand) {
    this.store.dispatch(new fromBranchesActions.UpdateBranchStockDays(command));
  }

  loadTwinnerCredentials(branchId: string) {
    this.store.dispatch(new fromBranchesActions.LoadTwinnerCredentials({ id: branchId }));
  }

  clearTwinnerCredentials() {
    this.store.dispatch(new fromBranchesActions.ClearTwinnerCredentials());
  }

  loadBranchGdvConfiguration(branchId: string) {
    this.store.dispatch(new fromBranchesActions.LoadBranchGdvConfiguration(branchId));
  }

  updateBranchGdvConfiguration(command: UpdateGdvInsuranceClaimingBranchConfigurationCommand) {
    this.store.dispatch(new fromBranchesActions.UpdateBranchGdvConfiguration(command));
  }

  branchGdvConfigurationStatus$() {
    return this.actions$.pipe(
      filter(
        x =>
          x.type === BranchesActionTypes.UpdateBranchGdvConfigurationSuccess ||
          x.type === BranchesActionTypes.UpdateBranchGdvConfigurationError,
      ),
      map(x => {
        switch (x.type) {
          case BranchesActionTypes.UpdateBranchGdvConfigurationSuccess:
            return 'success';
          case BranchesActionTypes.UpdateBranchGdvConfigurationError:
            return 'error';
        }
      }),
    );
  }
}
