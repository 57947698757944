import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CountryDto, Features, IdentificationMethod as AdminIdentificationMethods } from '@vpfa/rest-api/admin';
import { IdentificationMethod as ValuationIdentificationMethods } from '@vpfa/rest-api/valuation';
import { distinctUntilChanged, filter, map, take, withLatestFrom } from 'rxjs/operators';
import { LocalePartialState } from './locale.reducer';
import { localeQuery } from './locale.selectors';
// FIXME: relative due to circular dependency
import { ProfileFacade } from '../../../../profile/data/src/lib/+state/profile.facade';
import {
  CheckTranslateFile,
  CsvConfigurationLoad,
  FeatureConfigLoad,
  LoadBranchCountry,
  LoadUserCountry,
  SelectContentLanguage,
  SelectUILanguage,
} from './locale.actions';
import { FeaturesTechnicalDataConfiguration } from '../interfaces';
import { LanguageEnum } from '@vpfa/shared/translate';
import { enumToOptions } from '@vpfa/utils';
import { isNil } from 'lodash/fp';
import { availableIdentificationMethods } from '../country.utils';
import { combineLatest, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocaleFacade {
  country$ = this.store.select(localeQuery.getCountry);
  currency$ = this.store.select(localeQuery.getCurrency);
  locale$ = this.store.select(localeQuery.getLocale);
  timezone$ = this.store.select(localeQuery.getTimezone);
  csvConfiguration$ = this.store.select(localeQuery.getCsvConfiguration);
  featuresForCountry$ = this.store.select(localeQuery.getFeaturesForCountry);
  featureConfigLoading$ = this.store.select(localeQuery.getFeatureConfigLoading);
  featureConfigLoaded$ = this.store.select(localeQuery.getFeatureConfigLoaded);
  countryFeatureConfig$ = this.store.select(localeQuery.getFeatureConfigCountry);
  featureConfigAllVehicleTypes$ = this.store.select(localeQuery.getFeatureConfigAllVehicleTypes);

  isSpecificUserCountry$(countryCode: string) {
    return this.country$.pipe(
      map(x => x?.countryCode?.toLowerCase()),
      map(x => x === countryCode?.toLowerCase()),
    );
  }

  uiLanguageOptions$ = this.country$.pipe(
    filter(Boolean),
    map(
      (uc: CountryDto): Pick<CountryDto, 'defaultLanguage' | 'alternativeLanguages'> => ({
        defaultLanguage: uc.defaultLanguage,
        alternativeLanguages: uc.alternativeLanguages,
      }),
    ),
    map(uc => {
      const availableLanguages = [uc.defaultLanguage, ...uc.alternativeLanguages];
      return enumToOptions(LanguageEnum, 'admin.country.languages.')
        .filter(opt => undefined !== availableLanguages.find(el => el === opt.value))
        .map(x => ({ ...x, additional: { isDefaultLanguage: x.value === uc.defaultLanguage } }));
    }),
  );

  contentLanguageOptions$ = this.country$.pipe(
    filter(Boolean),
    map(
      (uc: CountryDto): Pick<CountryDto, 'defaultContentLanguage' | 'alternativeContentLanguages'> => ({
        alternativeContentLanguages: uc.alternativeContentLanguages,
        defaultContentLanguage: uc.defaultContentLanguage,
      }),
    ),
    map(uc => {
      const availableLanguages = [uc.defaultContentLanguage, ...uc.alternativeContentLanguages];
      return enumToOptions(LanguageEnum, 'admin.country.languages.').filter(
        opt => undefined !== availableLanguages.find(el => el === opt.value),
      );
    }),
  );

  selectedUiLanguage$ = this.store.pipe(select(localeQuery.getSelectedUILanguage), filter<LanguageEnum>(Boolean));
  selectedContentLanguage$ = this.store.pipe(
    select(localeQuery.getSelectedContentLanguage),
    filter<LanguageEnum>(Boolean),
  );
  isCurrencyPrefix$ = this.store.pipe(select(localeQuery.getIsCurrencyPrefix));
  countryDefaultLanguage$ = this.store.pipe(select(localeQuery.getCountryDefaultLanguage));
  localeEffectsInitialized$ = this.store.pipe(select(localeQuery.localeEffectsInitialized));

  get experianTermsUrl$() {
    return this.store.pipe(select(localeQuery.getExperianTermsUrl));
  }

  get countryAvailableIdentificationMethods$(): Observable<AdminIdentificationMethods[]> {
    return combineLatest([
      this.isFeatureConfigurationEnabled(Features.ShowIdentificationKBA),
      this.isFeatureConfigurationEnabled(Features.ShowIdentificationVIN),
      this.isFeatureConfigurationEnabled(Features.ShowIdentificationVRM),
      this.isFeatureConfigurationEnabled(Features.ShowIdentificationMatriculationNumber),
      this.country$,
    ]).pipe(
      map(([kba, vin, vrm, matriculationNumber, country]) => {
        const methods: AdminIdentificationMethods[] = [AdminIdentificationMethods.Natcode];

        if (vrm) methods.push(AdminIdentificationMethods.VRM);
        if (vin) methods.push(AdminIdentificationMethods.VIN);
        if (kba) methods.push(AdminIdentificationMethods.KBA);
        if (matriculationNumber) methods.push(AdminIdentificationMethods.MatriculationNumber);

        return availableIdentificationMethods(country?.countryCode, methods);
      }),
    );
  }

  get featureIdentifiersOrderConfiguration$(): Observable<ValuationIdentificationMethods[]> {
    return combineLatest([
      this.isFeatureConfigurationEnabled(Features.ShowKBA),
      this.isFeatureConfigurationEnabled(Features.ShowVin),
      this.isFeatureConfigurationEnabled(Features.ShowVRMFirst),
      this.isFeatureConfigurationEnabled(Features.HideVRM),
      this.isFeatureConfigurationEnabled(Features.ShowIdentificationMatriculationNumber),
    ]).pipe(
      map(([kba, vin, vrmFirst, hideVrm, matriculationNumber]) => {
        const features: ValuationIdentificationMethods[] = [];

        if (vin) features.push(ValuationIdentificationMethods.VIN);

        features.push(ValuationIdentificationMethods.Natcode);

        if (kba) features.push(ValuationIdentificationMethods.KBA);

        if (hideVrm === false) {
          vrmFirst
            ? features.unshift(ValuationIdentificationMethods.VRM)
            : features.push(ValuationIdentificationMethods.VRM);
        }

        if (matriculationNumber) features.push(ValuationIdentificationMethods.MatriculationNumber);

        return features;
      }),
    );
  }

  loadFeatureConfigByCountry(countryCode: string) {
    this.featuresForCountry$
      .pipe(
        take(1),
        filter(currentCountryCode => currentCountryCode !== countryCode),
      )
      .subscribe(() => {
        this.store.dispatch(new FeatureConfigLoad(countryCode));
      });
  }

  loadUserContextFeatures() {
    this.featuresForCountry$
      .pipe(
        withLatestFrom(this.profileFacade.userContext$),
        filter(([, context]) => !isNil(context)),
        take(1),
        filter(([currentCountryCode, userContext]) => currentCountryCode !== userContext.countryCode),
      )
      .subscribe(([, userContext]) => {
        this.store.dispatch(new FeatureConfigLoad(userContext.countryCode));
      });
  }

  featureConfiguration(vehicleType?: string) {
    return isNil(vehicleType)
      ? this.store.select(localeQuery.getFeatureConfigCountry)
      : this.store.select(localeQuery.getFeatureConfigVehicle(), { vehicleType });
  }

  featureTechnicalDataConfiguration(vehicleType) {
    return this.featureConfiguration(vehicleType).pipe(
      distinctUntilChanged(),
      filter(featureConfiguration => !isNil(featureConfiguration)),
      map(featureConfiguration => featureTechnicalDataConfigurationMapping(featureConfiguration)),
    );
  }

  buildDateNotAvailableForMake(makeCode: number) {
    return this.store.select(localeQuery.getBuildDateNotAvailableForMake(), { makeCode });
  }

  featuresForVehicleTypes(feature: Features, vehicleTypes: number[]): Observable<Record<number, boolean>> {
    return combineLatest(
      vehicleTypes.map(vehicleCode =>
        this.isFeatureConfigurationEnabled(feature, vehicleCode.toString()).pipe(map(hide => ({ vehicleCode, hide }))),
      ),
    ).pipe(
      map(x => {
        let result = {};
        for (let value of x) {
          result[value.vehicleCode] = value.hide;
        }
        return result;
      }),
    );
  }

  isFeatureConfigurationEnabled(feature: Features, vehicleType?: string) {
    return this.featureConfiguration(vehicleType).pipe(
      distinctUntilChanged(),
      filter(featureConfiguration => !isNil(featureConfiguration)),
      map(featureConfiguration => featureConfigurationEnabled(featureConfiguration, feature)),
    );
  }

  constructor(
    private store: Store<LocalePartialState>,
    private profileFacade: ProfileFacade,
  ) {}

  selectContentLocale(lang: LanguageEnum) {
    this.store.dispatch(new SelectContentLanguage(lang));
  }

  selectUILanguage(lang: LanguageEnum) {
    this.store.dispatch(new SelectUILanguage(lang));
  }

  loadUserCountry() {
    this.store.dispatch(new LoadUserCountry());
  }

  loadCountryForBranch(branchId: string) {
    this.store.dispatch(new LoadBranchCountry(branchId));
  }

  loadCsvConfiguration() {
    this.store.dispatch(new CsvConfigurationLoad());
  }

  checkTranslateFile(language: LanguageEnum, countryCode: string) {
    this.store.dispatch(new CheckTranslateFile({ language, countryCode }));
  }
}

export function featureConfigurationEnabled(config: Features[], feature: Features): boolean {
  return config.indexOf(feature) !== -1;
}

export function featureTechnicalDataConfigurationMapping(config: Features[]): FeaturesTechnicalDataConfiguration {
  return <FeaturesTechnicalDataConfiguration>{
    hideDoors: featureConfigurationEnabled(config, Features.HideTechnicalDataDoors),
    hideMaxLoad: featureConfigurationEnabled(config, Features.HideTechnicalDataMaxLoad),
    hideTrailerLoadBraked: featureConfigurationEnabled(config, Features.HideTechnicalDataTrailerLoadBraked),
    hideTrailerLoadUnbraked: featureConfigurationEnabled(config, Features.HideTechnicalDataTrailerLoadUnbraked),
    hideRoofLoad: featureConfigurationEnabled(config, Features.HideTechnicalDataRoofLoad),
    hideKerbWeight: featureConfigurationEnabled(config, Features.HideTechnicalDataKerbWeightInclDriver),
    hideWheelbase: featureConfigurationEnabled(config, Features.HideTechnicalDataWheelbase),
    hideBootCapacity: featureConfigurationEnabled(config, Features.HideTechnicalDataBootCapacity),
    hideLength: featureConfigurationEnabled(config, Features.HideTechnicalDataLength),
    hideHeight: featureConfigurationEnabled(config, Features.HideTechnicalDataHeight),
    hideWidth: featureConfigurationEnabled(config, Features.HideTechnicalDataWidth),
    showPerformanceImperial: featureConfigurationEnabled(config, Features.ShowPerformanceImperial),
    hidePartialCover: featureConfigurationEnabled(config, Features.HidePartialCover),
    hideComprehensiveCover: featureConfigurationEnabled(config, Features.HideComprehensiveCover),
    hideRentalCarGroup: featureConfigurationEnabled(config, Features.HideRentalCarGroup),
    hideNonRentalMaintenanceCosts: featureConfigurationEnabled(config, Features.HideNonRentalMaintenanceCosts),
    hideRentalMaintenanceCosts: featureConfigurationEnabled(config, Features.HideRentalMaintenanceCosts),
    hidePersonalCosts: featureConfigurationEnabled(config, Features.HidePersonalCosts),
    hideLiabilityInsuranceClass: featureConfigurationEnabled(config, Features.HideLiabilityInsuranceClass),
    showInsuranceGroup: featureConfigurationEnabled(config, Features.ShowInsuranceGroup),
    hideInsuranceInformationSection: hideInsuranceInformationSection(config),
    hideCompensationForLossOfUse: featureConfigurationEnabled(config, Features.HideCompensationForLossOfUse),
    showNovaTax: featureConfigurationEnabled(config, Features.ShowNovaTax),
    showMaxWidthForLCV: featureConfigurationEnabled(config, Features.ShowMaxWidthForLCV),
    showSeatHeightForBikes: featureConfigurationEnabled(config, Features.ShowSeatHeightForBikes),
  };
}

export function hideInsuranceInformationSection(config: Features[]): boolean {
  return (
    featureConfigurationEnabled(config, Features.HidePartialCover) &&
    featureConfigurationEnabled(config, Features.HideComprehensiveCover) &&
    featureConfigurationEnabled(config, Features.HideRentalCarGroup) &&
    featureConfigurationEnabled(config, Features.HideNonRentalMaintenanceCosts) &&
    featureConfigurationEnabled(config, Features.HideRentalMaintenanceCosts) &&
    featureConfigurationEnabled(config, Features.HidePersonalCosts) &&
    featureConfigurationEnabled(config, Features.HideLiabilityInsuranceClass) &&
    !featureConfigurationEnabled(config, Features.ShowInsuranceGroup)
  );
}
