import { Component, OnDestroy, ViewChild } from '@angular/core';
import { SelectMode, SelectOption } from '@vpfa/ui-kit';
import { AgFrameworkComponent } from 'ag-grid-angular';
import {
  Column,
  ColumnResizedEvent,
  FilterChangedEvent,
  GridApi,
  IFilterParams,
  IFloatingFilter,
  IFloatingFilterParams,
  RowNode,
  TextFilter,
  ValueGetterFunc,
} from 'ag-grid-community';
import { isEmpty, isNil, isString, orderBy, uniq, isEqual, uniqBy } from 'lodash';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { DATA_TABLE_COMPONENTS } from '../../models/data-table-custom-components.enum';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { TranslateService } from '@ngx-translate/core';
import { isCallable } from '@vpfa/utils';

export type Option = string | SelectOption | number | any;

export interface DropdownFloatingFilterExtendParams {
  defaultSelectedValues?: string[];
  overrideOptions?: SelectOption[];
  options$?: Observable<Option[]>;
  showGroups?: boolean;
  disableSorting?: boolean;
}

export interface DropdownFloatingFilterParams extends IFloatingFilterParams {
  filterParams: IFilterParams & DropdownFloatingFilterExtendParams;
}

export function dropdownFilterComparator(filter: string, gridValue: any, filterText: string) {
  // HACK: need improvement in future (may cause performance issues in large collections)
  const filterValues = JSON.parse(filterText);
  return (
    filterValues.length === 0 ||
    filterValues.map((value: number | string) => value.toString()).indexOf(gridValue.toString()) !== -1
  );
}

export const getFilterDropdownValue = (field: string, notAvailable: string) => (params: RowNode) => {
  return !isNil(params.data[field]) ? params.data[field] : notAvailable;
};

export const getFilterDropdownTextsAsTranslateKey = (field: string, notAvailable: string) => (params: RowNode) => {
  return !isNil(params.data[field]?.texts)
    ? { name: params.data[field]?.texts, value: params.data[field]?.id }
    : notAvailable;
};

export const getFilterDropdownTextsAsNameValue = (field: string, notAvailable: string) => (params: RowNode) => {
  return {
    name: !isNil(params.data[field]?.texts) ? params.data[field]?.texts : notAvailable,
    value: params.data?.[field]?.id,
  };
};

export const getFilterDropdownUnitValue = (field: string, notAvailable: string) => (params: RowNode) => {
  return params.data[field]?.value ?? params.data[field] ?? notAvailable;
};

@Component({
  selector: 'vpfa-dropdown-floating-filter',
  templateUrl: './dropdown-floating-filter.component.html',
  styleUrls: ['./dropdown-floating-filter.component.scss'],
})
export class DropdownFloatingFilterComponent
  implements IFloatingFilter, AgFrameworkComponent<DropdownFloatingFilterParams>, OnDestroy
{
  private onDestroy$ = new Subject<void>();

  params: DropdownFloatingFilterParams;
  column: Column;
  options: SelectOption[] = [];
  mode: SelectMode = SelectMode.multiple;
  selectedValues: string[] = [];
  gridApi: GridApi;
  fieldName: string;
  dropdownOpen = false;
  shouldFilter = false;
  previousFilterSelection: any[] = [];
  keepSelectedFilters = false;
  showGroups: boolean = false;
  groupLabelsFromOptions: string[] = [];

  @ViewChild(NzSelectComponent, { static: true }) filterDropdown: NzSelectComponent;

  constructor(private translateService: TranslateService) {}

  agInit(params: DropdownFloatingFilterParams): void {
    this.params = params;
    this.gridApi = params.api;
    this.fieldName = this.params.column.getColDef().field;
    this.column = params.column;
    this.keepSelectedFilters = params.column.getColDef()?.filterParams?.newRowsAction === 'keep';

    this.showGroups = !!this.params.filterParams.showGroups;

    this.initDropdownUpdate();

    // TODO: AG-UPDATE - as any required?
    fromEvent<ColumnResizedEvent>(this.params.api as any, 'columnResized')
      .pipe(
        filter(event => event.finished && event.source === 'autosizeColumns'),
        // debounceTime works here as delay & throttling
        debounceTime(15),
        // nz-option-container position is shifted after AgGrid
        // columns-autosize, so we need to update cdk overlay position
        tap(() => this.updateCdkConnectedOverlayPositions()),
        takeUntil(this.onDestroy$),
      )
      .subscribe();
  }

  optionsByGroup(groupLabel?: string): SelectOption[] {
    return this.options?.filter(x => x.groupLabel === groupLabel);
  }

  initDropdownUpdate() {
    if (this.params.filterParams.options$) {
      this.initDropdownUpdateForInfiniteMode();
    } else {
      this.initDropdownUpdateForClientMode();
    }
  }

  initDropdownUpdateForClientMode() {
    this.updateDropdownForClientMode();
    // TODO: AG-UPDATE - as any required?
    const filterChange$ = fromEvent(this.params.api as any, 'filterChanged');
    const rowDataChange$ = fromEvent(this.params.api as any, 'rowDataUpdated');
    merge(filterChange$, rowDataChange$)
      .pipe(debounceTime(100), takeUntil(this.onDestroy$))
      .subscribe(() => this.updateDropdownForClientMode());
  }

  private initDropdownUpdateForInfiniteMode() {
    this.params.filterParams.options$
      .pipe(
        filter(options => !isNil(options)),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$),
      )
      .subscribe(optionsData => {
        if (this.ignoreOptionUpdateWhenInInfiniteMode()) {
          return;
        }

        let options = [];

        if (!this.showGroups) {
          // TODO: add tests
          options = optionsData.map(optionData =>
            this.params.filterParams.valueGetter({
              data: {
                [this.fieldName]: optionData,
              },
            } as any),
          );
        } else {
          options = optionsData;
        }

        this.updateDropdown(options, true);
      });
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent): void {
    if (
      filterChangedEvent &&
      filterChangedEvent.type === 'filterChanged' &&
      ((isNil(parentModel) && (isNil(this.selectedValues) || this.selectedValues.length === 0)) ||
        (!isNil(parentModel) && parentModel.filter === JSON.stringify(this.selectedValues)))
    ) {
      return;
    }

    if (!parentModel) {
      this.selectedValues = [];
    } else {
      this.selectedValues = JSON.parse(parentModel.filter);
    }
  }

  onValueChange(value: SelectOption[]) {
    this.params.parentFilterInstance((instance: TextFilter) => {
      // HACK: need improvement in future (may cause performance issues in large collections)
      if (value && value.length > 0) {
        this.previousFilterSelection = value;
        instance.onFloatingFilterChanged('other', JSON.stringify(value));
      } else {
        this.shouldFilter = true;
        this.previousFilterSelection = null;
        instance.setModel(null);
        this.params.api.onFilterChanged();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  isTranslatable(): boolean {
    const renderer = this.params.column.getColDef().cellRenderer;
    if (!isString(renderer)) {
      return false;
    }

    if (renderer === DATA_TABLE_COMPONENTS.TRANSLATE_RENDERER) {
      return true;
    }
  }

  compareOptions = isEqual;

  private updateDropdownForClientMode() {
    if (this.params.filterParams.overrideOptions) {
      this.updateDropdown(this.params.filterParams.overrideOptions, true, false);
      return;
    }

    let options: number[] | string[];
    const rowData = [];

    this.gridApi?.forEachNodeAfterFilter(node => rowData.push(node));

    options = uniq(rowData.map(rowElement => this.params.filterParams.valueGetter(rowElement)));
    this.updateDropdown(options);
  }

  private updateDropdown(options: Option[], externalData = false, sort = true) {
    if (!isEmpty(this.selectedValues) && !externalData) {
      options = this.selectedValues;
    }

    if (!this.dropdownOpen || this.shouldFilter || externalData) {
      this.options = [];
      for (const option of options) {
        this.addOption(option);
      }

      const sortEnabled = !this.params?.filterParams?.disableSorting;

      if (sortEnabled && sort) {
        this.sortFilterDropdownOptions();
      }
      this.shouldFilter = false;
    }

    if (this.showGroups) {
      this.groupLabelsFromOptions = uniqBy(this.options, 'groupLabel').map(x => x.groupLabel);
    }

    if (this.keepSelectedFilters && !isNil(this.previousFilterSelection)) {
      this.params.parentFilterInstance((instance: TextFilter) => {
        instance.onFloatingFilterChanged('other', JSON.stringify(this.previousFilterSelection));
      });
    }
  }

  /**
   * Do not update options when in infiniteMode and dropdown is opened, so user can select more than one option.
   *
   * Also check if user deselected all options to update the list without closing and opening again.
   *
   * Without this:
   *  1. user selects first option
   *  2. response from BE with new set of filters is received
   *  3. items in dropdown are updated
   *  4. user can see only one selected option, without possibility to select more.
   */
  private ignoreOptionUpdateWhenInInfiniteMode() {
    return this.previousFilterSelection?.length > 0;
  }

  private addOption(cellValue: Option) {
    if (!cellValue) {
      return;
    }

    if (cellValue.name !== undefined && cellValue.value !== undefined) {
      this.options.push({ name: cellValue.name, value: cellValue.value, groupLabel: cellValue?.groupLabel });
      return;
    }

    let formattedValue: string = !isNil(cellValue)
      ? JSON.stringify(cellValue)
      : this.translateService.instant('common.noValue');

    const formatter = this.params.column.getColDef().valueFormatter;

    if (isCallable(formatter)) {
      const valueFormatterParams: any = { value: cellValue };
      formattedValue = formatter(valueFormatterParams);
    }

    formattedValue = formattedValue?.toString();

    if (formattedValue?.includes('|')) {
      formattedValue = formattedValue.replace('|', ' / ');
    }

    this.options.push({ name: formattedValue, value: cellValue });
  }

  private sortFilterDropdownOptions() {
    let tempOptions = [...this.options];

    let nullIndex = tempOptions.findIndex(el => el.name === this.translateService.instant('common.noValue'));
    if (nullIndex !== -1) {
      // Always push 'N/A' to the last position of filter options
      let nullItem = tempOptions.splice(nullIndex, 1)[0];
      tempOptions = orderBy(tempOptions, ['name'], ['asc']);
      tempOptions.push(nullItem);
    } else {
      tempOptions = orderBy(tempOptions, ['name'], ['asc']);
    }
    this.options = tempOptions;
  }

  private updateCdkConnectedOverlayPositions() {
    this.filterDropdown.updateCdkConnectedOverlayPositions();
  }
}
