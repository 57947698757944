<button
  *ngIf="!outline"
  [disabled]="disabled"
  (click)="onClick()"
  [type]="buttonType"
  [ngClass]="{ 'button-arrow': arrow, 'button-no-border': !border, 'full-width': fullWidth }"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <span *ngIf="arrow" [ngClass]="{ 'arrow-disabled': disabled }" class="arrow zmdi zmdi-long-arrow-right"></span>
</button>

<button
  *ngIf="outline"
  [disabled]="disabled"
  (click)="onClick()"
  [type]="buttonType"
  [ngClass]="{
    'button-outline': outline,
    'button-arrow': arrow,
    'button-not-available': notAvailableIcon,
    'button-no-border': !border,
    'full-width': fullWidth,
    'btn-small': size === 'small',
    'text-align-right': textAlign === 'right'
  }"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <vpfa-tooltip *ngIf="tooltipContent" [content]="tooltipContent" class="tooltip-icon"></vpfa-tooltip>
  <span *ngIf="arrow" [ngClass]="{ 'arrow-disabled': disabled }" class="arrow zmdi zmdi-long-arrow-right"></span>
  <i *ngIf="notAvailableIcon" class="zmdi zmdi-info"></i>
</button>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
