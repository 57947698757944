import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicNotificationsService } from './services/basic-notifications.service';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromNotifications from './+state/notifications.reducer';
import { NotificationsEffects } from './+state/notifications.effects';
import { NotificationsFacade } from './+state/notifications.facade';
import { NotificationsContainerComponent } from './containers/notifications-modal/notifications-container.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ModalsModule } from '@vpfa/modals';
import { NotificationContentComponent } from './components/notification-content/notification-content.component';
import { NotificationComponent } from './components/notification/notification.component';
import { FileNotificationsComponent } from './components/file-notifications/file-notifications.component';
import { FilesNotificationsService } from './services/files-notifications.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationToastComponent } from './components/notification-toast/notification-toast.component';
import { ToastsNotificationService } from './services/toast-notifications.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NzButtonModule,
    StoreModule.forFeature(fromNotifications.NOTIFICATIONS_FEATURE_KEY, fromNotifications.reducer),
    EffectsModule.forFeature([NotificationsEffects]),
    NzSpinModule,
    NzIconModule,
    BrowserAnimationsModule,
    ModalsModule,
    // Warning: do not import SharedDirectivesModule, it causes a lot of unit tests to fail for unknown reason
  ],
  providers: [BasicNotificationsService, NotificationsFacade, FilesNotificationsService, ToastsNotificationService],
  declarations: [
    NotificationsContainerComponent,
    NotificationContentComponent,
    NotificationComponent,
    FileNotificationsComponent,
    NotificationToastComponent,
  ],
  exports: [NotificationsContainerComponent, NotificationComponent],
})
export class SharedNotificationsModule {}
