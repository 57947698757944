export * from './lib/utils.module';
export * from './lib/converters/enum-to-options';
export * from './lib/converters/get-unique-options';
export * from './lib/converters/option-value-to-name';
export * from './lib/dates/get-local-date';
export * from './lib/dates/dates-compare';
export * from './lib/constants/static-images-paths';
export * from './lib/dynamic-locale-register';
export * from './lib/guid';
export * from './lib/animations';
export * from './lib/rxjs/convert-observable-to-behavior-subject';
export * from './lib/directives/view-cache-map/view-cache-map.directive';
export * from './lib/rxjs/mapFileToDataUrl';
export * from './lib/get-route-param';
export * from './lib/ellipsis-active';
export * from './lib/url/clear-query-params-from-url';
export * from './lib/url/has-url-query-params';
export * from './lib/url/parse-boolean-query-param';
export * from './lib/url/parse-price-query-param';
export * from './lib/url/is-valid-http-url';
export * from './lib/images/case-image-url';
export * from './lib/images/image-size';
export * from './lib/rxjs/full-width-group';
export * from './lib/sorting/post-sort-full-width-groups';
export * from './lib/is-in-urls-list';
export * from './lib/mixpanel/mixpanel.service';
export * from './lib/mixpanel/mixpanel-event.enum';
export * from './lib/civic-cookies/civic-cookies.service';
export * from './lib/find-resolved-data';
export * from './lib/components/empty/empty.component';
export * from './lib/services/utils.service';
export * from './lib/recalculate-eqp-short-desc';
export * from './lib/rxjs/operators';
export * from './lib/get-most-frequently-occurring-item';
export * from './lib/objects/flat-object';
export * from './lib/objects/object-type-check';
export * from './lib/ngrx-devtools/sanitizers';
export * from './lib/error-handlers/chunk-error-handler';
export * from './lib/cache/cache.interceptor';
export * from './lib/images/sorc-image-view';
export * from './lib/files/create-shared-case-pdf-filename';
export * from './lib/error-handlers/vp-error-reader';
export * from './lib/text-helpers/first-char-to-lower-case';
export * from './lib/debug/debug.utils';
export * from './lib/directives/get-client-bounding-rect/get-client-bounding-rect.directive';
export * from './lib/debug/equipment-debug.util';
export * from './lib/dom/sanitize-selector-value';
export * from './lib/printing/printing.utils';
export * from './lib/files/download-file.util';
export * from './lib/i18n-texts/get-i18-range-value.util';
export * from './lib/data-dog/data-dog.service';
export * from './lib/type-predicates/is-callable';
export * from './lib/dates/get-utc-date';
export * from './lib/pdf-templates/get-pdf-template-name.util';
export * from './lib/live-retail/live-retail-tax-type.util';
export * from './lib/rxjs/execute-requests-sequentially';
export * from './lib/forms/mark-form-as-touched-and-validate';
export * from './lib/forms/form-status';

import { DateTime } from 'luxon';
import { isString, isDate } from 'lodash';
import { isNumber } from 'util';

export enum DATE_FORMAT {
  TIMESTAMP = 'TIMESTAMP',
  ISO_DATE = 'ISO_DATE',
  JS_DATE = 'JS_DATE',
}

export const toDateTime = (data: string | number | Date, dataFormat: DATE_FORMAT) => {
  if (null === data) {
    throw new Error('Date data cant be null!');
  }

  switch (dataFormat) {
    case DATE_FORMAT.ISO_DATE:
      if (!isString(data)) throw new Error('Expected [ ISO_DATE ] string ');
      return DateTime.fromISO(data);
    case DATE_FORMAT.JS_DATE:
      if (!isDate(data)) throw new Error('Expected [ JS_DATE ] object ');
      return DateTime.fromJSDate(data);
    case DATE_FORMAT.TIMESTAMP:
      if (!isNumber(data)) {
        throw new Error('Expected [ TIMESTAMP ] number ');
      }
      const invVal = parseInt(String(data), 10);
      return DateTime.fromSeconds(invVal);
  }
};
export const getIsoDate = (data: string | number | Date, dataFormat: DATE_FORMAT) =>
  toDateTime(data, dataFormat).toISODate();
export const getJsDate = (data: string | number | Date, dataFormat: DATE_FORMAT) =>
  toDateTime(data, dataFormat).toJSDate();
export const getTimestamp = (data: string | number | Date, dataFormat: DATE_FORMAT) =>
  toDateTime(data, dataFormat).toSeconds();

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type Rename<T, K extends keyof T, N extends string> = Pick<T, Exclude<keyof T, K>> & { [P in N]: T[K] };
export type ValueOf<T> = T[keyof T];
