import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NotificationsPartialState } from './notifications.reducer';
import { notificationsQuery } from './notifications.selectors';
import {
  AddBasicNotification,
  AddFileNotification,
  ClearAllNotifications,
  SetCountOfFilesUpload,
  SetInvalidFilesCount,
} from './notifications.actions';
import { NotificationType } from '../models/notification-type.enum';
import { FileNotificationType } from '../models/file-notification-type.enum';
import { FileNotAcceptedReason } from '../models/file-not-accepted-reason.enum';
import { DataDogService } from '@vpfa/utils';
import { TranslateService } from '@ngx-translate/core';
import { ToastsNotificationService } from '../services/toast-notifications.service';

@Injectable()
export class NotificationsFacade {
  errorNotifications$ = this.store.pipe(select(notificationsQuery.getBasicErrorNotifications));
  warningNotifications$ = this.store.pipe(select(notificationsQuery.getBasicWarningNotifications));
  successNotifications$ = this.store.pipe(select(notificationsQuery.getBasicSuccessNotifications));
  mistakeNotifications$ = this.store.pipe(select(notificationsQuery.getBasicMistakeNotifications));

  basicNotifications$ = this.store.pipe(select(notificationsQuery.getAllBasicNotifications));
  filesNotifications$ = this.store.pipe(select(notificationsQuery.getFilesNotifications));

  filesUploadCount$ = this.store.pipe(select(notificationsQuery.getFilesUploadCount));
  invalidFilesCount$ = this.store.pipe(select(notificationsQuery.getInvalidFilesCount));

  constructor(
    private store: Store<NotificationsPartialState>,
    private dataDogService: DataDogService,
    private toastsNotificationService: ToastsNotificationService,
  ) {}

  addBasic(
    title: string,
    message: string,
    interpolateParams: Object,
    type: NotificationType,
    translate: boolean = false,
    errorId?: string,
  ) {
    if (type === NotificationType.Error) {
      this.dataDogService.addNotificationError(title, message, errorId, interpolateParams);
    }

    if (type === NotificationType.Success) {
      this.toastsNotificationService.success(title, message, interpolateParams);
      return;
    }

    this.store.dispatch(
      new AddBasicNotification({
        title,
        type,
        message,
        interpolateParams,
        translate,
        errorId,
      }),
    );
  }

  addFileNotification(fileName: string, notificationType: FileNotificationType, reason?: FileNotAcceptedReason) {
    if (notificationType === FileNotificationType.Accepted) {
      this.toastsNotificationService.success('files.caseFiles.fileAdded', fileName);
      return;
    }

    this.store.dispatch(
      new AddFileNotification({
        fileName,
        notificationType,
        reason,
      }),
    );
  }

  setCountOfFilesUpload(count: number) {
    this.store.dispatch(new SetCountOfFilesUpload(count));
  }

  setInvalidFilesCount(count: number) {
    this.store.dispatch(new SetInvalidFilesCount(count));
  }

  clearAll() {
    this.store.dispatch(new ClearAllNotifications());
  }
}
