import { FormGroup } from '@angular/forms';

/**
 * Mark all fields as touched so UI will show eventual validations errors (red borders without popover in controls)
 * and force validation, because some required fields may be added to Form due to changed language in Admin panel
 * for example.
 * @param formGroup
 */
export function validateFormAndMarkAsTouched(formGroup: FormGroup) {
  formGroup.updateValueAndValidity();
  Object.values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    control.updateValueAndValidity();
  });
}

/**
 * Mark all fields as dirty so UI will show eventual validations errors (red borders and popover in controls).
 * @param formGroup
 */
export function markFormAsDirty(formGroup: FormGroup) {
  formGroup.updateValueAndValidity();
  Object.values(formGroup.controls).forEach(control => {
    control.markAsDirty();
  });
}
