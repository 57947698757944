<div class="p20px">
  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
      <h3 class="heading" translate>vehicleRetailMarketStats.retailMarket</h3>
      <vpfa-tooltip class="tooltip" [content]="'tooltip.retailMarket' | translate"></vpfa-tooltip>
    </div>
    <vpfa-inline-loader *ngIf="isLoading && !liveRetailNotAvailable"></vpfa-inline-loader>
  </div>
  <nz-divider></nz-divider>
  <ng-container *ngIf="retailMarketDetails">
    <div class="stats-entry" *ngIf="!isInClientMode">
      <vpfa-label
        [fxFlex]="'60%'"
        [text]="
          ('vehicleRetailMarketStats.liveRetailPrice' | translate) + ' (' + (taxInformation$ | async | translate) + ')'
        "
        [ellipsis]="true"
        [forceTitle]="true"
      >
      </vpfa-label>
      <ng-container
        [ngTemplateOutlet]="price"
        [ngTemplateOutletContext]="{
          price: retailMarketDetails.liveRetailPrice,
          htmlId: 'retailMarketLiveRetailPrice',
        }"
      ></ng-container>
    </div>
    <div class="stats-entry" *ngIf="(showSellingSpotPrice$ | async) && !isInClientMode">
      <vpfa-label text="{{ 'vehicleRetailMarketStats.sellingRate' | translate }}"></vpfa-label>
      <ng-container
        [ngTemplateOutlet]="price"
        [ngTemplateOutletContext]="{
          price: retailMarketDetails.sellingSpotPrice,
          htmlId: 'retailMarketSellingSpotPrice',
        }"
      ></ng-container>
    </div>
    <div class="stats-entry">
      <vpfa-label text="{{ 'vehicleRetailMarketStats.stockDays' | translate }}"></vpfa-label>
      <ng-container
        [ngTemplateOutlet]="value"
        [ngTemplateOutletContext]="{
          value: retailMarketDetails.averageTimeToSell,
          htmlId: 'retailMarketAverageTimeToSell',
        }"
      ></ng-container>
    </div>
    <div class="stats-entry">
      <vpfa-label
        text="{{ 'vehicleRetailMarketStats.mileage' | translate }}{{ mileageUnit ? mileageUnit : '' }}"
      ></vpfa-label>
      <ng-container
        [ngTemplateOutlet]="value"
        [ngTemplateOutletContext]="{ value: retailMarketDetails.averageMileage, htmlId: 'retailMarketAverageMileage' }"
      ></ng-container>
    </div>
    <div class="stats-entry">
      <vpfa-label text="{{ 'vehicleRetailMarketStats.desirability' | translate }}"></vpfa-label>
      <ng-container
        [ngTemplateOutlet]="desirability"
        [ngTemplateOutletContext]="{ htmlId: 'retailMarketDesirability' }"
      ></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!retailMarketDetails">
    <div class="no-zip-code">
      <ng-container *ngIf="isNewVehicle; else isUsedVehicleTemplate">
        {{ 'vehicleRetailMarketStats.liveRetailNotAvailableDueToNewVehicle' | translate }}
      </ng-container>
      <ng-template #isUsedVehicleTemplate>
        {{ 'vehicleRetailMarketStats.liveRetailNotAvailable' | translate }}
      </ng-template>
    </div>
  </ng-container>
</div>

<ng-template #price let-price="price" let-htmlId="htmlId">
  <vpfa-value *ngIf="!liveRetailNotAvailable" [attr.id]="htmlId" [attr.data-price]="price">
    {{ price | currencyLocale | lT }}
  </vpfa-value>
  <vpfa-price-with-icon
    *ngIf="liveRetailNotAvailable"
    [attr.id]="htmlId"
    [attr.data-price]="price"
    [color]="'orange'"
    [price]="price | currencyLocale | lT"
  ></vpfa-price-with-icon>
</ng-template>

<ng-template #value let-value="value" let-htmlId="htmlId">
  <vpfa-value *ngIf="!liveRetailNotAvailable" [attr.id]="htmlId" [attr.data-value]="value">
    {{ value | numberLocale | lT }}
  </vpfa-value>
  <vpfa-price-with-icon
    *ngIf="liveRetailNotAvailable"
    [attr.id]="htmlId"
    [attr.data-value]="value"
    [color]="'orange'"
    [price]="value | numberLocale | lT"
  ></vpfa-price-with-icon>
</ng-template>

<ng-template #desirability let-htmlId="htmlId">
  <vpfa-value
    *ngIf="!liveRetailNotAvailable"
    class="desirability"
    [attr.id]="htmlId"
    [attr.data-desirability]="retailMarketDetails.desirability"
  >
    {{ retailMarketDetails.desirability | lT }}
  </vpfa-value>
  <vpfa-price-with-icon
    *ngIf="liveRetailNotAvailable"
    [attr.id]="htmlId"
    [attr.data-desirability]="retailMarketDetails.desirability"
    [color]="'orange'"
    [price]="retailMarketDetails.desirability | lT"
  ></vpfa-price-with-icon>
</ng-template>
