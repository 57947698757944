import { BREAKPOINT } from '@ngbracket/ngx-layout';
import { WEB_VIEW_WINDOW_WIDTH_PX } from '../services/is-mobile-view.service';

export const mobileViewAlias = 'lt-mv';

const minMobileWidth = WEB_VIEW_WINDOW_WIDTH_PX - 1;

const MOBILE_VIEW_BREAKPOINTS = [
  {
    alias: mobileViewAlias,
    suffix: 'LtMV',
    mediaQuery: `screen and (max-width: ${minMobileWidth}px)`, // based on https://jira.autovistagroup.com/browse/VP-3718
    overlapping: true,
    priority: 2000,
  },
  {
    alias: 'gt-mv',
    suffix: 'GtMV',
    mediaQuery: `screen and (min-width: ${WEB_VIEW_WINDOW_WIDTH_PX}px)`, // based on https://jira.autovistagroup.com/browse/VP-3718
    overlapping: true,
    priority: 2001,
  },
];

export const MobileBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: MOBILE_VIEW_BREAKPOINTS,
  multi: true,
};
