<div [fxLayout]="'row'" [fxLayoutGap]="'10px'">
  <div [fxLayout]="'row'" [fxFlex]="'100%'">
    <vpfa-reset-button
      *ngIf="showResetButton"
      [tooltip]="resetButtonTooltip"
      [disabled]="resetButtonDisabled"
      (reset)="onReset()"
    ></vpfa-reset-button>
    <vpfa-text-field
      [fxFlex]="'100%'"
      [parentFormGroup]="formGroup"
      [fcName]="fcName"
      [errorMsgVertPosition]="'inside'"
      [errorMessages]="errorMessages"
    ></vpfa-text-field>
  </div>

  <vpfa-select
    [fxFlex]="'6%'"
    [parentFormGroup]="selectGroup"
    [fc]="selectControl"
    [options]="options"
    [placeholder]="' '"
    [translateLabel]="true"
    [dropdownClass]="'vpfa-text-field-with-dropdown'"
    [customSelectedTemplate]="selectedTemplate"
    [nzDropdownMatchSelectWidth]="false"
    placement="bottomRight"
    (selected)="onSelectedValue($event)"
  ></vpfa-select>
</div>

<ng-template #selectedTemplate></ng-template>
