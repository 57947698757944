import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { ConfirmModalContentComponent } from './components/modal-confirm/confirm-modal-content.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalService } from './services/modal.service';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { TranslateModule } from '@ngx-translate/core';
import { ModalButtonComponent } from './components/modal-button/modal-button.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { OKModalContentComponent } from './components/modal-ok/ok-modal-content.component';
import { ConfirmChangesModalContentComponent } from './components/modal-confirm-changes/confirm-changes-modal-content.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ModalServiceContentComponent } from './components/modal-service-content/modal-service-content.component';

@NgModule({
  imports: [NzModalModule, CommonModule, NzPopconfirmModule, TranslateModule, NzSpinModule, NzIconModule],
  declarations: [
    ModalComponent,
    ModalContentComponent,
    ConfirmModalContentComponent,
    ConfirmChangesModalContentComponent,
    ModalHeaderComponent,
    ModalButtonComponent,
    OKModalContentComponent,
    ModalServiceContentComponent,
  ],
  exports: [
    ModalComponent,
    ModalContentComponent,
    ConfirmModalContentComponent,
    ConfirmChangesModalContentComponent,
    ModalHeaderComponent,
    ModalButtonComponent,
    OKModalContentComponent,
    ModalServiceContentComponent,
  ],
  providers: [ModalService],
})
export class ModalsModule {}
