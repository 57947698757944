import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const WEB_VIEW_WINDOW_WIDTH_PX = 1024;

@Injectable({ providedIn: 'root' })
export class IsMobileViewService {
  isMobileView$ = new BehaviorSubject(false);

  constructor() {
    this.updateIsMobile();
    window.addEventListener('resize', () => this.updateIsMobile());
  }

  updateIsMobile(): void {
    const isMobile = window.innerWidth < WEB_VIEW_WINDOW_WIDTH_PX;

    if (this.isMobileView$.value !== isMobile) {
      this.isMobileView$.next(isMobile);
    }
  }
}
